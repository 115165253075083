import * as React from 'react';

import * as PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Button, Select, FormControl, InputLabel, createStyles, WithStyles } from '@material-ui/core'
import { withStyles, Theme } from '@material-ui/core/styles';

import { AdvisorHelper } from "../charts/advisorHelper"
import { Advisor, AdvisorMeta } from "../types/types";
import { LambdaHelper } from "../lambda/lambdaHelper";

import LinearIndeterminate from "./ProgressBar";


const styles = (theme: Theme) => createStyles({
    dropDown: {
        '& select': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            appearance: 'none',
            paddingLeft: theme.spacing(0.5),
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    submitButton: {
        verticalAlign: 'bottom'
    },
    noReportsOption: {
        color: 'silver !important'
    }
});

const AdvisorMetaSchema = Yup.object().shape({
    stage: Yup.string()
        .required('stage is required')
});

interface Props extends WithStyles<typeof styles> {
    onSubmit: (values: AdvisorMeta) => Promise<void>;
    classes: any;
}

const activationEndpoints: { [index: string]: string } = {
    ST_EU: process.env.REACT_APP_ENV_ST_EU as string,
    ST_US: process.env.REACT_APP_ENV_ST_US as string,
    SL: process.env.REACT_APP_ENV_SL as string,
    tiger: process.env.REACT_APP_ENV_TIGER as string,
    barracuda: process.env.REACT_APP_ENV_BARRACUDA as string,

    ST_EU_dev0: process.env.REACT_APP_ENV_ST_EU_dev0 as string,
    ST_US_dev0: process.env.REACT_APP_ENV_ST_US_dev0 as string,
    SL_dev0: process.env.REACT_APP_ENV_SL_dev0 as string,
    tiger_dev0: process.env.REACT_APP_ENV_TIGER_dev0 as string,
    barracuda_dev0: process.env.REACT_APP_ENV_BARRACUDA_dev0 as string
};

const getSpecificActivationEndpoint = (env: keyof typeof activationEndpoints, stage: string) => {
    const specificEnv = (env + '_' + stage);
    let url = activationEndpoints[specificEnv];
    if (!url) {
        url = activationEndpoints[env];
    }
    return url;
};

const fetchActivatedAdvisors = async (env: keyof typeof activationEndpoints, stage: string): Promise<Advisor[]> => {
    if (env) {
        const url = getSpecificActivationEndpoint(env, stage);
        const advisorHelper = new AdvisorHelper();
        return await advisorHelper.fetchFromAep(url).catch(error => {
            console.log(error);
            return [];
        });
    } else {
        return []
    }
};

const filterAdvisorsWithResults = async (env: keyof typeof activationEndpoints, stage: string): Promise<Advisor[]> => {
    const advisors = await fetchActivatedAdvisors(env, stage);
    const lambdaHelper = new LambdaHelper();

    return await Promise.all(advisors.map(async advisor => {
        const aggregatedReportPromise = await lambdaHelper.getAggregatedReport(stage, env as string, advisor.advisorId).catch(e=>{console.log(e)});
        if (aggregatedReportPromise && aggregatedReportPromise.statements.length > 0 &&
            aggregatedReportPromise.statements[0].statementGroup !== 'NoCustomResults' &&
            aggregatedReportPromise.statements[0].statementGroup !== 'NotEnoughData' &&
            aggregatedReportPromise.statements[0].statementGroup !== 'NoSignificantResults') {
            advisor.hasReports = true;
        }
        return advisor;
    }));
};

const ShowResultsForm: React.FC<Props> = ({ onSubmit, classes }) => {

    const defaultSettings = {advisorId: 0, advisorLabel: '', localizationIds: [0], hasReports: false};
    const [stage, setStage] = React.useState(process.env.REACT_APP_DEFAULT_STAGE as string);
    const [env, setEnv] = React.useState('');
    const [advisors, setAdvisors] = React.useState([defaultSettings]);
    const [advisor, setAdvisor] = React.useState(defaultSettings);
    const [loading, setLoading] = React.useState(false);

    const updateActivatedAdvisors = (advisors: Advisor[]) => {
        if ( advisors && advisors.length && advisors[0].localizationIds.length ) {
            setAdvisors(advisors);
            setAdvisor(advisors[0]);
        } else {
            setAdvisors([defaultSettings]);
        }
    };

    const handleStageChange = async (event: React.ChangeEvent<{ name?: string; value: any }>, env: string) => {
        setLoading(true);
        const stage = event.target.value;
        setEnv(env);
        setStage(stage);
        const advisors = await filterAdvisorsWithResults(env, stage).catch((e) => console.log(e));
        if (advisors) {
            updateActivatedAdvisors(advisors);
            setLoading(false);
        }
    };

    const handleEnvChange = async (event: React.ChangeEvent<{ name?: string; value: any }>, stage: string) => {
        const env = event.target.value;
        if (env) {
            setLoading(true);
            const advisors = await filterAdvisorsWithResults(env, stage).catch((e) => console.log(e));
            setEnv(env);
            setStage(stage);
            if (advisors) {
                updateActivatedAdvisors(advisors);
            }
            setLoading(false);
        }
    };

    const handleAdvisorChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        setLoading(true);
        const advisorId = event.target.value;
        const advisor = advisors.find(adv => adv.advisorId.toString() === advisorId );
        if (advisor) {
            setAdvisor(advisor);
            setLoading(false);
        }
    };

    return (
        <Formik
            initialValues={{ stage: stage, env: env, advisorId: advisor.advisorId, lid: advisor.localizationIds[0] }}
            enableReinitialize={true}
            onSubmit={ values => {
                onSubmit(values);
            }}
            validationSchema={AdvisorMetaSchema}
        >
        {( { setFieldValue, values, handleChange, errors, touched } ) => (
            <Form>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <div>
                    <FormControl required className={classes.formControl}>
                        <InputLabel htmlFor="stage">Stage</InputLabel>
                        <Select className={classes.dropDown} native value={values.stage} id="stage" onChange={async e => {
                            setFieldValue('stage', e.target.value);
                            await handleStageChange(e, values.env);
                        }}>
                            {(process.env.REACT_APP_STAGE0 !== undefined ? <option value={process.env.REACT_APP_STAGE0}>{process.env.REACT_APP_STAGE0}</option> : '')}
                            {(process.env.REACT_APP_STAGE1 !== undefined ? <option value={process.env.REACT_APP_STAGE1}>{process.env.REACT_APP_STAGE1}</option> : '')}
                            {(process.env.REACT_APP_STAGE2 !== undefined ? <option value={process.env.REACT_APP_STAGE2}>{process.env.REACT_APP_STAGE2}</option> : '')}
                            {(process.env.REACT_APP_STAGE3 !== undefined ? <option value={process.env.REACT_APP_STAGE3}>{process.env.REACT_APP_STAGE3}</option> : '')}
                            {(process.env.REACT_APP_STAGE4 !== undefined ? <option value={process.env.REACT_APP_STAGE4}>{process.env.REACT_APP_STAGE4}</option> : '')}
                            {(process.env.REACT_APP_STAGE5 !== undefined ? <option value={process.env.REACT_APP_STAGE5}>{process.env.REACT_APP_STAGE5}</option> : '')}
                            {(process.env.REACT_APP_STAGE6 !== undefined ? <option value={process.env.REACT_APP_STAGE6}>{process.env.REACT_APP_STAGE6}</option> : '')}
                            {(process.env.REACT_APP_STAGE7 !== undefined ? <option value={process.env.REACT_APP_STAGE7}>{process.env.REACT_APP_STAGE7}</option> : '')}
                            {(process.env.REACT_APP_STAGE8 !== undefined ? <option value={process.env.REACT_APP_STAGE8}>{process.env.REACT_APP_STAGE8}</option> : '')}
                            {(process.env.REACT_APP_STAGE9 !== undefined ? <option value={process.env.REACT_APP_STAGE9}>{process.env.REACT_APP_STAGE9}</option> : '')}
                            {(process.env.REACT_APP_STAGE10 !== undefined ? <option value={process.env.REACT_APP_STAGE10}>{process.env.REACT_APP_STAGE10}</option> : '')}

                        </Select>
                        {loading ? <LinearIndeterminate /> : null}
                    </FormControl>
                    <FormControl required className={classes.formControl}>
                        <InputLabel htmlFor="env">Env</InputLabel>
                        <Select className={classes.dropDown} native value={values.env} id="env" onChange={async e => {
                            setFieldValue('env', e.target.value);
                            await handleEnvChange(e, values.stage);
                        }}>
                            <option value={''}></option>
                            <option value={'ST_EU'}>ST_EU</option>
                            <option value={'ST_US'}>ST_US</option>
                            <option value={'SL'}>SL</option>
                            <option value={'tiger'}>tiger</option>
                            <option value={'barracuda'}>barracuda</option>

                        </Select>
                        {loading ? <LinearIndeterminate /> : null}
                    </FormControl>

                    <FormControl required className={classes.formControl}>
                        <InputLabel htmlFor="advisorId">advisorId</InputLabel>
                        <Select className={classes.dropDown} native value={values.advisorId} id="advisorId" onChange={async e => {
                            setFieldValue('advisorId', e.target.value);
                            await handleAdvisorChange(e);
                        }}>
                            {advisors.map((advisor, idx) => (
                                advisor.hasReports?
                                    <option value={advisor.advisorId} key={'advisorId'+idx}>{advisor.advisorLabel + ' (' + advisor.advisorId + ')'}</option>:
                                    <option value={advisor.advisorId} key={'advisorId'+idx} className={classes.noReportsOption}>{advisor.advisorLabel + ' (' + advisor.advisorId + ')'}</option>
                                ))}
                        </Select>
                        {loading ? <LinearIndeterminate /> : null}
                    </FormControl>
                    <FormControl required className={classes.formControl}>
                        <InputLabel htmlFor="lid">lid</InputLabel>
                        <Select className={classes.dropDown} native value={values.lid} id="lid" onChange={e => {
                            setFieldValue('lid', e.target.value)
                        }}>
                            {advisor.localizationIds.map((lid, idx) => (
                                <option value={lid} key={'lid'+idx}>{lid}</option>
                            ))}
                        </Select>
                        {loading ? <LinearIndeterminate /> : null}
                    </FormControl>

                    <Button type="submit" variant="contained" className={`${classes.formControl} ${classes.submitButton}`}>Go!</Button>
                </div>
            </Form>
        )}
        </Formik>

    );
};

ShowResultsForm.propTypes = { classes: PropTypes.object.isRequired };

export default withStyles(styles)(ShowResultsForm);

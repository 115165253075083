import React from 'react';

import { ResponsiveLine, Serie } from '@nivo/line'
import { Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/styles/createStyles/createStyles";

import { dateFromMilliseconds } from "../../utils/utils";
import { InfoText } from "../InfoTexts/InfoText";
import { cummulativeBinomialConfidence, SeriesWithOccurrences } from '../Dashboards/data/StatisticsUtils';

import { confidenceSliceTooltip } from './LinePlotUtils/CustomTooltip';
import { ConfidenceIntervalLayer, StandardDeviationLayer } from './LinePlotUtils/CustomLayers';
import {getAxisBottomRotation, getMaxXDataLength} from "./SharedPlotUtils/PlotUtils";
import {getYFormat} from "./LinePlotUtils/LineUtils";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: { padding: theme.spacing(1), boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)' },
    title: { ...theme.typography.h4, textAlign: 'center' }
}));

interface IProps {
    xTitle?: string;
    yTitle: string;
    lines: SeriesWithOccurrences[];
    showAsPercentage: boolean;
    infoText?: string;
}



const ConfidenceIntervalPlot: React.FC<IProps> = (props) => {
    const classes = useStyles();

    function getData(): Serie[] {
        return props.lines.map((seriesWithOccurrences, idx) => ({
            id: seriesWithOccurrences.id,
            data: [...seriesWithOccurrences.xData.keys()].map((index: number) => ({
                x: dateFromMilliseconds(seriesWithOccurrences.xData[index]),
                y: seriesWithOccurrences.yData[index],
                n: seriesWithOccurrences.occurrences![index] || 0,
                c: cummulativeBinomialConfidence(seriesWithOccurrences, index + 1),
            })),
            key: idx
        }))
    }

    return (
        <Paper className={`${classes.paper}`}>
            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <Typography className={classes.title}>
                        {props.yTitle}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ height: '300px' }} >
                    <ResponsiveLine
                        curve="linear"
                        data={getData()}
                        margin={{ top: 30, right: 2, bottom: 50, left: 50 }}
                        colors={props.lines.map(line => line.color)}
                        axisTop={null}
                        axisRight={null}
                        lineWidth={3}
                        pointSize={12}
                        pointColor="white"
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: getAxisBottomRotation(getMaxXDataLength(props)),
                            format: '%b %d',
                            legend: props.xTitle,
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 0,
                            tickRotation: 0,
                            format: getYFormat(props.showAsPercentage)
                        }}
                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%d-%H-%M',
                            precision: 'minute',
                            useUTC: true
                        }}
                        xFormat="time:%Y-%m-%d"
                        yFormat={getYFormat(props.showAsPercentage)}
                        yScale={{
                            type: 'linear',
                            min: 0,
                            max: 1,
                        }}
                        sliceTooltip={confidenceSliceTooltip}
                        enableGridX={false}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        enableSlices='x'
                        layers={[
                            'grid',
                            'markers',
                            'areas',
                            StandardDeviationLayer(props),
                            ConfidenceIntervalLayer(props),
                            'lines',
                            'slices',
                            'axes',
                            'points',
                            'legends',
                        ]}
                    />
                </Grid>
            </Grid>
            {props.infoText &&
                <Grid item xs={12} >
                    <InfoText info={props.infoText} />
                </Grid>
            }
        </Paper >

    )
};

export default ConfidenceIntervalPlot;
import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { Theme } from "../../styles/theme/types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 140,
        }
    }),
);

interface Props {
    title: string;
    options: [string, string][]; // value, label
    enabled: boolean;
    valueChanged: (newSelection: string) => void;
    selectedInput: string;
}

export const DropDown: React.FC<Props> = ({ title, options, enabled, valueChanged, selectedInput }) => {
    const classes = useStyles();

    function createMenuItems() {
        return options.map((option) => <MenuItem value={option[0]} key={option[0]}>{option[1]}</MenuItem>)
    }

    function dropDownSelected(input: any) {
        const newValue = input.target.value as string
        valueChanged(newValue)
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>{title}</InputLabel>
            <Select
                value={selectedInput ?? ''}
                disabled={!enabled}
                onChange={dropDownSelected}>
                {createMenuItems()}

            </Select>
        </FormControl>
    );

};
import React from 'react';

import classNames from "classnames";

import {Grid, Container, Typography, Paper} from "@material-ui/core";
import {createStyles} from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {Theme} from "../../styles/theme/types";
import {InfoText} from "../InfoTexts/InfoText";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0
        },
        paper: {
            padding: theme.spacing(1),
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
            marginBottom: theme.spacing(1),
            width: '100%'
        },
        paperNoShadow: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
            boxShadow: 'none',
            width: '100%'
        },
        cartIcon: {
            color: theme.palette.cartIcon
        },
        lastPath: {
            backgroundColor: theme.palette.greys.bcg.element
        },
        title: {
            ...theme.typography.h4,
            textAlign: 'center',
            marginBottom: theme.spacing(1)
        },
    }),
);

interface Question {
    origin: string;
    text: string;
}

export interface Path {
    // list of questions
    questions: Question[];
    last?: boolean;
}

interface Props {
    paths: Path[];
    infoText?: string;
}

export const QuestionPathCard: React.FC<Props> = ({ paths , infoText}) => {
    const classes = useStyles();
     return(
         <Paper className={`${classes.paper}`}>
             <Container maxWidth={false} className={classes.root}>
                 <Grid item xs={12} >
                     <Typography className={classes.title}>
                         All and most recent popular paths
                     </Typography>
                 </Grid>
                 {paths.map((p, idx) =>
                     <Grid container item spacing={1} key={idx}>
                         <Paper className={classNames(classes.paperNoShadow, {[classes.lastPath]: p.last}) }  >
                             <Typography variant="h5">Path:</Typography>
                             {p.questions.map((q, idx) =>
                                 <Grid item xs key={idx}>
                                     <Typography variant="caption">
                                         {idx+1}. {q.text}
                                     </Typography>
                                 </Grid>
                             )}
                         </Paper>
                     </Grid>
                    )
                 }
                {infoText &&
                <Grid item xs={12} >
                    <InfoText info={infoText}/>
                </Grid>
              }
             </Container>
         </Paper>
     )
};


interface Insight {
    values: number[],
    periodTimestamp: number[]
}

export interface Insights {
    [insightName: string]: Insight;
    rollingClickoutRate: Insight,
    expandingClickoutRate: Insight,
}

export const dummyAssistantInsights: Insights = {
    rollingClickoutRate: {values: [], periodTimestamp: []},
    expandingClickoutRate: {values: [], periodTimestamp: []}
};

function getInsight(key: string, insights: Insights) {
    return insights[key];
}

export enum InsightsNames{
    ROLLING_CLICKOUT_RATE = 'rollingClickoutRate',
    EXPANDING_CLICKOUT_RATE = 'expandingClickoutRate'
}

export function getRollingClickoutRate(insights: Insights) {
    const insight = getInsight(InsightsNames.ROLLING_CLICKOUT_RATE, insights);
    return insight.values;
}

export function getExpandingClickoutRate(insights: Insights) {
    const insight = getInsight(InsightsNames.EXPANDING_CLICKOUT_RATE, insights);
    return insight.values;
}

export function getInsightTimePeriod(key: string, insights: Insights) {
    const insight = getInsight(key, insights);
    return insight.periodTimestamp
}

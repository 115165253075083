import React from 'react';

import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pickerContainer: {
            margin: theme.spacing(1),
            "& input": {
                fontSize: theme.spacing(1.8)
            }
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        calItem: {
            maxWidth: '160px'
        },
        button: {
            margin: theme.spacing(1),
        },
        rightIcon: {
            marginLeft: theme.spacing(1),
        },

    }),
);

interface Props {
    startTime: number;
    endTime: number;
    timerangeSelected: (startTime: number, endTime: number) => void;
}

const DateRangeSelector: React.FC<Props> = ({startTime, endTime, timerangeSelected}) => {
    const classes = useStyles();

    const [selectedStartDate, setSelectedStartDate] = React.useState(new Date(startTime));
    function handleStartDateChange(startDate: any) {
        setSelectedStartDate(startDate);
        if(extendRangeIfZero(startDate, selectedEndDate)){
            setSelectedEndDate(selectedEndDate);
        } else if (selectedEndDate < startDate) {
            setSelectedEndDate(startDate);
        }
        timerangeSelected(startDate.getTime(), selectedEndDate.getTime());
    }

    const [selectedEndDate, setSelectedEndDate] = React.useState(new Date(endTime));
    function handleEndDateChange(endDate: any) {
        if(extendRangeIfZero(selectedStartDate, endDate)){
            setSelectedEndDate(endDate);
        } else if (selectedStartDate > endDate) {
            setSelectedStartDate(endDate);
        }
        setSelectedEndDate(endDate);
        timerangeSelected(selectedStartDate.getTime(), endDate.getTime());
    }

    function extendRangeIfZero(startDate: any, endDate: any): boolean {
        let endDateShifted = false;
        if (endDate.getTime() === startDate.getTime()) {
            endDate.setHours(endDate.getHours() + 23); // shift, we will else get a range of 0 ms
            endDate.setMinutes(endDate.getMinutes() + 59);
            endDateShifted = true
        }
        return endDateShifted
    }

    return (
        <div className={classes.pickerContainer}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3} direction='row'>
                    <Grid item xs={12} sm={6} className={classes.calItem}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="startDate"
                            label="Start Date"
                            value={selectedStartDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.calItem}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            id="endDate"
                            label="End Date"
                            value={selectedEndDate}
                            onChange={handleEndDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    )
};

export default DateRangeSelector;
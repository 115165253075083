import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SvgIcon from "@material-ui/core/SvgIcon/SvgIcon";

import {Pipeline} from "../../types/health";
import {convertToDatestring, calculateAndFormatDuration} from "../../utils/utils";
import {Theme} from "../../styles/theme/types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        leftBound: {
            paddingLeft: '0 !important',
        },
        selected: {
            backgroundColor: theme.palette.greys.bcg.element,
        },
        paper: {
            padding: theme.spacing(1),
            margin: 'auto',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
            maxWidth: '500px',
            cursor: 'pointer'
        },
        cartIcon: {
            color: theme.palette.cartIcon
        },
        superBold: {
            weightForFont: theme.typography.fontWeightSuperBold
        },
        inlineRight: {
            display: 'inline',
            float: 'right'
        }
    }),
);

interface Props {
    pipeline: Pipeline,
    selected: boolean,
    iconPath: string,
    specificationAttrName: string,
    purposeAttrName: string
}

export const PipelineCard: React.FC<Props> = ({ selected, pipeline: {specification, purpose, startTime, endTime}, iconPath, specificationAttrName, purposeAttrName }) => {
    const classes = useStyles();
    const selectedClass = selected? classes.selected : '';

    return (
            <Paper className={`${selectedClass} ${classes.paper}`}>
                <Grid container spacing={2} >
                    <Grid item>
                        {iconPath &&
                        <SvgIcon className={`${classes.cartIcon} `}>
                            <path d={iconPath}/>
                        </SvgIcon>
                        }
                    </Grid>
                    <Grid container item xs direction="column" spacing={2}>
                        <Grid item className={classes.leftBound}>
                            <Typography variant="button" color="textSecondary">
                                { (specification !== undefined)?
                                    (specification as any)[specificationAttrName] : '' }
                            </Typography>
                            <Typography variant="subtitle2" className={classes.inlineRight}>
                                { convertToDatestring(startTime, false) }
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                { (purpose !== undefined)?
                                    (purpose as any)[purposeAttrName] : '' }
                            </Typography>
                            <Typography variant="caption">
                                pipeline run time:
                            </Typography>
                            <Typography variant="caption" className={classes.superBold}>
                                { calculateAndFormatDuration(startTime, endTime) }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
    );

};
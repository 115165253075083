export const TOTAL_NUMBER_OF_VISITS_INFO_TEXT_ACODA = 'Number of visits where the questionnarie was filled as well'
export const TOTAL_NUMBER_OF_CLICKOUTS = 'Total number of clickouts during the visits'
export const AGGREGATED_CO_RATE_INFO_TEXT_ACODA = 'Aggregated clickout rate from the all visits in the assistant'
export const AVERAGE_OVERALL_DURATION = 'Average duration from the overall sessions, including filling the survey and the ' +
    'assistant.'
export const AVERAGE_ASSISTANT_DURATION = 'Average duration for filling the assistant only'
export const DURATION_OF_EACH_VISIT = 'Duration of each visit particularly'
export const VISITS_AND_CLICKOUTS_PER_USER_TYPE = 'The number of visits and corresponding clickouts based on the user types. ' +
    'Rationality is based on the average of rationality questions: (I prefer to gather all the necessary information before committing to a decision,' +
    ' I thoroughly evaluate decision alternatives before making a final choice,' +
    ' In decision making, I take time to contemplate the pros/cons or risks/benefits of a situation). ' +
    ' Intuitivity is based on the average of intuitiveness questions: (When making decisions, I rely mainly on my gut feelings, ' +
    ' I make decisions based on intuition, I rely on my first impressions when making decisions)'
export const NUMBER_OF_VISITS_PER_QUESTIONS_SEEN = 'Number of visits where the user saw this many questions. This shows the tendency' +
    ' of where users most likely drop.'
export const NUMBER_OF_VISITS_PER_DURATION_GROUP = 'Number of visits per duration group. Short (up to 5 mins), Medium (5-10 min), Long (10-15 min)' +
    ' and super long is above 15 min.'
export const NUMBER_OF_VISITS_BY_BROWSER = 'Number of visits and corresponding clickouts split by browser family'
export const NUMBER_OF_VISITS_BY_PLATFORM = 'Number of visits and corresponding clickouts split by platform family'
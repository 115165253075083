import React from 'react';

import {Point, ResponsiveLine} from '@nivo/line'
import {LegendProps} from '@nivo/legends'
import {Paper, Grid, Typography} from '@material-ui/core';
import { makeStyles, Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/styles/createStyles/createStyles";

import {dateFromMilliseconds, getTspanGroups} from "../../utils/utils";
import {InfoText} from "../InfoTexts/InfoText";

import {getAxisBottomRotation, getMaxXDataLength} from "./SharedPlotUtils/PlotUtils";
import {DashedLine, getYFormat} from "./LinePlotUtils/LineUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
        },
        title: {
            ...theme.typography.h4,
            textAlign: 'center'
        }
    }),
);

const legendItemHeight = 50

interface IProps {
    xTitle?: string,
    yTitle: string,
    lines: SingleLine[],
    showLegend: boolean,
    showAsPercentage: boolean,
    infoText?: string,
}

interface SingleLine {
    id: string,
    xData: number[],
    yData: number[],
    hoverText?: string[]
}


const MultiTimeLinePlot: React.FC<IProps> = React.memo(props => {
    const classes = useStyles();

    function getColors(lineIdx: number, linesLength: number) {
        const darkScheme = ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02', '#a6761d', '#666666']
        return darkScheme[lineIdx % linesLength]
    }

    function getData() {
        const output = [...props.lines.keys()].map((line_idx: number) => ({
                id: props.lines[line_idx].id,
                color: getColors(line_idx, props.lines.length),
                data: [...props.lines[line_idx].xData.keys()].map((data_idx: number) => ({
                    x: dateFromMilliseconds(props.lines[line_idx].xData[data_idx]),
                    y: props.lines[line_idx].yData[data_idx],
                }))
        }))

        return output
    }

    function getSeriesHoverText(point: Point) {
        // if legend should not be shown, return series id for hover text
        if(!props.showLegend) {
            return point.serieId;
        }
        // find the highlighted line
        const line = props.lines.filter(x => x.id === point.serieId)

        if(!line.length) {
            console.log('This should not happen. Hovered line should be present in props as well.')
            return '-'
        }

        // get the index of the highlighted value. point id is formatted as `line id.index`. We split based on the dot
        const index = point.id.split('.').pop()

        if(index === undefined) {
            console.log('This should not happen. Hovered value should be on present on the selected line.')
            return '-'
        }
        // @ts-ignore
        return line[0].hoverText[index]
    }

    function getLegend(): LegendProps[] {
        if (!props.showLegend) {
            return [];
        } else {
            return [{
                data: getData().map((item, index) => ({
                        id: item.id,
                        value: Math.random(),
                        color: item.color,
                        // format id as tspans (supports breaking to new lines)
                        label: getTspanGroups(item.id, 250)
                    })
                ),
                anchor: 'bottom-left',
                direction: 'column',
                justify: false,
                translateX: 0,
                translateY: ((props.lines.length +  1) * legendItemHeight),
                itemWidth: 1300,
                itemHeight: legendItemHeight,
                itemsSpacing: 1,
                symbolSize: 15,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }]
        }
    }

    function getMargins() {
        if(props.showLegend) {
            return { top: 30, right: 50, bottom: ((props.lines.length +  1) * legendItemHeight), left: 50 }
        } else {
            return { top: 30, right: 50, bottom: 50, left: 50 }
        }
    }

    function getHeight() {
        if(props.showLegend) {
            // plot height is bigger when using legends - need some space for them
            return 300 + ((props.lines.length +  1) * legendItemHeight)
        } else {
            return 300
        }
    }

    return (
        <Paper className={`${classes.paper}`}>
            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <Typography className={classes.title}>
                        {props.yTitle}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ height: `${getHeight()}px` }} >
                    <ResponsiveLine
                        curve="linear"
                        data={getData()}
                        margin={getMargins()}
                        colors={d => d.color}
                        axisTop={null}
                        axisRight={null}
                        isInteractive={true}
                        layers={['grid', 'markers', 'axes', 'areas', DashedLine, 'slices', 'points', 'mesh', 'legends']}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: getAxisBottomRotation(getMaxXDataLength(props)),
                            format: '%b %d',
                            legend: props.xTitle,
                            legendPosition: 'middle',
                            legendOffset: 45
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 0,
                            tickRotation: 0,
                            format: getYFormat(props.showAsPercentage)
                        }}
                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%d-%H-%M',
                            precision: 'minute',
                            useUTC: true
                        }}
                        xFormat="time:%Y-%m-%d"
                        yFormat={getYFormat(props.showAsPercentage)}
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                        }}
                        enableGridX={false}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        enableSlices="x"
                        sliceTooltip={({ slice }) => (
                                <div
                                    style={{
                                        background: 'white',
                                        padding: '9px 12px',
                                        border: '1px solid #ccc',
                                    }}>
                                    {slice.points.map(point => (
                                        <div
                                            key={point.id}
                                            style={{
                                                color: point.serieColor,
                                                padding: '3px 0',
                                            }}
                                        >

                                            {getSeriesHoverText(point)}
                                            [{point.data.yFormatted}]
                                        </div>
                                    ))}
                                </div>)
                        }
                        legends={getLegend()}
                    />
                </Grid>
            </Grid>
           {props.infoText &&
                <Grid item xs={12} >
                    <InfoText info={props.infoText}/>
                </Grid>
              }
        </Paper >

    )
});

export default MultiTimeLinePlot;
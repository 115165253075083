import React from 'react';

import {createStyles, makeStyles} from '@material-ui/core/styles';
import {Grid, Container, LinearProgress, Fade} from "@material-ui/core";

import {KPICard} from "../Cards/KPICard"
import {AssistantSelector, FilterSettings} from "../Selectors/AssistantSelector"
import {Theme} from "../../styles/theme/types";
import {fetchAssistantStatsAcoda} from "../../utils/fetch";
import {someDaysAgo} from "../../utils/utils";
import {
    AGGREGATED_CO_RATE_INFO_TEXT_ACODA,
    AVERAGE_ASSISTANT_DURATION,
    AVERAGE_OVERALL_DURATION,
    DURATION_OF_EACH_VISIT,
    NUMBER_OF_VISITS_BY_BROWSER, NUMBER_OF_VISITS_BY_PLATFORM,
    NUMBER_OF_VISITS_PER_DURATION_GROUP,
    NUMBER_OF_VISITS_PER_QUESTIONS_SEEN,
    TOTAL_NUMBER_OF_CLICKOUTS,
    TOTAL_NUMBER_OF_VISITS_INFO_TEXT_ACODA,
    VISITS_AND_CLICKOUTS_PER_USER_TYPE
} from "../InfoTexts/AcodaInfoTexts";
import LinePlot from "../Plots/LinePlot";
import BarPlot from "../Plots/BarPlot";


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        background: theme.palette.greys.bcg.mainBcg
    },
    container: {
        marginTop: '20px'
    },
    progressCircle: {
        position: 'absolute',
        left: '50%'
    },
    relativePosition: {
        position: 'relative'
    },
    negativeMarginTop: {
        marginTop: "-16px"
    }
}));


const dummyAssistantStatistics = {
    'total_number_of_visits': 0,
    'total_number_of_clickouts': 0,
    'average_clickout_rate': 0,
    'average_assistant_and_survey_time': 0,
    'average_assistant_time': 0,
    'duration_of_visits': [0],
     'duration_groups_count': [
        {
            'Duration Group': 0,
            'Number of visits': 0
        }
    ],
    'number_of_questions_seen': [
        {
            'Number of questions': 0,
            'Number of visits': 0,
        }
    ],
    'user_types_visits': [
        {
            'user_type': 'Rational',
            'Number of visits': 0,
            'Number of clickouts': 0,
        },
        {
            'user_type': 'Intuitive',
            'Number of visits': 0,
            'Number of clickouts': 0,
        }
    ],
     'by_browser_stats': [
        {
            'browser_name': 'Chrome',
            'Number of visits': 0,
            'Number of clickouts': 0,
        }
    ],
    'by_platform_stats': [
        {
            'platform_name': 'Windows',
            'Number of visits': 0,
            'Number of clickouts': 0,
        }
    ],
};

export const AcodaDashboard: React.FC = () => {

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false)
    const [allStatistics, setAllStatistics] = React.useState(dummyAssistantStatistics)

    const fetchAssistantStatistics = async (filterSettings: FilterSettings) => {
        setLoading(true);
        const response = await fetchAssistantStatsAcoda(filterSettings).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        });
        if (response && response.ok) {
            const assistantStatistics = await response.json();
            setAllStatistics(assistantStatistics.response);
            console.log(assistantStatistics)
        }
    };

    return (
        <Container maxWidth={false} className={`${classes.container}`}>
            <Grid container spacing={5} className={`${classes.root}`}>
                <Grid item xs={12}>
                    <AssistantSelector
                        initialStartDate={someDaysAgo()}
                        initialEndDate={Date.now()}
                        projectName={'Acoda'}
                        goButtonPressed={fetchAssistantStatistics}
                    >
                    </AssistantSelector>

                    <Fade in={loading} unmountOnExit>
                        <Grid item xs={12}>
                            <LinearProgress className={classes.negativeMarginTop}/>
                        </Grid>
                    </Fade>
                </Grid>

                <Grid container item xs={12} spacing={1} className={classes.negativeMarginTop}>
                    <Grid item xs={3}>
                        <KPICard title='total number of visits'
                                 value={String(allStatistics.total_number_of_visits)}
                                 iconPath='M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z'
                                 infoText={TOTAL_NUMBER_OF_VISITS_INFO_TEXT_ACODA}
                                 percentageChange={0}
                                 percentageDescription=''>
                        </KPICard>
                    </Grid>
                    <Grid item xs={3}>
                        <KPICard title='total number of clickouts'
                                 value={String(allStatistics.total_number_of_clickouts)}
                                 iconPath='M9 11.24V7.5C9 6.12 10.12 5 11.5 5S14 6.12 14 7.5v3.74c1.21-.81 2-2.18 2-3.74C16 5.01 13.99 3 11.5 3S7 5.01 7 7.5c0 1.56.79 2.93 2 3.74zm9.84 4.63l-4.54-2.26c-.17-.07-.35-.11-.54-.11H13v-6c0-.83-.67-1.5-1.5-1.5S10 6.67 10 7.5v10.74l-3.43-.72c-.08-.01-.15-.03-.24-.03-.31 0-.59.13-.79.33l-.79.8 4.94 4.94c.27.27.65.44 1.06.44h6.79c.75 0 1.33-.55 1.44-1.28l.75-5.27c.01-.07.02-.14.02-.2 0-.62-.38-1.16-.91-1.38z'
                                 infoText={TOTAL_NUMBER_OF_CLICKOUTS}
                                 percentageChange={0}
                                 percentageDescription=''>
                        </KPICard>
                    </Grid>
                    <Grid item xs={3}>
                        <KPICard title='Average clickout rate'
                                 value={`${allStatistics.average_clickout_rate.toFixed(2)}%`}
                                 iconPath='M9 11.24V7.5C9 6.12 10.12 5 11.5 5S14 6.12 14 7.5v3.74c1.21-.81 2-2.18 2-3.74C16 5.01 13.99 3 11.5 3S7 5.01 7 7.5c0 1.56.79 2.93 2 3.74zm9.84 4.63l-4.54-2.26c-.17-.07-.35-.11-.54-.11H13v-6c0-.83-.67-1.5-1.5-1.5S10 6.67 10 7.5v10.74l-3.43-.72c-.08-.01-.15-.03-.24-.03-.31 0-.59.13-.79.33l-.79.8 4.94 4.94c.27.27.65.44 1.06.44h6.79c.75 0 1.33-.55 1.44-1.28l.75-5.27c.01-.07.02-.14.02-.2 0-.62-.38-1.16-.91-1.38z'
                                 infoText={AGGREGATED_CO_RATE_INFO_TEXT_ACODA}
                                 percentageChange={0}
                                 percentageDescription=''>
                        </KPICard>
                    </Grid>
                    <Grid item xs={3}>
                        <KPICard title='Average duration (assistant and survey)'
                                 value={`${allStatistics.average_assistant_and_survey_time.toFixed(1)}s`}
                                 iconPath='M11 17c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1zm0-14v4h2V5.08c3.39.49 6 3.39 6 6.92 0 3.87-3.13 7-7 7s-7-3.13-7-7c0-1.68.59-3.22 1.58-4.42L12 13l1.41-1.41-6.8-6.8v.02C4.42 6.45 3 9.05 3 12c0 4.97 4.02 9 9 9 4.97 0 9-4.03 9-9s-4.03-9-9-9h-1zm7 9c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM6 12c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z'
                                 infoText={AVERAGE_OVERALL_DURATION}
                                 percentageChange={0}
                                 percentageDescription=''>
                        </KPICard>
                    </Grid>
                    <Grid item xs={3}>
                        <KPICard title='Average Assistant Only Duration'
                                 value={`${allStatistics.average_assistant_time.toFixed(1)}s`}
                                 iconPath='M11 17c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1zm0-14v4h2V5.08c3.39.49 6 3.39 6 6.92 0 3.87-3.13 7-7 7s-7-3.13-7-7c0-1.68.59-3.22 1.58-4.42L12 13l1.41-1.41-6.8-6.8v.02C4.42 6.45 3 9.05 3 12c0 4.97 4.02 9 9 9 4.97 0 9-4.03 9-9s-4.03-9-9-9h-1zm7 9c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM6 12c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z'
                                 infoText={AVERAGE_ASSISTANT_DURATION}
                                 percentageChange={0}
                                 percentageDescription=''>
                        </KPICard>
                    </Grid>
                </Grid>

                <Grid container item spacing={3} className={classes.relativePosition}>
                    <Grid item xs={4}>
                        <LinePlot xTitle='Visits' yTitle='Duration per visit' dataTitle='Visits'
                                      xData={[...Array(allStatistics.duration_of_visits.length).keys()]}
                                      yData={allStatistics.duration_of_visits}
                                      infoText={DURATION_OF_EACH_VISIT}>
                        </LinePlot>
                    </Grid>

                      <Grid item xs={4}>
                        <BarPlot
                            data={allStatistics.user_types_visits}
                            keys={['Number of visits', 'Number of clickouts']}
                            indexBy={'user_type'}
                            dataTitle='Vists and clickouts for user-types'
                            yTitle='Vists and clickouts'
                            infoText={VISITS_AND_CLICKOUTS_PER_USER_TYPE}>
                        </BarPlot>
                    </Grid>
                     <Grid item xs={4}>
                        <BarPlot
                            data={allStatistics.number_of_questions_seen}
                            keys={['Number of visits']}
                            indexBy={'Number of questions'}
                            dataTitle='Number of visits for questions seen count'
                            yTitle='Number of visits'
                            infoText={NUMBER_OF_VISITS_PER_QUESTIONS_SEEN}>
                        </BarPlot>
                    </Grid>
                </Grid>
                  <Grid container item spacing={3} className={classes.relativePosition}>
                      <Grid item xs={4}>
                        <BarPlot
                            data={allStatistics.duration_groups_count}
                            keys={['Number of visits']}
                            indexBy={'Duration Group'}
                            dataTitle='Number of visits foreach duration group'
                            yTitle='Number of visits'
                            infoText={NUMBER_OF_VISITS_PER_DURATION_GROUP}>
                        </BarPlot>
                    </Grid>

                  <Grid item xs={4}>
                    <BarPlot
                        data={allStatistics.by_browser_stats}
                        keys={['Number of visits', 'Number of clickouts']}
                        indexBy={'browser_name'}
                        dataTitle='Visits split by browser'
                        yTitle='Number of visits/clickouts'
                        infoText={NUMBER_OF_VISITS_BY_BROWSER}>
                    </BarPlot>
                </Grid>

               <Grid item xs={4}>
                    <BarPlot
                        data={allStatistics.by_platform_stats}
                        keys={['Number of visits', 'Number of clickouts']}
                        indexBy={'platform_name'}
                        dataTitle='Visits split by platform'
                        yTitle='Number of visits/clickouts'
                        infoText={NUMBER_OF_VISITS_BY_PLATFORM}>
                    </BarPlot>
                </Grid>

                  </Grid>
            </Grid>
        </Container>
    );
}

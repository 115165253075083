import {colors} from './colors';

const palette = {
    primary: {
        main: colors.primary.main,
        // dark: colors.primary.dark,
    },
    secondary: {
        main: colors.secondary.main,
    },
    cartIcon: colors.cartIcon,
    error: {main: colors.error},
    black: colors.black,
    white: colors.white,
    greys: {
        bcg: {
            mainBcg: colors.greyMainBcg,
            section: colors.greyLightest,
            element: colors.greyLight,
        },
        disabled: colors.greyDisabled,
        default: colors.greyDefault,
        dark: colors.greyDark,
    },
    grey: {
        50: colors.greyLightest,
    },
    divider: colors.greyLight,
    background: {
        default: colors.greyLightest,
    },
};

export default palette;

import * as React from "react";

import AITDashboard from "../components/Dashboards/AITDashboard";


class AiTags extends React.Component<{}> {
    public render() {
        console.log('rendering ai tags ');
        return <AITDashboard/>
    }
}

export default AiTags
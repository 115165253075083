import React from "react";

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import {createStyles, Theme} from "@material-ui/core";

import Header from "./Tables/ReportHeader";

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        fontSize: '0.8125rem',
        color: 'rgba(0, 0, 0, 0.87)',
        marginTop: theme.spacing(0.5)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
    },
});


export interface Props {
    classes: any,
    timestamp: number,
    snackbar?: React.ReactElement
}

function LastGoLiveGrid({classes, timestamp, snackbar}: Props) {
    return (
        <Paper className={classes.root}>
            <Header header={'Current LGL timestamp'}/>
            <Grid container spacing={0} direction='column'>
                { timestamp > 0 &&
                    <Grid container spacing={0} direction='column'>
                        <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                            <Paper className={classes.paper}>{getFormattedDate(timestamp)}</Paper>
                        </Grid>
                        <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                            <Paper className={classes.paper}>&nbsp;</Paper>
                        </Grid>
                    </Grid>
                }
            </Grid>
            {snackbar}
        </Paper>
    );
}

const getFormattedDate = (unix: number) => {
    const date = new Date(unix);
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  date.getHours() + ":" + date.getMinutes();
};

export default withStyles(styles)(LastGoLiveGrid);

import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import {FormControl} from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";

import { Theme } from "../../styles/theme/types";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(-1.5),
            marginLeft: theme.spacing(-0.5),
            height: theme.spacing(1)
        },
        checkboxLabel: {
            fontSize: "14.4px",
            textAlign: "center",
        }
    }),
);

interface Props {
    title: string;
    enabled: boolean;
    valueChanged: (event: any) => void;
}

export const CheckBox: React.FC<Props> = ({ title, enabled, valueChanged }) => {
    const classes = useStyles();

    const [selectedInput, setSelectedInput] = React.useState(false);


    const selectionChanged = (event: any) => {
        valueChanged(event)
        setSelectedInput(event.target.checked)
    }

    return (
       <FormControl className={classes.formControl}>
           <p className={classes.checkboxLabel}>
             <Checkbox
                checked={selectedInput}
                onChange={selectionChanged}
                size="small"
             />
              {title}
         </p>
       </FormControl>
    );

};
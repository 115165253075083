import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';

interface Color {
    main: string;
    // dark: string;
}

interface Colors {
    primary: Color;
    secondary: Color;
    cartIcon: string;
    error: string;
    black: string;
    white: string;
    greyDisabled: string;
    greyLightest: string;
    greyLight: string;
    greyMainBcg: string;
    greyDefault: string;
    greyDark: string;
}

export const colors: Colors = {
    primary: {
        main: indigo[500],
    },
    secondary: {
        main: pink[500],
    },
    cartIcon: '#b6cc32',
    error: '#e6007d',
    black: '#333',
    white: '#fff',
    greyLightest: '#fcfcfd',
    greyMainBcg: '#f4f6f8',
    greyLight: '#f0f2f6',

    greyDisabled: '#dadfe9',
    greyDefault: '#a1aabb',
    greyDark: '#6f7889',
};

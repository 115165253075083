import React from 'react';

import { area, curveMonotoneX } from 'd3-shape';

import { Datum, CustomLayerProps, Serie } from '@nivo/line'
// @ts-ignore
import { Defs } from '@nivo/core';

import { binomialDeviation } from '../../Dashboards/data/StatisticsUtils';

export const ConfidenceIntervalLayer: (props: any) => any = (props) => (layerProps: CustomLayerProps): React.ReactNode => {

    const confidenceAreaGenerator = (data: { data: Datum }[]) => area<{ data: Datum }>()
            .x(s => layerProps.xScale(s.data.x as number))
            // @ts-ignore
            .y0(s => Math.min(layerProps.innerHeight, layerProps.yScale(s.data.y as number - s.data.c as number)))
            .y1(s => Math.max(0, layerProps.yScale(s.data.y as number + s.data.c as number)))
            .curve(curveMonotoneX)(data)
    return (
        <>
            <Defs
                defs={[...layerProps.data.keys()].map((index: number) => ({
                    id: `pattern${index}`,
                    type: 'patternLines',
                    background: 'transparent',
                    color: props.lines[index].color,
                    lineWidth: 0.5,
                    spacing: 6,
                    rotation: 45,
                }))}
            />
            {[...layerProps.data.keys()].map((index: number) => (
                <path
                    // @ts-ignore
                    d={confidenceAreaGenerator(layerProps.series[index].data) as string}
                    fill={`url(#pattern${index})`}
                    fillOpacity={0.6}
                    stroke={props.lines[index].color}
                    strokeWidth={0.5}
                    strokeOpacity={0.6}
                    key={index}
                />
            ))}
        </>
    )
}



export const StandardDeviationLayer: (props: any) => any = (props) => (layerProps: CustomLayerProps): React.ReactNode => {

    const markersForSerie = (serie: Serie, index: number) =>
        serie.data.map((s: Datum) => {
            const deviation = binomialDeviation(s.data.y as number, s.data.n, true)
            const topY = Math.max(0, layerProps.yScale(s.data.y as number + deviation))
            // @ts-ignore
            const bottomY = Math.min(layerProps.innerHeight, layerProps.yScale(s.data.y as number - deviation))
            const deviationThickness = 1
            const deviationWidth = (layerProps.pointSize || 4) / 2
            return (
                <>
                    <line
                        key={`${s.data.x}${s.data.y}`}
                        x1={layerProps.xScale(s.data.x as number)}
                        x2={layerProps.xScale(s.data.x as number)}
                        y1={topY} y2={bottomY}
                        stroke={props.lines[index].color}
                        strokeWidth={deviationThickness} />

                    <line
                        key={`${s.data.x}${s.data.y}top`}
                        x1={layerProps.xScale(s.data.x as number) + deviationWidth}
                        x2={layerProps.xScale(s.data.x as number) - deviationWidth}
                        y1={topY} y2={topY}
                        stroke={props.lines[index].color}
                        strokeWidth={deviationThickness} />
                    <line
                        key={`${s.data.x}${s.data.y}bottom`}
                        x1={layerProps.xScale(s.data.x as number) + deviationWidth}
                        x2={layerProps.xScale(s.data.x as number) - deviationWidth}
                        y1={bottomY} y2={bottomY}
                        stroke={props.lines[index].color}
                        strokeWidth={deviationThickness} />
                </>
            )
        })

    // @ts-ignore
    const markers = () => layerProps.series.flatMap((s: Serie, index: number) => markersForSerie(s, index))

    return (
        <>
            {markers()}
        </>
    )
}
import * as React from 'react';

import classNames from "classnames";

import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ReportHeader from "./ReportHeader";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            overflowX: 'auto'
        },
        tableCell: {
            fontSize: '0.8125rem',
            borderBottom: '1px solid #eeeeee'
        },
        cursorPointer: {
            cursor: 'pointer'
        },
        tableHead: {
            ...theme.typography.subtitle1,
            borderBottom: '1px solid #eeeeee',
            backgroundColor: '#fafafa',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            whiteSpace: 'nowrap'
        },
        header: {
            margin: 6,
            ...theme.typography.h2
        },
        selectedRow: {
            backgroundColor: theme.palette.greys.bcg.element
        }
    }),
);

interface CellTextBuilder {
    cellObject: object;
    handler: (cellObject: object) => string;
}

interface RowProps {
    idx: number;
    rowData: CellTextBuilder[]; // cells
    onRowSelect?: (rowNumber: number) => void;
    selected?: number;
    classes?: any;
}

interface TableProps {
    tableTitle: string;
    tableHead: string[];
    tableRows: RowProps[];
    rowSelected?: (rowNumber: number) => void;
    selectedRowIdx?: number;
}

export const CptRow: React.FC<RowProps> = ({idx, rowData, onRowSelect, selected, classes}) =>
    <TableRow className={classNames(classes.tableCell,
        {[classes.selectedRow]: selected === idx},
        {[classes.cursorPointer]: onRowSelect !== undefined})}
                     onClick={() => {
        if (onRowSelect) {
            onRowSelect(idx)
        }
    }}>
        {rowData.map((builder, idx) =>
            <TableCell key={idx} align={(idx === rowData.length-1)?'right':'left'}>
                {builder.handler(builder.cellObject)}
            </TableCell>
        )}
    </TableRow>;

export const CptTable: React.FC<TableProps> = ({tableTitle, tableHead, tableRows, rowSelected, selectedRowIdx}) => {
    const classes = useStyles();
    if (!rowSelected) {
        classes.cursorPointer = ''
    }

    if (tableRows) {
        return (
            <Paper className={classes.root}>
                <ReportHeader header={tableTitle}/>
                <Table>
                    <TableHead>
                        <TableRow key='tr0'>
                            {tableHead.map((headText, idx) =>
                             <TableCell className={classes.tableHead} align={(idx === tableHead.length-1)?'right':'left'}>
                                    {headText}
                                </TableCell>
                           )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRows.map((tableRow, idx) => (
                            <CptRow idx={idx} rowData={tableRow.rowData} onRowSelect={() => {
                                if (rowSelected) {
                                    rowSelected(idx);
                                }
                            }} selected={selectedRowIdx} classes={classes}/>
                        ))}
                    </TableBody>
                </Table>

            </Paper>

        );
    } else {
        return <></>
    }
};


export default CptTable

export const sum = (list: number[]) => list.reduce((a, v) => a + v, 0);
export const average = (list: number[]) => sum(list) / list.length;
export const last = (list: number[]) => list[list.length - 1];
export const countOccurrences = (arr: string[], val: string) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

export function deduplicateDates(dates: string[]) {
    // given a list of dates, format them in a way that the bar plot accepts
    // e.g., if we have two dates with value 'Oct 02', then create two different dates 'Oct 02_1' and 'Oct 02_2'
    const deDuplicatedDates: string[] = [];

    // initial date has 0 pre-occurrences
    deDuplicatedDates.push(dates[0] + "_0");

    for(let i=1; i< dates.length; i++) {
        // count the occurrences of this date in the previous dates
        const occurrences = countOccurrences(dates.slice(0, i), dates[i])
        deDuplicatedDates.push(dates[i] + "_" + occurrences)
    }
    return deDuplicatedDates
}

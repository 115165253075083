import { sum } from "./ListUtils"

export interface SeriesWithOccurrences {
    id: string;
    xData: number[];
    yData: number[];
    occurrences: number[];
    color: string;
}

export const weighedAverage = (list: number[], weights: number[]) =>
        list.reduce((previousValue: number, currentValue: number, currentIndex: number) =>
         previousValue + (currentValue * weights[currentIndex] / sum(weights)), 0)


export const binomialDeviation = (probability: number, observations: number, isRange0to1: boolean) => {
    // probability can be passed either in range 0-1, or in range 0-100. We check the isRange0to1 flag, and we assign
    // the correct max probability value to calculate the binomial deviation
    let maxProb = 1
    if (!isRange0to1) {
        maxProb = 100;
    }

    // standard deviation for a binomial distribution sqrt(P*(maxProbability-P)/N)
    if (observations === 0) {
        return 0;
    } else {
        return (probability * (maxProb - probability) / observations) ** 0.5;
    }
}

export const binomialConfidence = (probability: number, observations: number) => {
    // confidence interval = z * standard deviation
    const z = 1.96 // for 95% confidence
    return binomialDeviation(probability, observations, true) * z
}

export const cummulativeBinomialConfidence = (series: SeriesWithOccurrences, index: number) => {
    // cumulative confidence interval from 0 until `index`
    const occurrences = series.occurrences.slice(0, index)
    const clickouts = series.yData.slice(0, index)
    const totalVisits = sum(occurrences)
    const totalClickout = weighedAverage(clickouts, occurrences)
    return binomialConfidence(totalClickout, totalVisits)
}

export function mean(v: number[]) {
    if (undefined !== v && v.length) {
        return v.reduce((a: number, b: number) => (a + b), 0) / v.length;
    } else {
        return 0;
    }
}


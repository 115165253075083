import * as React from 'react';

import {withStyles, Theme} from '@material-ui/core/styles';
import {createStyles, WithStyles} from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
    loader: {
        backgroundImage: 'url("images/speedometer2.png")',
        width: 90,
        height: 44,
        display: 'block',
        margin: 'auto',
        marginLeft: theme.spacing(1.5),
        backgroundSize: 62,
        backgroundRepeat: 'no-repeat'
    },
    arrow: {
        backgroundImage: 'url("images/speedometer-arrow.png")',
        width: 60,
        height: 62,
        display: 'block',
        animation: `12s ${theme.transitions.easing.easeOut} 200ms forwards`,
        animationName: '$arrowRotate',
        backgroundSize: 62,
        backgroundRepeat: 'no-repeat'
    },
    '@keyframes arrowRotate': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '70%': {
            transform: 'rotate(130deg)'
        },
        '85%': {
            transform: 'rotate(120deg)'
        },
        '100%': {
            transform: 'rotate(140deg)'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    loading: boolean,
    classes: any
}

const Loader: React.FC<Props> = ({loading, classes}) => (
        <span>
            {loading &&
                <span className={classes.loader}>
                    <span className={classes.arrow}></span>
                </span>}
        </span>
    );

const LoadingAnimation = withStyles(styles)(Loader);

export default LoadingAnimation;


























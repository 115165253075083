import * as React from 'react';

import {createStyles} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography"

import {Theme} from "../../styles/theme/types";


const styles = (theme: Theme) => createStyles({
    root: {
        padding: 6,
        textAlign: 'center',
        ...theme.typography.h6
    }
});

interface Props {
    header: string,
    classes: any
}

const ReportHeader: React.FC<Props> = ({ header, classes }) =>
    <Typography className={classes.root}>
        {header}
    </Typography>;

export default withStyles(styles)(ReportHeader);
import * as React from 'react';

import * as PropTypes from 'prop-types';

import { withStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { createStyles, WithStyles } from "@material-ui/core";

import { Statement } from "../../types/types";

import AITooltip from "./AITooltip";
import ReportHeader from "./ReportHeader";

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        overflowX: 'auto'
    },
    tableCell: {
        fontSize: '0.8125rem',
    },
    header: {
        margin: 6
    },
    tableHead: {
        ...theme.typography.subtitle1,
        borderBottom: '1px solid #eeeeee',
        backgroundColor: '#fafafa',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        whiteSpace: 'nowrap'
    },
});

interface Props extends WithStyles<typeof styles>{
    rows: Statement[];
    classes: any;
    snackbar?: React.ReactElement;
}

AggregatedReportTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

function AggregatedReportTable({ classes, rows, snackbar }: Props) {
    return (
            <Paper className={classes.root}>
                <ReportHeader header={'Latest Aggregated Report'}/>
                <Table>
                    <TableHead>
                        <TableRow key='ar0' className={classes.tableHead}>
                            <TableCell>Statement Group</TableCell>
                            <TableCell align="right">Statements</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((statement, idx) => (
                            <TableRow key={idx}>
                                <TableCell className={classes.tableCell}>
                                    <AITooltip title={statement.answerOrigin ? 'questionOrigin/answerOrigin: ' + statement.questionOrigin + '/' + statement.answerOrigin : ''}>
                                        <span>{statement.statementGroup}</span>
                                    </AITooltip>
                                </TableCell>
                                <TableCell align="right">
                                    <AITooltip title={statement.answerOrigin ? 'questionOrigin/answerOrigin: ' + statement.questionOrigin + '/' + statement.answerOrigin : ''} className={classes.tableCell}>
                                    <span>{statement.texts.filter(element =>
                                        element.type === 'paragraph').map(el => el.text)}
                                    </span>
                                    </AITooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {snackbar}
            </Paper>

    );
}


export default withStyles(styles)(AggregatedReportTable);
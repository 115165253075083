import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, Theme } from '@material-ui/core/styles';


const DataTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.95)',
        maxWidth: 520,
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default DataTooltip;

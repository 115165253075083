import React from 'react';

import {Serie} from "@nivo/line";

// custom line styles for different line ids
const styleById: { [styleName: string]: object} = {
    'Observed States Threshold ': {
        strokeDasharray: '10, 6',
        strokeWidth: 2,
    },
    default: {
        strokeWidth: 2
    },
}

export const getYFormat = (formatAsPercentage: boolean) => formatAsPercentage ? " >-.2%" : " >-.2f"

export const DashedLine: any = ({series, lineGenerator, xScale, yScale} : {series: Serie[], lineGenerator: any, xScale: any, yScale: any }) =>
    series.map(({ id, data, color }) => (
        <path
            key={id}
            d={lineGenerator(
                data.map(d => ({
                    x: xScale(d.data.x),
                    y: yScale(d.data.y),
                }))
            )}
            fill="none"
            stroke={color}
            style={styleById[id] || styleById.default}
        />)
    )

import { pathsWithSharedLogic } from "../DFDashboard";
import { PATH_TO_QUESTION_ORIGINS_REGEX } from "../../../utils/utils";

import { QuestionText } from "./HealthDbStatistics";

export const TOP_PATHS_TO_SHOW = 3

const isInPath = (path: string[], origin: string, idx: number) => {
    if (path.length > idx) {
        return path[idx] === origin
    }
    return false
};

const getUniqueOriginsFromPaths = (pathArray: string[]): string[] => {
    const originsNonUnique = pathArray.map((path: string) => path.split(PATH_TO_QUESTION_ORIGINS_REGEX)).flat();
    return originsNonUnique.filter((v, idx) => originsNonUnique.indexOf(v) === idx);
};

export const getQuestionFormatted = (origin: string, questionTexts: QuestionText[],
                              includeOrigin = true) => {
    const questionText: any = questionTexts.find(el => el[origin]);

    if (((questionText || {})[origin] || {})['question_text']!== undefined) {
        if (includeOrigin) {
            return `${questionText[origin]['question_text']} (${origin})`
        } else {
            return questionText[origin]['question_text']
        }
    } else {
        // if not found, fallback to origin only
        return `(${origin})`
    }
}

const getAnswerText = (origin: string | number, answerOrigin: string | number, questionTexts: QuestionText[]) => {
    const defaultAnswerText = `(${answerOrigin})`
    const question: any = questionTexts.find(el => el[origin]);

    if(question === undefined || question[origin]['answers'] === undefined) {
        return defaultAnswerText
    }
    // find answer
    const answerText = (question[origin] || {})['answers'].filter((a: {[key: string]: string} )=> a['answer_origin'] ===
    answerOrigin)
    if (answerText.length !== 0) {
        return answerText[0]['answer_text'];
    } else {
        return defaultAnswerText
    }
}


export const getPathAsString = (path: string, questionTexts: QuestionText[]) => {
    // given a path with comma separated question origins, convert it to string to make it human readable
    const questionOrigins = path.split(PATH_TO_QUESTION_ORIGINS_REGEX);
    let pathAsString = '';

    for(const origin of questionOrigins) {
        const formatted = getQuestionFormatted(origin, questionTexts);

        if (pathAsString !== '') { pathAsString += ' - ' }

        pathAsString += `${formatted} \n`;
    }

    return pathAsString
};

export const getAnswerVectorAsString = (answer_vector: string, questionTexts: QuestionText[]) => {
    // given a path with comma separated question origins, convert it to string to make it human readable
    const vectors = answer_vector.split(PATH_TO_QUESTION_ORIGINS_REGEX);

    let pathAsString = '';

    for(const vector of vectors) {
        const questionOrigin = vector.split('.')[0]
        const answerOrigin = vector.split('.')[1]

        const questionFormatted = getQuestionFormatted(questionOrigin, questionTexts, false);
        const answerFormatted = getAnswerText(questionOrigin, parseInt(answerOrigin), questionTexts)

        if(pathAsString !== '') { pathAsString += ' » ' }

        pathAsString += `${questionFormatted} - ${answerFormatted}`;
    }

    return pathAsString
};

export const uniquePopularPaths = (trainings: any[]): string[] => {
    let popularPathsArray: string[] = [];
    trainings.forEach((training: any) => {
        const pp = training.popular_paths? training.popular_paths: [];
        const newPaths = pp.filter((item: string, idx: number) =>  popularPathsArray.indexOf(item) === -1);
        popularPathsArray = popularPathsArray.concat(newPaths)
    });
    return popularPathsArray
};

export const isValidSharedPath = (pathName: string) => {
    if (!pathsWithSharedLogic.includes(pathName))  {
        // TODO (ilir): nice error message from here, not just logging
        console.log('ERROR: Invalid path property name for training. Choose one of: ' + pathsWithSharedLogic)
        return false
    }

    return true
}

export const getQuestionOriginsFromPath = (trainings: any[], pathName: string) => {
    // gets the question origins from some of the paths defined as properties in the training object
    // currently only highest_co_paths and highest_do_paths are supported

    if (!isValidSharedPath(pathName))  { return []; }

    // filter out trainings that do not have the path property tracked
    trainings = trainings.filter(t => t[pathName] !== undefined);

     // get all paths and extract the question origins
    const allPaths = trainings.map((v) => v[pathName].dynamic_question_path).flat();

    return getUniqueOriginsFromPaths(allPaths);
}

export const getBestNextQuestionOrigins = (trainings: any[]) => {
    // filter out trainings that do not have the best_next_question tracked
    trainings = trainings.filter(t => t['best_next_qs'] !== undefined);

    // gather next_question properties
    const next_questions = trainings.map((v) => v['best_next_qs'].next_question).flat();

    // gather only question origins from answer vectors
    const answer_vectors =  trainings.map((v) =>
        v['best_next_qs'].answer_vector.map((a: string) =>
        a.split(PATH_TO_QUESTION_ORIGINS_REGEX).map((vector: string) =>  vector.split('.')[0])).flat()).flat()

    // return question origins from both next_question and answer_vectors
    return next_questions.concat(answer_vectors)
}


export const getPopularPathsQuestionOrigins = (trainings: any[]) => {
    const popularPathsArray = uniquePopularPaths(trainings);
    return getUniqueOriginsFromPaths(popularPathsArray);
}

export const getPopularPaths = (trainings: any[], questionTexts: QuestionText[]) => {
    const lastTrainingPopularPaths = trainings.reverse().find(tr => !!(tr.popular_paths && tr.popular_paths.length));

    let lastTrainingPopularPath: string[][] = [];
    if (lastTrainingPopularPaths) {
        lastTrainingPopularPath = lastTrainingPopularPaths.popular_paths.map((path: string) => path.split(PATH_TO_QUESTION_ORIGINS_REGEX));
    }

    const popularPathsArray = uniquePopularPaths(trainings);
    const popularPaths = popularPathsArray.map((path: string) => path.split(PATH_TO_QUESTION_ORIGINS_REGEX));

    return  popularPaths.map((qoArray: string[]) => {
        const questionsInPath: {origin: string, text: string}[] = [];
        let isLastPath = true;
        qoArray.forEach((qo, idx) => {
            const qoTextPair = questionTexts.find( el => el[qo]);
            if (qoTextPair === undefined) { return; }
            const origin = Object.keys(qoTextPair)[0];
            questionsInPath.push({'origin': origin, 'text': qoTextPair[origin]['question_text']})
        });
        function _checkIfAllQuestionsIn(path: string[]) {
            questionsInPath.forEach((qo, idx) => {
                isLastPath = isLastPath && isInPath(path, qo.origin, idx);
            });
        }
        for (const p of lastTrainingPopularPath) {
            isLastPath = true;
            _checkIfAllQuestionsIn(p);
            if (isLastPath) { break; }
        }
        return {questions: questionsInPath, last: isLastPath}
    });
}

export const getPathDataFormatted = (trainings: any[], pathName: string, questionTexts: QuestionText[]) => {

    if (!trainings.length || !isValidSharedPath(pathName) || !questionTexts.length) { return {}; }

    // filter out trainings that do not have the path property tracked
    trainings = trainings.filter(t => t[pathName] !== undefined);

    // filter out trainings that do not have the format expected for the path (properties named dynamic_question_path
    // and values
    trainings = trainings.filter(t =>
        t[pathName]['dynamic_question_path'] !== undefined && t[pathName]['values'] !== undefined
    );

    const pathDataFormatted: {[key: string]: any} = trainings.reduce((accumulatedValue, currentTraining) => {

        const pathData = currentTraining[pathName];

        for (const path_idx in pathData.dynamic_question_path) {
            const path = pathData.dynamic_question_path[path_idx];
            const path_value = pathData.values[path_idx];

            // if we are seeing this path for the first time, define data structure to store the results for it
            // store training dates, values for this path and its string representation
            if (!(path in accumulatedValue)) {
                accumulatedValue[path] = {
                    'training_dates': [],
                    'y_data': [],
                    'path_as_string': getPathAsString(path, questionTexts)
                };
            }

            accumulatedValue[path]['training_dates'].push(currentTraining.start_time);
            accumulatedValue[path]['y_data'].push(path_value);
        }

        return accumulatedValue;
    }, {});

    return selectMostOccurrentPaths(pathDataFormatted);
}

export function selectMostOccurrentPaths(paths: any) {
    // convert path to list of key, value items
    let items = Object.keys(paths).map(function(key) {
        return [key, paths[key]];
    });

    // sort by the number of occurrences (length of training_dates property)
    items.sort(function(first, second) {
        return second[1]["training_dates"].length - first[1]["training_dates"].length;
    });

    // keep top x paths
    items = items.slice(0, TOP_PATHS_TO_SHOW);

    // convert back to object
    const sortedPaths: {[key: string]: any} = {}

     for (let i = 0; i < items.length; i++) {
         const key = items[i][0];
         sortedPaths[key] = items[i][1];
    }
    return sortedPaths
}

export const getBestNextQuestions = (trainings: any[], questionTexts: QuestionText[]) => {

    if (!trainings.length || !questionTexts.length) { return {}; }

    // filter out trainings that do not have the format expected for the path (properties named next_question, rewards
    // and answer_vector
    trainings = trainings.filter(t =>
        (t['best_next_qs'] || {})['next_question'] !== undefined &&
        (t['best_next_qs'] || {})['reward'] !== undefined &&
        (t['best_next_qs'] || {})['answer_vector'] !== undefined
    );

    const bestNextQuestionFormatted: {[key: string]: any} = trainings.reduce((accumulatedValue, currentTraining) => {

        const currentBestNextQs = currentTraining['best_next_qs'];

        for (const idx in currentBestNextQs.answer_vector) {
            const answer_vector = currentBestNextQs.answer_vector[idx];
            const reward = currentBestNextQs.reward[idx];
            const next_question = currentBestNextQs.next_question[idx];

            // skip empty answer vectors
            if (answer_vector === "") { continue }

            if (!(answer_vector in accumulatedValue)) {
                accumulatedValue[answer_vector] = {
                    'training_dates': [],
                    'y_data': [],
                    'path_as_string': getAnswerVectorAsString(answer_vector, questionTexts),
                    'next_questions': [],
                };
            }

            accumulatedValue[answer_vector]['training_dates'].push(currentTraining.start_time);
            accumulatedValue[answer_vector]['y_data'].push(reward);
            accumulatedValue[answer_vector]['next_questions'].push(getQuestionFormatted(next_question, questionTexts))
        }

        return accumulatedValue;
    }, {});
    return selectMostOccurrentPaths(bestNextQuestionFormatted);
}


export const getDynamicQuestionsSeen = (trainings: any[]) => {
    trainings = trainings.filter(t => t['dynamic_questions_seen_count'] !== undefined);
    const numberOfDynamicQuestionsSeen: {[key: string]: any} = trainings.reduce((accumulatedValue, currentTraining) => {

        const currentSeenQuestions = currentTraining['dynamic_questions_seen_count'] ;
        for (const numQuestions in currentSeenQuestions) {
            const numberOfQuestions = numQuestions;
            const numberOfVisits = currentSeenQuestions[numQuestions];

            if (!(numberOfQuestions in accumulatedValue)) {
                accumulatedValue[numberOfQuestions] = {
                    "training_dates": [],
                    "y_data": [],
                    "hover_text": [],
                }
            }
            accumulatedValue[numberOfQuestions]['training_dates'].push(currentTraining.start_time);
            accumulatedValue[numberOfQuestions]['y_data'].push(numberOfVisits);
            accumulatedValue[numberOfQuestions]['hover_text'].push("Seen " + numQuestions + " dynamic questions")
        }

        return accumulatedValue;
    }, {});
    return numberOfDynamicQuestionsSeen;
}
import { DynamoDB } from 'aws-sdk'
import { AWSError } from 'aws-sdk/lib/error';
import { DocumentClient } from "aws-sdk/clients/dynamodb";

import {Answer, Question, Report, TrainingResults} from "../types/types";

export class DdHelper {

    public getTrainingResult(stage: string, env: string, advisorId: number, locale: number, trainingTarget: string): Promise<TrainingResults> {
        const params = {
            ExpressionAttributeValues: {
                ":v1": advisorId + "_" + locale + env + trainingTarget
            },
            KeyConditionExpression: "advisorIdLocaleEnvTrainingTarget = :v1",
            ScanIndexForward: false,
            TableName: stage + "_ai_training_results",
            Limit: 1
        };
        return new Promise<TrainingResults>( (fulfill, reject) => {
            const dynamoDb = new DynamoDB.DocumentClient();
            dynamoDb.query(params, (err: AWSError, data: DocumentClient.QueryOutput) => {
                if (!err) {
                    if (data.Items === undefined) { reject(err); } else {
                        const item = data.Items[0];
                        if (item) {
                            if (item['error']) {
                                fulfill({questions: [], error: item['error']});
                            } else {
                                try {
                                    const dataStats = item['dataStats'];
                                    const questions = item['qualityMetrics']['scores']['questions'];
                                    questions.forEach((question: Question) => {
                                        question.answers.forEach((answer: Answer) => {
                                            answer.positiveWeight = answer.meanCoefficient;
                                        })
                                    });

                                    fulfill({dataStats, questions});
                                }
                                catch (e) {
                                    reject(Error(e));
                                }
                            }
                        } else {
                            reject(Error('no training result for this data'));
                        }
                    }

                } else {
                    reject(Error(err.message))
                }

            });
        });

    }

    public async getTrainingResultReport(stage: string, advisorId: number, locale: number, env: string, trainingTarget: string): Promise<Report> {

        const params = {
            ExpressionAttributeValues: {
                ":v1": advisorId + "_" + locale + env + trainingTarget
            },
            KeyConditionExpression: "advisorIdLocaleEnvTrainingTarget = :v1",
            ScanIndexForward: false,
            TableName: stage + "_ai_training_results_reports",
            Limit: 1
        };
        return new Promise<Report>( (fulfill, reject) => {
            const dynamoDb = new DynamoDB.DocumentClient();
            dynamoDb.query(params, (err: AWSError, data: DocumentClient.QueryOutput) => {
                if (!err) {
                    if (data.Items === undefined) {reject(err); return;}
                    const item = data.Items[0];
                    if (item) {
                        fulfill(item as Report);
                    } else {
                        reject("no report for this data");
                    }
                } else {
                    reject(err)
                }

            });
        });
    }

    public async getPerformanceReports(stage: string, env: string, advisorId: number, locale: number, reportType: string, reports_number: number): Promise<Report[]> {

        const params = {
            ExpressionAttributeNames: {
                "#key": "advisorIdLocaleEnv-ReportType"
            },
            ExpressionAttributeValues: {':val': advisorId + "_" + locale + env + "-" + reportType},
            KeyConditionExpression: "#key = :val",

            ScanIndexForward: false,
            TableName: stage + "_ai_performance_reports",
            Limit: reports_number
        };

        return new Promise<Report[]>( (fulfill, reject) => {
            const dynamoDb = new DynamoDB.DocumentClient();

            const items: any = [];

            dynamoDb.query(params, (err: AWSError, data: DocumentClient.QueryOutput) => {
                if (!err) {
                    if (data.Items === undefined) {reject(err); return;}
                    data.Items.forEach((item) => {
                        items.push(item);
                    });
                    fulfill(items);

                } else {
                    reject(err)
                }

            });
        });

    }

}

import * as React from "react";

import { DFDashboard } from "../components/Dashboards/DFDashboard"

class DFView extends React.Component<{}> {
    public render() {
        return <DFDashboard/>
    }
}

export default DFView
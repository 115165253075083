import * as React from "react";

import * as PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {createStyles, Theme} from "@material-ui/core";

import { QualityMetrics } from "../types/types";

import Header from "./Tables/ReportHeader";

const styles = (theme: Theme) => createStyles({
    root: {
        fontSize: '0.8125rem',
        color: 'rgba(0, 0, 0, 0.87)',
        marginRight: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left'
    },
});


export interface Props {
    classes: any;
    qualityMetrics?: QualityMetrics;
    snackbar?: React.ReactElement;
}

class QualityMetricsGrid extends React.Component<Props>  {
    public static propTypes = { classes: PropTypes.object.isRequired };
    public render() {
        const { classes, qualityMetrics, snackbar } = this.props;
        return (
            <Paper className={classes.root}>
                <Header header={'Training Quality Metrics'}/>
                {qualityMetrics &&
                <Grid container spacing={0} direction='column'>
                    <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                        <Paper className={classes.paper}>confidence_statement: {qualityMetrics.confidence_statement}</Paper>
                    </Grid>
                    <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                        <Paper className={classes.paper}>precision: {qualityMetrics.precision}</Paper>
                    </Grid>
                    <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                        <Paper className={classes.paper}>recall: {qualityMetrics.recall}</Paper>
                    </Grid>
                    <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                        <Paper className={classes.paper}>f1: {qualityMetrics.f1}</Paper>
                    </Grid>
                    <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                        <Paper className={classes.paper}>auc: {qualityMetrics.auc}</Paper>
                    </Grid>
                </Grid>}
                {snackbar}
            </Paper>
        );
    }
}

export default withStyles(styles)(QualityMetricsGrid);

import { FilterSettings } from "../components/Selectors/AssistantSelector";


export const fetchAssistantStatistics = (filterSettings: FilterSettings, limit = 1000, offset = 0): Promise<Response> =>
        fetch(process.env.REACT_APP_BACKEND_URL + '/assistant/statistics', {
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        body: JSON.stringify({
            endTime: filterSettings.endDate,
            startTime: filterSettings.startDate,
            projectName: filterSettings.projectName,
            stage: filterSettings.stage,
            env: filterSettings.env,
            locale: filterSettings.locale,
            assistantIdentifier: filterSettings.assistantIdentifier,
            identifierType: 'assistant_id',
            limit: limit,
            offset: offset
        })
    });

export const fetchAssistantInsights = (filterSettings: FilterSettings, limit = 1000, offset = 0): Promise<Response> =>
    fetch(process.env.REACT_APP_BACKEND_URL + '/assistant/insights', {
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        body: JSON.stringify({
            endTime: filterSettings.endDate,
            startTime: filterSettings.startDate,
            projectName: filterSettings.projectName,
            stage: filterSettings.stage,
            env: filterSettings.env,
            locale: filterSettings.locale,
            assistantIdentifier: filterSettings.assistantIdentifier,
            identifierType: 'assistant_id',
            limit: limit,
            offset: offset
        })
    });

export const fetchAssistantStatsAcoda = (filterSettings: FilterSettings, limit = 1000, offset = 0): Promise<Response> =>
        fetch(process.env.REACT_APP_BACKEND_URL + '/health/acoda_stats', {
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        body: JSON.stringify({
            endTime: filterSettings.endDate,
            startTime: filterSettings.startDate,
            projectName: filterSettings.projectName,
            stage: filterSettings.stage,
            env: filterSettings.env,
            locale: filterSettings.locale,
            assistantIdentifier: filterSettings.assistantIdentifier,
            identifierType: 'assistant_id',
            limit: limit,
            offset: offset
        })
    });



export const fetchAssistants = async (projectName: string, startTime: number, endTime: number, stage: string) => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/health/assistants', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
            projectName: projectName,
            startTime: startTime,
            endTime: endTime,
            stage: stage,
            limit: 1000
        })
    });
    if (response && response.ok) {
        const result = await response.json();
        return result['assistants']
    }
    return []
}

export const fetchStagesByTimerange = async (projectName: string, startTime: number, endTime: number) => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/health/pipeline_stages', {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
            projectName: projectName,
            startTime: startTime,
            endTime: endTime,
            limit: 1000
        })
    });
    if (response && response.ok) {
        const result = await response.json();

        if(result['stages'].length === 0) {
            // TODO (ilir): Replace this alert with something more beautiful
            alert('No pipelines found for this time range. Please adjust the time range')
         }
        return result['stages']
    }
    return []
}

export const fetchQuestionTexts = async (env: string, advisorId: string, origins: string[]) => {
    const originChunks = [], chunkSize = 5;

    // split all questions in chunks of 5 questions and fire requests to backend
    while (origins.length > 0) {
        originChunks.push(origins.splice(0, chunkSize));
    }

    const questionTexts = [];

    for(const chunk of originChunks) {

        const jsonPromises = chunk.map(async o => {
            const response = await fetch( `${
                process.env.REACT_APP_BACKEND_URL}/zoovu/${env}/${advisorId}/question/${o}/answers`,
                {
                    method: 'GET',
                    headers: { 'Content-type': 'application/json' }
                });
            return await response.json();
        });

       for (const question of jsonPromises) {
           questionTexts.push(await question)
       }
    }

    return questionTexts
};

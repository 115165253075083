import React from "react";

import Grid from "@material-ui/core/Grid";
import {makeStyles, Theme} from "@material-ui/core/styles";
import createStyles from "@material-ui/styles/createStyles/createStyles";
import {Paper, Typography} from "@material-ui/core";

import {PathCard} from "../Cards/PathCard";
import {InfoText} from "../InfoTexts/InfoText";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            marginBottom: theme.spacing(0.5)
        },
        marginLeft: {
            marginLeft: '50px'
        },
        marginLeftNegative: { // this is just to simplify calculation of absolute margins of the edges
            marginLeft: '-50px'
        },
        title: {
            ...theme.typography.h4,
            textAlign: 'center'
        },
        paper: {
            padding: theme.spacing(1),
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
            marginBottom: theme.spacing(1),

            marginLeft: '10px',
            marginRight: '10px',
        }
    }),
);

export interface PathQuestion {
    children: PathQuestion[];
    origin: string;
    text: string;
    // holds the stat value for a question (either cor or dor)
    stat_value?: number;
    number_visits?: number;
}

interface Props {
    title: string;
    questions: PathQuestion[];
    infoText?: string;
}

export const PathsPlot: React.FC<Props> = (props) => {
    const classes = useStyles()
    return (
        <Paper className={classes.paper}>
            <Grid item xs={12} >
                <Typography className={classes.title}>
                    {props.title}
                </Typography>
            </Grid>
            <div className={classes.marginLeftNegative}>
            {
                props.questions.map(startQuestion =>
                    <Card question={startQuestion}
                        previousRightX={0} key={startQuestion.origin} previousTopY={0}/>
                        // having unique key is crucial for updates
                )
            }
            </div>
           {props.infoText &&
                <Grid item xs={12} >
                    <InfoText info={props.infoText}/>
                </Grid>
           }
        </Paper>
    );
};

class Coordinate {
    x: number
    y: number

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
    }
}

class SVGPath {

    commands: Coordinate[] = []
    viewBox: string
    height: number
    width: number
    startXAbsolute: number
    startYAbsolute: number
    strokeWidth: number

    constructor(startXAbsolute: number, startYAbsolute: number, width: number, height: number, strokeWidth: number) {
        this.viewBox = `0 0 ${width} ${height}`
        this.width = width
        this.height = height
        this.startXAbsolute = startXAbsolute
        this.startYAbsolute = startYAbsolute
        this.strokeWidth = strokeWidth
        this.commands.push({x: 0, y: 0})
        this.commands.push({x: width*25/100, y: height*6.6/100})
        this.commands.push({x: width*45/100, y: height*6.6/100})
        this.commands.push({x: width*50/100, y: height*50/100})
        this.commands.push({x: width*50/100, y: height*50/100})
        this.commands.push({x: width*55/100, y: height*93.4/100})
        this.commands.push({x: width*75/100, y: height*93.4/100})
        this.commands.push({x: width, y: height*93.4/100})
    }

    getPath(): string {
        if (this.height === 0) { // straight line
            return `M ${this.commands[0].x} ${this.commands[0].y} 
            H ${this.width}`
        } else {
            return `M ${this.commands[0].x} ${this.commands[0].y}
            C ${this.commands[1].x} ${this.commands[1].y}
            ${this.commands[2].x} ${this.commands[2].y}
            ${this.commands[3].x} ${this.commands[3].y}
            M ${this.commands[4].x} ${this.commands[4].y}
            C ${this.commands[5].x} ${this.commands[5].y}
            ${this.commands[6].x} ${this.commands[6].y}
            ${this.commands[7].x} ${this.commands[7].y}`
        }
    }

    getSVG(): any {
        let viewBoxWidth = 0
        if (this.height === 0) {
            viewBoxWidth = -this.strokeWidth/2
        }
        return <svg xmlns="http://www.w3.org/2000/svg"
                    viewBox={`0 ${viewBoxWidth} ${this.width} ${this.height? this.height: this.strokeWidth}`}
                    width={`${this.width}px`} height={`${this.height? this.height: this.strokeWidth}px`}
                    style={{position:'absolute', top:+this.startYAbsolute, left:+this.startXAbsolute}}>
            <path d={this.getPath()} stroke="#5F71DA" fill="transparent" strokeDasharray="6 3" strokeWidth={this.strokeWidth}/>
        </svg>
    }

}

const Edge: React.FC<{startXAbsolute: number, startYAbsolute: number, width: number, height: number, strokeWidth: number}> = (props) => {
    const { startXAbsolute, startYAbsolute, width, height, strokeWidth } = props
    return (
        <>
            {new SVGPath(startXAbsolute, startYAbsolute, width, height, strokeWidth).getSVG()}
        </>
    )
}

const Card: React.FC<{previousRightX: number, previousTopY: number, question: PathQuestion}> = (props) => {
    const { previousRightX, previousTopY, question } = props
    const classes = useStyles()

    const cardRef = React.useRef<HTMLSpanElement>(null)
    const [top, setTop] = React.useState(0)
    const [right, setRight] = React.useState(0)
    const [width, setWidth] = React.useState(0)

    React.useLayoutEffect(() => {
        if(null !== cardRef && cardRef.current) {
            setTop(cardRef.current!.offsetTop)
            setRight(cardRef.current!.offsetLeft + cardRef.current!.offsetWidth)
            setWidth(cardRef.current!.offsetWidth)

        }
    }, [cardRef])

    return (
            <Grid container spacing={2} direction={"row"} className={classes.card} key={`${question.origin}${question.stat_value}`}>
                <Grid item>
                    {/* First we draw the connecting line starting from parent's right (as line's X) & top (as line's Y)
                    and ending in this PathCard's left & top. Avoid drawing line before the first PathCard */}
                    {/* +/- 16 is to overcome some default grid margin, could not easily figure out where it comes
                    from */}
                    {previousRightX?
                        <Edge startXAbsolute={previousRightX} key={`${question.origin}${question.stat_value}`}
                                          startYAbsolute={previousTopY-16}
                                          width={width/2-14} // 50 (margin) / 2 - some fraction of padding
                                          height={top-previousTopY}
                                          strokeWidth={(question.stat_value && (question.number_visits || 0) > 3)?
                                          // magic to scale the lines to better outline paths with best cor
                                              Math.log(Math.pow(question.stat_value+1, 20)): 0.5} />:null}

                    <span className={classes.marginLeft} ref={cardRef}>
                        <PathCard title={question.text} origin={question.origin} cor={question.stat_value}
                          num_visits={question.number_visits} />
                    </span>
                </Grid>
                {
                question.children.length?
                    <Grid item>
                        {
                            question.children.map((q, idx) =>
                                <Card question={q}
                                    previousRightX={right} previousTopY={top} key={`${q.origin}${idx}${q.stat_value}`}/>
                            )
                        }
                    </Grid> : null
                }
            </Grid>
    );
};


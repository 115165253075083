import * as React from "react";

import { AcodaDashboard } from "../components/Dashboards/AcodaDashboard"

class AcodaView extends React.Component<{}> {
    public render() {
        return <AcodaDashboard/>
    }
}

export default AcodaView
import React, { ReactNode } from 'react';

import cn from 'classnames';

import {Button, CircularProgress} from '@material-ui/core';
import {makeStyles, createStyles} from "@material-ui/styles";

import {Theme} from "../../styles/theme/types";

const useStyles = makeStyles<Theme>((theme: Theme) => {
  const getBaseColor = () => theme.palette.primary.main;
  const borderWidth = 1;

  return createStyles({
    primary: {
      border: `${borderWidth}px solid transparent`,
      backgroundColor: getBaseColor(),
      '&:hover, &:active': {
        backgroundColor: getBaseColor(),
      },
      '&:disabled': {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.greys.disabled,
      },
    },
    secondary: {
      borderWidth,
      backgroundColor: theme.palette.white,
      borderColor: theme.palette.greys.bcg.element,
      color: getBaseColor(),
      '&:hover, &:active': {
        backgroundColor: theme.palette.white,
        borderColor: theme.palette.greys.bcg.element,
      },
      '&:disabled': {
        color: theme.palette.greys.disabled,
        boxShadow: 'none',
        borderColor: theme.palette.greys.disabled,
      },
    },
    context: {
      textTransform: 'uppercase',
      color: theme.palette.greys.default,
      border: `${borderWidth}px solid transparent`,
      transition: theme.transitions.create(['color', 'background-color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      '&:hover, &:active': {
        backgroundColor: theme.palette.greys.bcg.element,
        color: getBaseColor(),
      },
      '&:disabled': {
        color: theme.palette.greys.disabled,
      },
    },
    small: {
        padding:  theme.spacing(0.3, 1),
        minWidth: 64,
    },
    root: {
      padding: theme.spacing(0.7, 2),
      minWidth: 64,
    },
    spinner: {
      position: 'absolute',
    },
  });
});

export interface ButtonBaseProps {
  children: string | ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  size?: 'medium' | 'small';
  variant?: 'primary' | 'secondary' | 'context';
  pending?: boolean;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  ref?: React.RefObject<HTMLButtonElement>;
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const variantMap = {
  primary: 'contained' as 'contained',
  secondary: 'outlined' as 'outlined',
  context: 'text' as 'text',
};

const CptButton: React.FC<ButtonBaseProps> = ({
  children,
  type = 'button',
  size = 'medium',
  variant = 'primary',
  pending = false,
  disabled = false,
  fullWidth = false,
  iconLeft,
  iconRight,
  ref,
  className,
  onClick,
  ...other
}) => {
  const classes = useStyles();

  return (
    <Button disableElevation
      color="primary"
      variant={variantMap[variant]}
      fullWidth={fullWidth}
      disabled={pending || disabled}
      type={type}
      ref={ref}
      size={size}
      onClick={onClick}
      className={className}
      classes={{
        root: cn(classes.root, size === 'small' && classes.small),
        [variantMap[variant]]: classes[variant],
      }}
      startIcon={iconLeft}
      endIcon={iconRight}
      {...other}
    >
      {children}
      {pending && <CircularProgress className={classes.spinner} size={20} data-testid="spinner" />}
    </Button>
  );
};

export default CptButton;

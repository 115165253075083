import * as React from "react";

import classNames from 'classnames';

import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles, Theme } from '@material-ui/core/styles';

const styles1 = (theme: Theme) => ({
    root: {
        paddingTop: 0,
        paddingBottom: 0,
        padding: theme.spacing(1),
        margin: 0
    },
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    },
});


export interface Props {
    classes: any;
    className: string,
    message: string,
    variant: string,
}

class InfoMessage extends React.Component<Props> {
    public render() {
        const { classes, className, message, variant, ...other } = this.props;
        // const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={classNames(classes[variant], classes.root, className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <img style={{width: '30px', height: '30px', margin: '2px 10px 2px 2px'}}src="images/flag2.png" alt=""/>
                        {message}
                    </span>
                }
                {...other}
            />
        );
    }

}

const InfoMessageWrapper = withStyles(styles1)(InfoMessage);

const styles2 = (theme: Theme) => ({
    margin: {
        margin: theme.spacing(0),
    },
});

class CustomizedSnackbars extends React.Component<Props> {
    public render() {
        const {classes, message, variant} = this.props;
        return (
            <InfoMessageWrapper
                variant={variant}
                className={classes.margin}
                message={message}
            />
        );
    }
}


export default withStyles(styles2)(CustomizedSnackbars);

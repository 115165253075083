/* global JSX */
import React from 'react';

export const PATH_TO_QUESTION_ORIGINS_REGEX = /\s*(?:,|$)\s*/


export const convertToDatestring = (d: number, withSeconds = true) => {
    if (d.toString().length === 10) {
        d = d*1000
    }
    const date = new Date(d);
    let formatted_date = date.getFullYear() + "/" +
        (date.getMonth() + 1) + "/" +
        date.getDate() + " " +
        date.getHours() + ":" +
        date.getMinutes();
    if (withSeconds) {
        formatted_date += ":" + date.getSeconds();
    }

    return formatted_date
};

export const roundUp = (num: number, decimal = 3) => {
    if (decimal === 3) {
        return Math.round(num * 1000) / 1000;
    } else {
        return Math.round(num * 100) / 100;
    }
};

export const calculateAndFormatDuration = (startTime: number, endTime: number) => {
    let duration = (endTime - startTime);
    if (startTime.toString().length === 13) {
        duration = duration / 1000;
    }
    if (duration >= 60) {
        return ' ' + roundUp(duration, 2) + ' min';
    }
    return ' ' + roundUp(duration, 2) + ' sec'
};

export const dateFromMilliseconds = (ms: number) => {
    const date: Date = new Date(ms)
    const dateStr = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours()}-${date.getMinutes()}`
    return dateStr
};

export function getDateAsString(ms: number) {
    // given a timestamp, return it in format "Month Date", e.g. "Oct 02"
    const [, month, day] = new Date(ms).toString().split(' ');
    return `${month} ${day}`
}


export function someDaysAgo() {
    return new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * ((process.env.REACT_APP_ASSISTANT_SELECTOR_DAYS_AGO || 7) as number)).getTime();
}

export const getTspanGroups = (value: string, maxLineLength: number, maxLines = 2) => {
    const words = value.split(' ');

    interface linesAcc {
        lines: string[];
        currLine: string;
    }

    // reduces the words into lines of maxLineLength
    const assembleLines: linesAcc = words.reduce( (acc: linesAcc, word: string) => {
        // if the current line isn't empty and the word + current line is larger than the allowed line size, create new line and update current line
        if ( (word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
            return {
                lines: acc.lines.concat([acc.currLine]),
                currLine: word
            }
        }
        // otherwise add the word to the current line
        return {
            ...acc,
            currLine: acc.currLine + ' ' + word
        }

    }, {lines: [], currLine: ''})

    // add the ending state of current line (the last line) to lines
    const allLines = assembleLines.lines.concat([assembleLines.currLine])

    // for now, only take first 2 lines due to tick spacing and possible overflow
    const lines = allLines.slice(0, maxLines)
    const children: JSX.Element[] = []
    let dy = -3

    lines.forEach( (lineText, i) => {
        children.push(
            <tspan x={20} dy={dy} key={i}>
                 {
                    // if on the second line, and that line's length is within 3 of the max length, add ellipsis
                    (1 === i && allLines.length > 2) ? lineText.slice(0, maxLineLength - 3) + '...' : lineText
                }
            </tspan>)
        // increment dy to render next line text below
        dy += 15
    });

    return children
}


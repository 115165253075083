import React from 'react';

import {ResponsiveBar} from '@nivo/bar'
import { LegendProps } from '@nivo/legends'
import {Grid, Paper, Typography} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/styles/createStyles/createStyles";

import { Theme } from "../../styles/theme/types";
import { InfoText } from "../InfoTexts/InfoText";

import { barTooltip } from './LinePlotUtils/CustomTooltip';
import {getAxisBottomRotation} from "./SharedPlotUtils/PlotUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
        },
        title: {
            ...theme.typography.h4,
            textAlign: 'center'
        }
    }),
);

interface IProps {
    data: object[];
    keys: string[];
    indexBy: string;
    dataTitle: string;
    showLegend?: boolean;
    formatFunc?(val: string | number): string;
    xTitle?: string;
    yTitle: string;
    infoText?: string;
}



const BarPlot: React.FC<IProps> = (props: any) => {
    const classes = useStyles();
    // fixed height set for each item in the legend
    const legendItemHeight = 20

    function getLegend(): ({ dataFrom: 'indexes' | 'keys' } & LegendProps)[] {
        if (!props.showLegend) {
            return []
        } else {
            return [{
                dataFrom: 'indexes',
                anchor: 'bottom-left',
                direction: 'column',
                justify: false,
                translateX: 0,
                // in order for the legend text to not overlap with x-axis labels, position legend a bit lower (add + 2 spaces)
                translateY: ((props.data.length +  2) * legendItemHeight),
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: legendItemHeight,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 3,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }]
        }
    }

    function getMargins() {
        if(props.showLegend) {
            return { top: 30, right: 50, bottom:  ((props.data.length +  2) * legendItemHeight), left: 50 }
        } else {
            return { top: 25, right: 2, bottom: 63, left: 50 }
        }
    }

    function getHeight() {
        if(props.showLegend) {
            // plot height is bigger when using legends - need some space for them
            return 300 + ((props.data.length +  2) * legendItemHeight)
        } else {
            return 300
        }
    }

    return (
        <Paper className={`${classes.paper}`}>
            <Grid container spacing={0}>
                <Grid item xs={12} >
                    <Typography className={classes.title}>
                        {props.dataTitle}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div style={{ height: getHeight() }}>
                        <ResponsiveBar
                            data={props.data ? props.data : []}
                            keys={props.keys}
                            indexBy={props.indexBy}
                            margin={getMargins()}
                            padding={0.7}
                            groupMode='grouped'
                            colors={{ scheme: 'accent' }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: getAxisBottomRotation(props.data.length),
                                legend: props.xTitle,
                                legendPosition: 'middle',
                                legendOffset: 30,
                                format: v => {
                                    if(props.formatFunc !== undefined) {
                                        return props.formatFunc(v);
                                    } else {
                                        return v
                                    }
                                }
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 0,
                                tickRotation: 0,
                                legend: props.yTitle,
                                legendPosition: 'middle',
                                legendOffset: -40
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            tooltip={barTooltip}
                            legends={getLegend()}
                        />
                    </div>
                </Grid>
            </Grid>
            {props.infoText &&
                <Grid item xs={12} >
                    <InfoText info={props.infoText} />
                </Grid>
            }
        </Paper>
    )
};

export default BarPlot;
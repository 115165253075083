import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const fontWeights = {
    fontWeightRegular: 300, // regular
    fontWeightMedium: 400, // semi-bold
    fontWeightBold: 500, // bold
    fontWeightSuperBold: 600, // very bold
};

const fontSizes = {
    superSmall: '0.6rem',
    small: '0.8rem',
    regular: '1rem',
    medium: '1.2rem',
    large: '2.3rem',
};

const typography: TypographyOptions = {
    ...fontWeights,
    fontFamily: ['Helvetica', 'Arial', 'sans-serif'].join(','),
    button: {
        textTransform: 'uppercase',
        fontWeight: 700,
    },
    fontSizes,
    h1: {
        fontSize: fontSizes.large,
        fontWeight: fontWeights.fontWeightBold,
    },
    h2: {
        fontSize: fontSizes.medium,
        fontWeight: fontWeights.fontWeightBold,
    },
    h3: {
        fontSize: fontSizes.medium,
    },
    h4: {
        fontSize: fontSizes.regular,
        fontWeight: fontWeights.fontWeightSuperBold,
    },
    h5: {
        fontSize: fontSizes.small,
        fontWeight: fontWeights.fontWeightSuperBold,
    },
    body1: {
        fontSize: '0.875rem',
    },
};

export { fontWeights };
export default typography;

export const TOTAL_NUMBER_OF_VISITS_INFO_TEXT = 'Number of visits used for training in the selected time period e.g. only those confronted with a dynamic question. ' +
    'Please consider that visits containing a start-over event are counted as many times as this event happened.'
    // train_visits

export const NUMBER_OF_VISITS_INFO_TEXT = 'Number of visits used for training in the selected period split by days e.g. only those confronted with a dynamic question. ' +
    'Please consider that visits containing a start-over event are counted as many times as this event happened.'
    // train_visits

export const AGGREGATED_CO_RATE_INFO_TEXT = 'Clickout rate from the trainings in the selected time period. Calculated based on training visits and their clickouts. ' +
    'Only unique clickouts are taken into account. Please consider that visits containing a start-over event are ' +
    'counted as many times as this event happened.' // train_clickouts / train_visits

export const TOTAL_CO_RATE_INFO_TEXT = 'Clickout rates performed on daily basis. Calculated based on training visits and their clickouts. ' +
    'Only unique clickouts are taken into account. Please consider that visits containing a start-over event are ' +
    'counted as many times as this event happened.' // train_clickouts / train_visits

export const AGGREGATED_RANDOM_SHARE_INFO_TEXT = 'The rate of random actions for the trainings in the selected time ' +
    'period.' // random_rate

export const RATE_OF_RANDOM_RECOMMENDATIONS_INFO_TEXT = 'The rate of random recommendations.' // random_rate

export const AVERAGE_TRAINING_DURATION_INFO_TEXT = 'Average duration of trainings during the selected time period.'

export const PATHS_POPULARITY_AND_COR_INFO_TEXT = 'Paths encountered by users, the number of visits where each path was encountered and the corresponding clickout rate for each path. ' +
    'Calculated based on all visits used for training.' // question_paths_stats

export const ALL_AND_MOST_RECENT_PATHS_INFO_TEXT = 'List of most popular paths from the tracking set, ordered by occurrences. ' +
    'Most recent popular paths (paths from last training) are highlighted.' // popular_paths

export const CO_RATE_AI_VS_RANDOM_INFO_TEXT = 'Clickout rate for visits that are pure random and pure AI. ' +
    'A visit is pure random if all the actions performed were chosen randomly and pure AI if all actions were ' +
    'predicted.' + // purely_random_visits_co_rate,purely_ai_visits_co_rate
    'We also plot the standard deviation markers, assuming we have a binomial distribution. Also, we aggregate the clickout rates and plot ' +
    'the statistical significance (again, assuming a binomial distribution).'

export const TRAINING_TIMES_INFO_TEXT = 'How long it took in seconds to train the model.'

export const PATHS_WITH_HIGHEST_CO_INFO_TEXT = 'The paths with the highest clickout rates. y axis shows the clickout rate. ' +
    'Hover over the line to see the path order. Calculated based on visits used for training. There might be more paths, but the number of paths shown ' +
    'here is limited to TOP_PATHS_TO_SHOW'

export const PATHS_WITH_HIGHEST_DO_INFO_TEXT = 'The paths with the highest dropout rates. y axis shows the dropout rate. ' +
    'Hover over the line to see the path order. Dropout is the action when user just quits the assistant flow. Calculated based on visits used for training. ' +
    'There might be more paths, but the number of paths shown here is limited to TOP_PATHS_TO_SHOW.'

export const RETRAINS_INFO_TEXT = 'All other plots ignore training stats for retrain. This plot shows on which days retraining happened for this assistant'

export const OBSERVED_STATES_INFO_TEXT = 'Number of observed answer vectors seen by the model. ' +
    'An answer vector is a collection of question and answer paths seen by the users while interacting with the assistant. ' +
    'The observed states threshold is the minimum number of observed answer vectors that the model should see, in order to start ' +
    'delivering predictions. Other than this threshold, the model also starts delivering predictions if the sum of the number of visits in which ' +
    'these answer vectors are seen exceeds a certain threshold (for this assistant its value is MINIMUM_NUMBER_OF_VISITS)' // number_of_observed_states, answer_vectors_threshold,minimum_answer_vector_visits_to_converge

export const NEW_OBSERVED_STATES_INFO_TEXT = 'Number of new observed answer vectors seen by the model in each training day. ' +
    'An answer vector is a collection of question and answer paths seen by the users while interacting with the assistant.'
    // number_of_observed_states

export const NUMBER_OF_CONFIDENT_STATES_INFO_TEXT = 'Number of confident observed states seen so far. ' +
    'A state is considered confident if its uncertainty is less than 0.05 (means we use 95% as confidence)'
    // number_of_observed_states

export const RATIO_OF_OBSERVED_STATES_INFO_TEXT = 'From the overall number of possible answer vectors, how many the model has seen so far in the visits used for training. ' +
    'The more visits we have, more states are observed and this number should increase.'
    // number_of_observed_states (OBSERVED_STATES_INFO_TEXT) / (answer_vectors_threshold * 1/answer_vectors_threshold_rate)

export const BEST_NEXT_QUESTION_INFO_TEXT = 'Given an answer vector (collection of question and answers seen during visits), which is the next best question for that state. ' +
    'The legend shows the answer vector, hover over lines to see the best next question. y axis shows the reward for that best next question. There might be more paths, but the number of' +
    ' paths shown here is limited to TOP_PATHS_TO_SHOW.'

export const NUMBER_OF_DYNAMIC_QUESTIONS_SEEN_INFO_TEXT = "If an assistant has skipping questions enabled, this means that sometimes the algorithm decides to skip the dynamic box after" +
    " some questions are asked. This chart considers only visits where users saw the question after the dynamic box (this way we are sure that the algorithm skipped and it's not a dropout). " +
    "Here we count the number of visits foreach number of dynamic questions our users are seeing per day. The number of dynamic questions seen can be from 1 to number_of_dynamic_questions in the box."

export const NUMBER_OF_TIMES_QUESTION_SKIPPED = "If an assistant has skipping questions enabled, this means that sometimes the algorithm decides to skip some of the dynamic questions. This chart " +
    "considers only visits where users saw the question after the dynamic box (this way we are sure that the algorithm skipped and it's not a dropout). " +
    "For each of the dynamic questions, plot the number of visits where this question was skipped and also plot the number of visits that resulted with clickout when" +
    " this question was skipped."

export const INPUT_AND_OUTPUT_NEURONS_INFO_TEXT = 'The number of input and output neurons of the network. The number of input neurons equals the number of features we use for training. Currently each answer' +
    ' answer option has its own input and, furthermore, external features are represented by some neurons in the input (which equals the number of known browser and platform combinations). ' +
    'The number of output neurons is the number of questions in the dynamic  box (basically the possible outcomes of next question predictions). Seeing changes in the number of input/output neurons means that a significant change has happened to the assistant and we have retrained for it.'

export const BURN_IN_BUFFER_INFO_TEXT = 'The number of experiences seen by the model before starting predicting. ' +
    'The current minimum number is 500.'

export const NUMBER_OF_EXPERIENCES_INFO_TEXT = 'The number of experiences stored by the model. The experiences are gradually added to the buffer after the training process. ' +
    'During training, we are sampling a small batch of the existing tuples from the experience buffer and we fit them' +
    ' into the model to learn. This is known as experience replay.'

export const DQN_LOSS_INFO_TEXT = 'Average loss value returned by the DQN model for each training.' // mean(loss_values_of_training)

export const UNPARSED_VISITS_INFO_TEXT = 'The visits that visit parser was unable to process due to malfunctions in ' +
    'the tracking data, unknown tracking events, malformed data.' // engaged_visits-unique_train_visits

export const ROLLING_CLICKOUT_RATE_INFO_TEXT = 'A rolling clickout rate is a clickout rate aggregation across a ' +
    'sliding partition of data, in this example over a 6-day sliding window.' +
    'The main purpose of rolling average is to look at the past 6 days and reveal a trend for that period.'

export const EXPANDING_CLICKOUT_RATE_INFO_TEXT = 'An expanding clickout rate is a clickout rate aggregation across ' +
    'an expanding partition of data, in this example expanding by each day. Expanding average takes into account all ' +
    'of the previous time points and it depicts a more overall look into our desired time period.'
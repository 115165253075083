import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SvgIcon from "@material-ui/core/SvgIcon/SvgIcon";

import {Theme} from "../../styles/theme/types";
import {InfoText} from "../InfoTexts/InfoText";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flex: {
            display: 'flex'
        },
        superBold: {
            weightForFont: theme.typography.fontWeightSuperBold
        },
        marginRight: {
            marginRight: theme.spacing(1)
        },
        marginTop: {
            marginTop: theme.spacing(0.5)
        },
        paper: {
            padding: theme.spacing(1),
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
        },
        cartIcon: {
            color: theme.palette.cartIcon
        }
    }),
);

interface Props {
    title: string,
    value: string,
    iconPath?: string,
    infoText?: string,
    percentageChange: number,
    percentageDescription: string
}

export const KPICard: React.FC<Props> = ({ title, value, iconPath, infoText, percentageChange, percentageDescription }) => {
    const classes = useStyles();

    function percentageShift(percentageChange: number): string {
        if (percentageChange > 0) {
            return '+'
        } else {
            return ''
        }
    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={2} >
                <Grid container item xs direction="column" spacing={2}>
                    <Grid item xs>
                        <Typography variant="button" color="textSecondary">
                            {`${title}`}
                        </Typography>
                        <Typography variant="h1">
                            {`${value}`}
                        </Typography>
                        {percentageChange !== 0 &&
                            <div className={classes.flex}>
                                <Typography variant="body2" className={`${classes.superBold} ${classes.marginRight}`}>
                                    {`${percentageShift(percentageChange)}${percentageChange.toFixed(2)}%`}
                                </Typography>
                                <Typography variant="caption">
                                    {`${percentageDescription}`}
                                </Typography>
                            </div>
                        }
                    </Grid>
                </Grid>
                {iconPath &&
                    <SvgIcon className={`${classes.cartIcon} ${classes.marginTop} ${classes.marginRight}`}>
                        <path d={iconPath}/>
                    </SvgIcon>
                }
            </Grid>
              {infoText &&
                <Grid item xs={12} >
                    <InfoText info={infoText}/>
                </Grid>
              }
        </Paper>
    );

};
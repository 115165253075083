import * as React from 'react';

import {Theme, withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Grid, {GridSpacing} from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {createStyles, WithStyles} from "@material-ui/core";

import {Report} from "../../types/types";

import ReportHeader from "./ReportHeader";
import AITooltip from "./AITooltip";
import TablePaginationActions from './TablePaginationActions'

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto'
    },
    table: {

    },
    tableCell: {
        padding: 10,
        fontSize: '0.8125rem'
    },
    tableCellNoPadding: {
        fontSize: '0.8125rem',
        padding: 0,
        paddingRight: 0,
        "&:last-child": {
            paddingRight: 0
        }
    },
    gridItem: {
        padding: '4px 16px 6px 4px',
        borderBottom: '1px solid #ddd',
    },
    paper: {
        // padding: theme.spacing(2),
        // textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    noWrap: {
        whiteSpace: 'nowrap'
    },
    tableHead: {
        ...theme.typography.subtitle1,
        borderBottom: '1px solid #eeeeee',
        backgroundColor: '#fafafa',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        whiteSpace: 'nowrap'
    },
});

interface Props extends WithStyles<typeof styles>{
    title: string;
    reports: Report[];
    classes: any;
}

function ReportTable({ title, classes, reports }: Props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(3);

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number,
    ) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) {
        setRowsPerPage(parseInt(event.target.value, 10));
    }

    return (
            <Paper className={classes.root}>
                <ReportHeader header={title}/>
                <Table>
                    <TableHead>
                        <TableRow key='ar0' className={classes.tableHead}>
                            <TableCell align="center" className={`${classes.tableCell} ${classes.noWrap}`}>Job Start Time</TableCell>
                            <TableCell align="right">Statements</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {reports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((report, idx) => (
                        <TableRow key={idx}>
                            <TableCell className={`${classes.tableCell} ${classes.noWrap}`}>
                                <AITooltip title={'LGL: ' + getFormattedDate(report.signifChangeTimestamp)}>
                                    <span>
                                    {getFormattedDate(report.jobStartTime)}
                                    </span>
                                </AITooltip>
                            </TableCell>
                            <TableCell align="right" className={classes.tableCellNoPadding}>
                                {report.statements.map((statement, idx) => (
                                    <Grid container spacing={Number(0) as GridSpacing} direction='row' key={idx}>
                                        <Grid item xs={3} sm={3} lg={3} className={classes.gridItem}>
                                            <AITooltip title={statement.answerOrigin ? 'questionOrigin/answerOrigin: ' + statement.questionOrigin + '/' + statement.answerOrigin : ''}>
                                                <div>{statement.statementGroup}</div>
                                            </AITooltip>
                                        </Grid>
                                        <Grid item xs={9} sm={9} lg={9} className={classes.gridItem}>
                                            <AITooltip title={statement.answerOrigin ? 'questionOrigin/answerOrigin: ' + statement.questionOrigin + '/' + statement.answerOrigin : ''}>
                                                <div>
                                                {statement.texts.filter(element =>
                                                    element.type === 'paragraph'
                                                )[0].text}
                                                </div>
                                            </AITooltip>
                                        </Grid>
                                    </Grid>
                                ))}

                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow key='ar-footer'>
                            <TablePagination
                                labelRowsPerPage={'Reports per page'}
                                rowsPerPageOptions={[3, 6, 10]}
                                colSpan={3}
                                count={reports.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'Reports per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
    );
}

const getFormattedDate = (unix: number) => {
    const date = new Date(unix);
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " +  date.getHours() + ":" + date.getMinutes();
};

export default withStyles(styles)(ReportTable);
import { getDateAsString } from "../../../utils/utils";
import {HIGHEST_CO_PATH_NAME, HIGHEST_DO_PATH_NAME, NUMBER_OF_CLICKOUTS_WHEN_SKIPPED, NUMBER_OF_VISITS_WHEN_SKIPPED,
    QUESTION_TEXT } from "../../constants";

import { mean } from "./StatisticsUtils"
import { last, sum, deduplicateDates } from './ListUtils';
import { getBestNextQuestions, isValidSharedPath, getPathDataFormatted, getDynamicQuestionsSeen,
    getQuestionFormatted } from "./PathStatistics";

enum LearningStrategies{
    DQN_LEARNING = 'dqn_learning',
    REINFORCEMENT_LEARNING = 'reinforcement_learning'
}

export interface QuestionText {
    [origin:string]: { question_text: string,
                       question_origin: string,
                       answers: [] }
}

export const dummyAssistantStatistics = [{
    random_rate: 0.0,
    purely_ai_visits_co_rate: 0.0,
    purely_ai_visits_count: 0,
    purely_random_visits_co_rate: 0.0,
    purely_random_visits_count: 0,
    number_of_observed_states: 0,
    number_of_confident_states: 0,
    train_answer_vectors: 0,
    train_clickouts: 0,
    train_visits: 0,
    unique_train_visits: 0,
    unparsed_visits: 0,
    engaged_visits: 0,
    end_time: 0,
    start_time: 0,
    answer_vectors_threshold: 0,
    popular_paths: [],
    highest_co_paths: {
        dynamic_question_path: [],
        values: [],
    },
    highest_do_paths: {
        dynamic_question_path: [],
        values: [],
    },
    best_next_qs: {
        answer_vector: [],
        next_question: [],
        rewards: [],
    },
    question_paths_stats: [],
    question_paths_dropout_stats: [],
    ratio_of_observed_states: 0,
    minimum_answer_vector_visits_to_converge: 0,
    run_type: '',
    skipped_questions_count: {},
    dynamic_questions_seen_count: {},
    strategy_type: LearningStrategies.REINFORCEMENT_LEARNING,
    number_of_input_neurons: 0,
    number_of_output_neurons: 0,
    buffer_burn_in: 0,
    number_of_experiences: 0,
    loss: [],
    box_origin: undefined
}];

export function visits(statistics: any[]) {
    return statistics.map((t) => t.train_visits)
}

export function clickouts(statistics: any[]) {
    if (statistics.length) {
        return statistics.map((v) => {
            if (v.train_visits === 0) {
                return 0
            } else {
                return (v.train_clickouts / v.train_visits)
            }
        })
    } else {
        return [0]
    }
}

export function aggregatedClickoutRate(statistics: any[]) {
    const numberOfVisits = sum(visits(statistics))
    const numberOfClickOuts = sum(statistics.map((v) => v.train_clickouts))

    if (numberOfVisits === 0) {
        return 0
    } else {
        return ((numberOfClickOuts / numberOfVisits) * 100)
    }
}

export function randomRates(statistics: any[]) {
    if (statistics.length) {
        return statistics.map((v) => v.random_rate)
    } else {
        return [0]
    }
}

export function durations(statistics: any[]) {
    return statistics.map((v) => (v.end_time - v.start_time) / 1000)
}

export function getBestNextQuestionData(statistics: any[], questionTexts: QuestionText[]) {
    const bestNextQuestions = getBestNextQuestions(statistics, questionTexts);
    // convert the object data to the format accepted by MultiTimeLine plot. Select top 10
    return Object.values(bestNextQuestions).slice(0, 8).map((path: any, idx: number, array) => (
        {
            id: (idx + 1) + '.' + path.path_as_string,
            xData: path.training_dates,
            yData: path.y_data,
            hoverText: path.next_questions,
        }
    ));
}

export function getPathDataAsMultiline(pathName: string, statistics: any, questionTexts: QuestionText[]) {

    if (!isValidSharedPath(pathName)) { return []; }

    let pathObject = statistics;
    const stats = statistics;
    if (pathName === HIGHEST_CO_PATH_NAME) {
        pathObject = getPathDataFormatted(stats, HIGHEST_CO_PATH_NAME, questionTexts);
    } else {
        pathObject = getPathDataFormatted(stats, HIGHEST_DO_PATH_NAME, questionTexts);
    }
    // convert the object data to the format accepted by MultiTimeLine plot
    return Object.values(pathObject).map((path: any) => (
        {
            id: path.path_as_string,
            xData: path.training_dates,
            yData: path.y_data,
        }
    ));
}

export function startTimes(statistics: any[]) {
    return statistics.map((v) => v.start_time);
}

export function numberOfInputAndOutputNeurons(statistics: any[]) {
    return [
        {
            id: 'Number of Input Neurons',
            xData: startTimes(statistics),
            yData: statistics.map(v => v.number_of_input_neurons)
        },
        {
            id: 'Number of Output Neurons',
            xData: startTimes(statistics),
            yData: statistics.map(v => v.number_of_output_neurons)
        }
    ]
}

export function numberOfExperiences(statistics: any[]) {
    return statistics.map(v => v.number_of_experiences || 0);
}

export function burnInBuffer(statistics: any[]) {
    const mostRecentStats = statistics[statistics.length - 1];
    return mostRecentStats.buffer_burn_in || 0;
}

export function unParsedVisits(statistics: any[]) {
    return statistics.map((t) =>  t.unparsed_visits || 0)
}

export function averageDqnLossPerTraining(statistics: any[]) {
    return statistics.map(v => mean(v.loss));
}

export function getAiVsRandomCORate(statistics: any[]) {
    return [
        {
            id: 'Purely Random Visits CO Rate',
            xData: startTimes(statistics),
            yData: statistics.map((v) => v.purely_random_visits_co_rate || 0.0),
            occurrences: statistics.map((v) => v.purely_random_visits_count || 0.0),
            color: '#3daff7',
        },
        {
            id: 'Purely AI Visits CO Rate',
            xData: startTimes(statistics),
            yData: statistics.map((v) => v.purely_ai_visits_co_rate || 0.0),
            occurrences: statistics.map((v) => v.purely_ai_visits_count || 0.0),
            color: '#774dd7',
        },
    ]
}

export function minimumVisitsThreshold(statistics: any[]) {
    if(statistics.length === 0) {
        return 0
    } else {
        return last(statistics.map(v => (v.minimum_answer_vector_visits_to_converge || 0)))
    }
}

export function observedStates(statistics: any[]) {
    return [
        {
            id: 'Number of Observed States ',
            xData: startTimes(statistics),
            yData: statistics.map((v) => v.number_of_observed_states),
        },
        {
            id: 'Observed States Threshold ',
            xData: startTimes(statistics),
            yData: statistics.map(v => v.answer_vectors_threshold),
        }
    ]
}

export function aggregatedRandomRate(statistics: any[]) {
    const numberOfTrainings = statistics.length
    const randomRatesSum = sum(randomRates(statistics))

    if (numberOfTrainings === 0) {
        return 0
    } else {
        return ((randomRatesSum / numberOfTrainings) * 100).toFixed(2)
    }
}

export function skippingEnabled(statistics: any[]) {
    return statistics.some(e =>
    Object.keys(e.skipped_questions_count || {}).length !== 0 ||
    Object.keys(e.dynamic_questions_seen_count || {}).length !== 0);
}

export function isAssistantDQN(statistics: any[]) {
    return statistics.some(e => e.strategy_type === LearningStrategies.DQN_LEARNING);
}

export function dynamicQuestionsSeen(statistics: any[]) {
    const dynamicQuestionsCount = getDynamicQuestionsSeen(statistics);
    // convert the object data to the format accepted by MultiTimeLine plot
    return Object.values(dynamicQuestionsCount).map((value: any) => (
        {
            // use hover text as id, which should be shown in the plots' legend
            id: value.hover_text[0],
            xData: value.training_dates,
            yData: value.y_data,
            hoverText: value.hover_text,
        }
    ));
}

export function numberOfConfidentStates(statistics: any[]) {
    const confidentStates = statistics.map(v => v.number_of_confident_states || 0)
    const dates: string[] = startTimes(statistics).map((key: number, index) => getDateAsString(key) )
    return deduplicateDates(dates).map((key: string, index) => ({
            'date': key,
            'Confident States': confidentStates[index]
        })
    )
}

export function ratioOfObservedStates(statistics: any[]) {
    return statistics.map(v => v.ratio_of_observed_states || 0)
}

function groupRetrainsByDate(retrains: { date: string, retrain: number }[]) {
    return retrains.reduce(function (accumulator: { date: string, retrain: number }[], current) {
        let found = false
        for (const acc of accumulator) {
            if (acc.date === current.date) { acc.retrain += current.retrain; found = true }
        }
        if (!found) { accumulator.push(current) }
        return accumulator;
    }, [] as { date: string, retrain: number }[]);
}

export function retrainsPerDay(statistics: any[]) {
    const retrains = statistics.map((training) => ({
            'date': getDateAsString(training.start_time),
            'retrain': training.run_type === 'retrain' ? 1 : 0
        })
    )
    return groupRetrainsByDate(retrains)
}

export function newStatesPerDay(statistics: any[]) {
    const allObservedStates = statistics.map((v) => v.number_of_observed_states);
    const trainingStartTimes = startTimes(statistics);

    // by using the number of observed states per each day, calculate the number of new states seen each day
    // skip day 1 of the selected period, since we don't know the number of observed states for the day before it
    const skipDays = 1,
        dates: string[] = [],
        newStates: number[] = [];
    allObservedStates.slice(skipDays).map((key, index) => {
        // keep the list of dates and number of new states foreach date
        dates.push(getDateAsString(trainingStartTimes[index + skipDays]))
        newStates.push(allObservedStates[index + skipDays] - allObservedStates[index])
    });

    return deduplicateDates(dates).map((key: string, index) => ({
            'date': key,
            'New States': newStates[index]
        })
    )
}

export function numberOfTimesQuestionSkipped(statistics: any[], questionTexts: QuestionText[]): Object[] {
        const skippedCount = statistics.map(v => (v.skipped_questions_count || {})).filter((v) => Object.keys(v).length
        !== 0);
        const result = skippedCount.reduce((accumulatedValue: {[key: number]: {[key: string]: number}},
                                            currentTraining: {[key: number]: {[key: string]: number}}) => {

        function emptyQuestionResults() {
            const emptyDict: {[key: string]: string | number} = {};
            emptyDict[NUMBER_OF_VISITS_WHEN_SKIPPED] = 0;
            emptyDict[NUMBER_OF_CLICKOUTS_WHEN_SKIPPED] = 0;
            emptyDict[QUESTION_TEXT] = '';
            return emptyDict;
        }

        for(const question in currentTraining) {
            accumulatedValue[question] = accumulatedValue[question] || emptyQuestionResults();
            accumulatedValue[question][NUMBER_OF_VISITS_WHEN_SKIPPED] += currentTraining[question]['skip_count'];

            // reverse engineer the visits with clickouts from the times it was skipped and clickout rate
            accumulatedValue[question][NUMBER_OF_CLICKOUTS_WHEN_SKIPPED] +=
                currentTraining[question]['clickout_rate'] * currentTraining[question]['skip_count'];
            accumulatedValue[question][QUESTION_TEXT] = getQuestionFormatted(question, questionTexts);
      }
      return accumulatedValue;
    }, []);

        return Object.values(result)
    }
import React from 'react';

import classNames from "classnames";

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {Theme} from "../../styles/theme/types";
import AITooltip from "../Tables/AITooltip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            boxShadow: theme.palette.greys.default,
            display: "inline-block",
            paddingBottom: 0
        },
        alignRight: {
            textAlign: "right"
        },
        itemMargin: {
            margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`
        },
        container: {
            display: "inline-block",
            width: "160px"
        },
        superBold: {
            weightForFont: theme.typography.fontWeightSuperBold
        },
        inlineRight: {
            display: 'inline',
            float: 'right'
        },
        line: {
            minHeight: '1.5em'
        },
        hide: {
            visibility: 'hidden'
        },
        shortText: {
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }),
);

interface Props {
    title: string,
    origin: string,
    cor?: number,
    num_visits?: number
}

export const PathCard: React.FC<Props> = ({title, origin,
                                         cor,
                                         num_visits}) => {

    const classes = useStyles();

    return (
        <Paper className={classNames(classes.paper, {[classes.hide]: origin === '-1'})}>
            <Grid container spacing={2} className={classes.container}>

                <Grid container spacing={0} direction={"row"} wrap={"nowrap"}>
                    <Grid item xs={12} className={classes.itemMargin}>
                        <Typography variant="h5" className={classes.line}>
                            {(num_visits !== undefined) ? num_visits: ''}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            <div className={classes.line}>
                                {(num_visits !== undefined) ? 'visits': ''}
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={`${classes.itemMargin} ${classes.alignRight}`}>
                        <Typography variant="h5" color="primary" className={classes.line}>
                            {cor !== undefined ? cor: ''}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.shortText}>
                    <AITooltip title={title} placement="bottom">
                        <Typography variant="caption" className={classes.line}>
                        {title}
                        </Typography>
                    </AITooltip>
                </Grid>

            </Grid>
        </Paper>
    )

}
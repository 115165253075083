import React from 'react';

import '../App.css';

import {createStyles, Grid, Theme} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";

import ShowResultsForm from "../components/ShowResultsForm";
import {DdHelper} from "../dd/ddHelper";
import {AdvisorHelper} from "../charts/advisorHelper";
import {LambdaHelper} from "../lambda/lambdaHelper";
import LoadingAnimation from "../components/LoadingIndicators/LoadingAnimation";
import {AggregatedReport, BarChartData, DataStats, QualityMetrics, Report} from "../types/types";
import AggregatedReportTable from "../components/Tables/AggregatedReportTable";
import LastGoLiveGrid from "../components/LastGoLiveGrid";
import QualityMetricsGrid from "../components/QualityMetricsGrid";
import ReportTable from "../components/Tables/ReportTable";
import {ChartHelper, ChartHelper2} from "../charts/chartHelper";
import {BarChartMetrics, BarChartTrainingResults} from "../components/BarChart";
import CustomizedSnackbars from "../components/InfoMessage"
import TrainingStatsGrid from "../components/TrainingStatsGrid";

export interface Props {
    classes: any;
}

const initialAggregatedReport: AggregatedReport = {
    statements: [],
};

export interface MessageType {
    message: string;
    type: string;
}

const FlowAnalyzer: React.FC<Props> = ({classes}: Props) => {
    const ddHelper = new DdHelper();
    const lambdaHelper = new LambdaHelper();
    const advisorHelper = new AdvisorHelper();

    const [loading, setLoading] = React.useState(false);

    const [aggregatedReport, setAggregatedReport] = React.useState(initialAggregatedReport);
    const [aggregatedReportMessage, setAggregatedReportMessage] = React.useState<MessageType | null>(null);

    const [lglTime, setLglTime] = React.useState(0);
    const [lglMessage, setLglMessage] = React.useState<MessageType | null>(null);

    const [clickoutReports, setClickoutReports] = React.useState<Report[]>([]);
    const [insightsReports, setInsightsReports] = React.useState<Report[]>([]);

    const emptyBarchart = {
        texts: [],
        answerOrigins: [],
        positiveWeights: [],
        negativeWeights: [],
        eventBarColor: '',
        counterEventBarColor: '',
        showLegend: true
    };
    const [barchartData, setBarchartData] = React.useState<BarChartData>(emptyBarchart);
    const [barchartMessage, setBarchartMessage] = React.useState<MessageType | null>(null);

    const [metricBarChartData, setMetricBarChartData] = React.useState<BarChartData>({
        texts: [],
        answerOrigins: [],
        positiveWeights: [],
        eventBarColor: ''
    });
    const [metricBarchartMessage, setMetricBarchartMessage] = React.useState<MessageType | null>(null);

    const [dataStats, setDataStats] = React.useState<DataStats | undefined>(undefined);
    const [dataStatsMessage, setDataStatsMessage] = React.useState<MessageType | null>(null);

    const [isQualityMetrics, setIsQualityMetrics] = React.useState<QualityMetrics | undefined>(undefined);
    const [isQualityMetricsMessage, setIsQualityMetricsMessage] = React.useState<MessageType | null>(null);


    return (
        <div className="App">

            <Grid container spacing={1} alignItems="center" >
                <Grid item>
                    <ShowResultsForm onSubmit={async ({stage, env, advisorId, lid}) => {

                    setLoading(true);

                    const aggregatedReportPromise = lambdaHelper.getAggregatedReport(stage, env, advisorId);
                    const lglPromise = advisorHelper.fetchLastGoLive(env, advisorId);
                    const metricPromise = lambdaHelper.getMetricReporterResponse(stage, env, advisorId, lid, 'clickout');

                    Promise.all([
                        aggregatedReportPromise.catch(error => {
                            console.log(error);
                            setAggregatedReport(initialAggregatedReport);
                            setAggregatedReportMessage({
                                message: 'Problem getting aggregated report',
                                type: 'error'
                            });
                        }),
                        lglPromise.catch(error => {
                            console.log(error);
                            setLglTime(0);
                            setLglMessage({message: 'Problem getting last-go-live report', type: 'error'});
                        }),
                        metricPromise.catch(error => {
                            setMetricBarChartData(emptyBarchart);
                            setMetricBarchartMessage({
                                message: 'Could not fetch normalized metrics.',
                                type: 'error'
                            });
                        })
                    ]).then(([aggregatedReport, lglTimestamp, metricResult]) => {

                        if (aggregatedReport) {
                            setAggregatedReport(aggregatedReport);
                            setAggregatedReportMessage(null);
                        }
                        if (lglTimestamp) {
                            setLglTime(lglTimestamp);
                            setLglMessage(null);
                        }
                        if (metricResult) {
                            if (metricResult.questions) {
                                const chartHelper = new ChartHelper2(metricResult.questions, []); // todo: pages for LID
                                setMetricBarChartData({
                                    texts: chartHelper.texts, answerOrigins: chartHelper.answerOrigins,
                                    positiveWeights: chartHelper.positiveWeights, eventBarColor: '#64439c'
                                });
                                setMetricBarchartMessage(null);
                            } else {
                                setMetricBarChartData({
                                    texts: [],
                                    answerOrigins: [],
                                    positiveWeights: [],
                                    eventBarColor: '#64439c'
                                });
                                setMetricBarchartMessage({
                                    message: 'No data or no significant data for this advisor',
                                    type: 'info'
                                });
                            }
                        }

                    }).finally(() => setLoading(false));

                    const clickoutReportsPromise = await ddHelper.getPerformanceReports(stage, env, advisorId, lid, 'qqml1Clickout', 100);
                    setClickoutReports(clickoutReportsPromise);

                    const insightsPeportsPromise = await ddHelper.getPerformanceReports(stage, env, advisorId, lid, 'insights', 100);
                    setInsightsReports(insightsPeportsPromise);

                    const trainingPromise = ddHelper.getTrainingResult(stage, env, advisorId, lid, 'clickout');
                    const pagesPromise = advisorHelper.getAdvisorPages(env, advisorId, "0", lid);
                    const isPromise = lambdaHelper.getISResponse(stage, env, advisorId, lid, 'clickout');


                    Promise.all([
                        trainingPromise.catch(error => {
                            console.log(error);
                            setBarchartData(emptyBarchart);
                            setBarchartMessage({
                                message: 'Problem fetching training results',
                                type: 'error'
                            });
                        }),
                        pagesPromise.catch(error => {
                            console.log(error);
                            setBarchartData(emptyBarchart);
                            setBarchartMessage({
                                message: 'Could not fetch question texts. May be no live revision?',
                                type: 'error'
                            });
                        }),
                        isPromise.catch(error => {
                            setIsQualityMetrics(undefined);
                            setIsQualityMetricsMessage({
                                message: 'Problem getting interpreter service report',
                                type: 'error'
                            });
                        })

                    ]).then(([trainingResult, pages, isQualityMetrics]) => {
                        if (trainingResult && pages) {
                            if (!trainingResult.error) {
                                const chartHelper = new ChartHelper(trainingResult.questions, pages);
                                setBarchartData({
                                    texts: chartHelper.texts,
                                    answerOrigins: chartHelper.answerOrigins,
                                    positiveWeights: chartHelper.positiveWeights,
                                    negativeWeights: chartHelper.negativeWeights,
                                    eventBarColor: '#009688',
                                    counterEventBarColor: '#DCDCDC',
                                    showLegend: true
                                });
                                setBarchartMessage(null);
                            } else {
                                setBarchartData({
                                    texts: [],
                                    answerOrigins: [],
                                    positiveWeights: [],
                                    negativeWeights: [],
                                    eventBarColor: '#009688',
                                    counterEventBarColor: '#DCDCDC',
                                    showLegend: true
                                });
                                setBarchartMessage({message: trainingResult.error, type: 'info'});
                            }
                        }

                        if (trainingResult) {
                            if (!trainingResult.error) {
                                setDataStats(trainingResult.dataStats);
                                setDataStatsMessage(null);
                            } else {
                                setDataStats({numberOfEvents: 0, numberOfSessions: 0});
                                setDataStatsMessage(null);
                            }
                        }

                        if (isQualityMetrics) {
                            if (!isQualityMetrics.error) {
                                setIsQualityMetrics(isQualityMetrics.training_metrics);
                                setIsQualityMetricsMessage(null);
                            } else {
                                setIsQualityMetrics(undefined);
                                setIsQualityMetricsMessage({message: isQualityMetrics.error, type: 'info'});
                            }
                        }

                    }).finally(() => undefined
                    );

                }}>

                </ShowResultsForm>
                </Grid>
                <Grid item>
                    <LoadingAnimation loading={loading}/>
                </Grid>
            </Grid>


            <div className={classes.box} style={{width: '35%'}}>
                {<AggregatedReportTable rows={(aggregatedReport).statements}
                                        snackbar={aggregatedReportMessage ? <CustomizedSnackbars
                                            className={aggregatedReportMessage.type as unknown as string}
                                            variant={aggregatedReportMessage.type as unknown as string}
                                            message={aggregatedReportMessage.message as unknown as string}/> : undefined}/>}
                {<ReportTable title={'Training Reports for LID'} reports={clickoutReports}/>}
                {<ReportTable title={'Insights Reports for LID'} reports={insightsReports}/>}
            </div>

            <div className={classes.box}>
                {<BarChartTrainingResults barChartData={barchartData} title='Last Training Results'
                                          snackbar={barchartMessage ? <CustomizedSnackbars
                                              className={barchartMessage.type as unknown as string}
                                              variant={barchartMessage.type as unknown as string}
                                              message={barchartMessage.message as unknown as string}/> : undefined}/>}
                {<BarChartMetrics barChartData={{
                    texts: metricBarChartData.texts,
                    answerOrigins: metricBarChartData.answerOrigins,
                    positiveWeights: metricBarChartData.positiveWeights,
                    eventBarColor: metricBarChartData.eventBarColor
                }}
                                  title='Zoovu Platform (LID with highest number of sessions)'
                                  barType='bar' snackbar={metricBarchartMessage ?
                    <CustomizedSnackbars className={metricBarchartMessage.type as unknown as string}
                                         variant={metricBarchartMessage.type as unknown as string}
                                         message={metricBarchartMessage.message as unknown as string}/> : undefined}/>}

                <div className={`${classes.box} ${classes.justifyBox}`}>
                    <div className={classes.box1}>
                        {<QualityMetricsGrid qualityMetrics={isQualityMetrics}
                                             snackbar={isQualityMetricsMessage ? <CustomizedSnackbars
                                                 className={isQualityMetricsMessage.type as unknown as string}
                                                 variant={isQualityMetricsMessage.type as unknown as string}
                                                 message={isQualityMetricsMessage.message as unknown as string}/> : undefined}/>}
                    </div>
                    <div className={classes.box1}>
                        {<TrainingStatsGrid dataStats={dataStats}
                                            snackbar={dataStatsMessage ? <CustomizedSnackbars
                                                className={dataStatsMessage.type as unknown as string}
                                                variant={dataStatsMessage.type as unknown as string}
                                                message={dataStatsMessage.message as unknown as string}/> : undefined}/>}

                        {<LastGoLiveGrid timestamp={lglTime}
                                         snackbar={lglMessage ? <CustomizedSnackbars
                                             className={lglMessage.type as unknown as string}
                                             variant={lglMessage.type as unknown as string}
                                             message={lglMessage.message as unknown as string}/> : undefined}/>}
                    </div>
                </div>
            </div>

        </div>
    );
};

const styles = (theme: Theme) => createStyles({
    controlsRoot: {
        display: 'flex',
        flexDirection: 'row'
    },
    box: {
        float: 'left',
        margin: 10
    },
    box1: {
        flexGrow: 1
    },
    justifyBox: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        margin: 0,
        marginTop: theme.spacing(3)
    }
});


export default withStyles(styles)(FlowAnalyzer);

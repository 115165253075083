export const getAxisBottomRotation = (itemsLength: number) => {
    // given the length of the list of items that are shown on a plot, decide how the X axis labels should be rotated
    // if more than 10 items, then show them vertically, else horizontally
    if(itemsLength > 10) {
        return 90;
    } else {
        return 0;
    }
}

export const getMaxXDataLength = (props: any) =>
    // given a props object with lines. Go through all the lines and return the max length of X data, which will then
    // be used to decide what the rotation of the labels should be
    Math.max.apply(Math, props.lines.map(function(o: any) { return o.xData.length; }));
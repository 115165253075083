import React from 'react';

import {createStyles, makeStyles} from "@material-ui/styles";

import {Theme} from "../../styles/theme/types";

import CptButton, { ButtonBaseProps } from './CptButton';

const useStyles = makeStyles<Theme>((theme: Theme) => {
    const getBaseColor = () => theme.palette.secondary.main;
    const borderWidth = 0;

    return createStyles({
        root: {
            border: `${borderWidth}px`,
            backgroundColor: getBaseColor(),
            '&:hover, &:active': {
                backgroundColor: getBaseColor(),
                border: `${borderWidth}px`
            },
            margin: theme.spacing(1),
            color: theme.palette.white,
            weightForFont: theme.typography.fontWeightBold,
            fontSize: theme.typography.fontSizes.regular
        }
    });
});

export interface CptNavButtonProps extends Omit<ButtonBaseProps, 'children'|'variant'|'pending'|'disabled'|'type'|'size'> {
    children: string;
}

const CptNavButton: React.FC<CptNavButtonProps> = ({ children, ...rest }) => {
    const classes = useStyles();
    return <CptButton variant='secondary' className={classes.root} {...rest}>{children}</CptButton>;
};

export default CptNavButton;

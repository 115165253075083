import { AWSError } from 'aws-sdk/lib/error';
import {Lambda} from "aws-sdk";

import {AggregatedReport, ISResponse, MRResponse} from "../types/types";


const qrLambda ='_ai_qqml_quality_reporter';
const isLambda ='_ai_qqml_interpreter_service';
const mrLambda ='_ai_metric_reporter';


export class LambdaHelper {

    lambda = new Lambda();

    public async getAggregatedReport(stage: string, env: string, advisorId: number, trainingTarget?: string): Promise<AggregatedReport> {

        const params = {
            FunctionName: stage + qrLambda,
            InvocationType: 'RequestResponse',
            LogType: 'None',
            Payload: '{"pathParameters": { "advisorId" : ' + advisorId + ', "env": "' + env + '" }}'
        };

        return new Promise<AggregatedReport>((fulfill, reject) => {
            this.lambda.invoke(params, (err: AWSError, data: Lambda.Types.InvocationResponse) => {
                if (!err) {
                    const item = data.Payload as string;
                    const statusCode = data.StatusCode;
                    console.log("GR Lambda response: " + JSON.stringify(JSON.parse(item)));

                    if (statusCode === 200) {
                        const asJson = JSON.parse(item);
                        if (asJson['body'] && JSON.parse(asJson['body']).statements) {
                            try {
                                fulfill(JSON.parse(asJson['body']));
                            }
                            catch (e) {
                                console.error(e);
                                reject('Could not get aggregated response');
                            }
                        } else {
                            reject('Could not get aggregated response');
                        }

                    } else {
                        reject(Error(qrLambda + " responded with " + statusCode));
                    }

                } else {
                    reject(Error(err.message))
                }

            });
        });

    }

    public async getISResponse(stage: string, env: string, advisorId: number, localizationId: number, trainingTarget?: string): Promise<ISResponse> {

        const params = {
            FunctionName: stage + isLambda,
            InvocationType: 'RequestResponse',
            LogType: 'None',
            Payload: '{"advisor_id" : ' + advisorId + ', "localization_id" : ' + localizationId + ', "env": "' + env + '", "training_target": "' + trainingTarget + '" }'
        };

        return new Promise<any>((fulfill, reject) => {
            this.lambda.invoke(params, (err: AWSError, data: Lambda.Types.InvocationResponse) => {
                if (!err) {
                    const item = data.Payload as string;
                    const statusCode = data.StatusCode;
                    console.log("IS Lambda response: " + item);

                    if (statusCode === 200) {
                        let asJson = JSON.parse(item);
                        asJson = JSON.parse(asJson);
                        if (asJson['error_message']) {
                            fulfill({questions: [], error: asJson['error_message']});
                        } else {
                            asJson.training_metrics.f1 = asJson.training_metrics['f1-score'];
                            delete asJson.training_metrics['f1-score'];
                            asJson.training_metrics.recall = asJson.training_metrics['recall-score'];
                            delete asJson.training_metrics['recall-score'];
                            asJson.training_metrics.precision = asJson.training_metrics['precision-score'];
                            delete asJson.training_metrics['precision-score'];
                            fulfill(asJson);
                        }

                    } else {
                        reject(Error(isLambda + " responded with " + statusCode));
                    }

                } else {
                    reject(Error(err.message))
                }

            });
        });
    }

    public async getMetricReporterResponse(stage: string, env: string, advisorId: number, localizationId?: number, trainingTarget?: string): Promise<MRResponse> {

        const params = {
            FunctionName: stage + mrLambda,
            InvocationType: 'RequestResponse',
            LogType: 'None',
            Payload: '{"pathParameters": { "advisorId" : ' + advisorId + ', "env": "' + env + '" }}'
        };

        const jsonResponse = await this.invoke<MRResponse>(params);
        /* if (jsonResponse.questions === undefined) {
            jsonResponse.error = 'No data or no significant data for this advisor'
        } */
        return jsonResponse

    }


    private async invoke<T>(params: LambdaParameters): Promise<T> {
        return new Promise<any>((fulfill, reject) => {
            this.lambda.invoke(params, (err: AWSError, data: Lambda.Types.InvocationResponse) => {
                if (!err) {
                    const item = data.Payload as string;
                    const statusCode = data.StatusCode;
                    console.log(params.FunctionName + " response: " + item);
                    try {
                        const asJson = JSON.parse(item);
                        if(asJson.statusCode === 200) {
                            fulfill(JSON.parse(asJson['body']));
                        } else if(asJson.statusCode === 204) {
                            fulfill(JSON.parse(asJson['body']));
                        } else {
                            reject(Error(params.FunctionName + ' responded with status code: ' + statusCode));
                        }
                    } catch (e) {
                        console.error(e);
                        reject('Problem getting lambda response');
                    }

                } else {
                    reject(Error(err.message))
                }

            });
        });
    }

}


interface LambdaParameters {
    FunctionName: string,
    InvocationType: string,
    LogType: string,
    Payload: string
}


import React from "react";

import {Chart, PositionType} from 'chart.js'

import Paper from '@material-ui/core/Paper';
import {Theme, withStyles} from "@material-ui/core/styles";
import {createStyles} from "@material-ui/core";

import {BarChartData} from "../types/types";

import Header from "./Tables/ReportHeader";

interface Props {
    barChartData: BarChartData;
    title: string;
    barType?: string;
    classes: any;
    snackbar?: React.ReactElement;
}

const styles1 = (theme: Theme) => createStyles({
        root: {
            width: '100%',
            marginTop: 0,
        },
        collapseCanvasContainer: {
            position: 'relative',
            height:'0vh',
            width:'30vw'
        },
        canvasContainer: {
            position: 'relative',
            height: '50vh',
            width:'30vw'
        }
    });

const styles2 = (theme: Theme) => createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
        },
        collapseCanvasContainer: {
            position: 'relative',
            height:'0vh',
            width:'30vw'
        },
        canvasContainer: {
            position: 'relative',
            height: '25vh',
            width:'30vw'
        }
    });

class BarChart extends React.Component<Props> {

    private canvasRef = React.createRef<HTMLCanvasElement>();
    private chart?: Chart;

    shouldComponentUpdate(nextProps: Props) {
        return (this.props.barChartData.texts !== nextProps.barChartData.texts
            || this.props.barChartData.negativeWeights !== nextProps.barChartData.negativeWeights
            || this.props.barChartData.positiveWeights !== nextProps.barChartData.positiveWeights
            || this.props.barChartData.answerOrigins !== nextProps.barChartData.answerOrigins
            || this.props.snackbar !== nextProps.snackbar)
    }

    componentDidUpdate(prevProps: Props, prevState: Props) {
        this.updateCanvas();
    }

    updateCanvas() {
        const { barChartData, barType } = this.props;
        const datasets = this.prepareDatasets();

        if (this.chart) {
            this.chart.destroy();
        }
        const node = this.canvasRef.current;
        if (node == null) { return };
        const ctx = node.getContext('2d');
        if (ctx == null) { return; }
        ctx.clearRect(0, 0, 1000, 1000);

        if (barChartData.positiveWeights && barChartData.positiveWeights.length) {
            this.chart = new Chart(ctx as CanvasRenderingContext2D, {
                type: barType?barType:'horizontalBar',
                data: {
                    labels: barChartData.texts,
                    datasets: datasets
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        position: 'top' as PositionType,
                        display: !!barChartData.showLegend
                    },
                    scales: {
                        yAxes: [{
                            // barThickness: 'flex',
                            ticks: {
                                display: true,
                                callback: function(label: string, index: number, labels: []) {
                                    if (label.length > 20) {
                                        return label.substring(0, 19) + "...";
                                    } else {
                                        return label;
                                    }
                                }
                            },
                        }],
                    },
                    tooltips: {
                        // Disable the on-canvas tooltip
                        enabled: false,

                        custom: (tooltipModel) => {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = "0";
                                return;
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                const bodyLines = tooltipModel.body.map((bodyItem: any) => bodyItem.lines);

                                let innerHtml = '<thead>';

                                innerHtml += barChartData.texts[tooltipModel.dataPoints[0].index!];
                                innerHtml += ' (' + barChartData.answerOrigins[tooltipModel.dataPoints[0].index!] + ')';
                                innerHtml += '</thead><tbody>';

                                bodyLines.forEach((body: string, i: number) => {
                                // let colors = tooltipModel.labelColors[i];
                                    let style = 'background: red';
                                    style += '; border-color: blue';
                                    style += '; border-width: 2px';
                                    const span = '<span style="' + style + '"></span>';
                                    innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                });
                                innerHtml += '</tbody>';

                                const tableRoot = tooltipEl.querySelector('table');
                                if (tableRoot == null) { return; }
                                tableRoot.innerHTML = innerHtml;
                            }

                            // `this` will be the overall tooltip
                            const position = node.getBoundingClientRect();

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = '1';
                            tooltipEl.style.backgroundColor = 'Azure';
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                            tooltipEl.style.pointerEvents = 'none';
                        }
                    }
                }

            });
            this.chart.update();
        }
    }

    private prepareDatasets() {
        const barChartData = this.props.barChartData;
        const datasets = [];
        const data = {
            data: barChartData.positiveWeights,
            backgroundColor: barChartData.eventBarColor
        } as any;
        data['label'] = 'Mean Coefficient';
        datasets.push(data);
        if (barChartData.negativeWeights) {
            const data = {
                data: barChartData.negativeWeights,
                backgroundColor: barChartData.counterEventBarColor
            } as any;
            datasets.push(data);
        }
        return datasets;
    }

    render() {
        const {barChartData, title, classes, snackbar} = this.props;
        let canvasContainer = classes.collapseCanvasContainer;
        if (barChartData.positiveWeights && barChartData.positiveWeights.length > 0) {
            canvasContainer = classes.canvasContainer;
        }
        return (
            <Paper className={classes.root}>
                <Header header={title}/>
                <div className={canvasContainer}>
                    <canvas id="barchart" ref={this.canvasRef}></canvas>
                </div>
                {snackbar}
            </Paper>
        );
    }

}

const BarChartTrainingResults = withStyles(styles1 as any)(BarChart);
const BarChartMetrics = withStyles(styles2 as any)(BarChart);

export { BarChartTrainingResults, BarChartMetrics };
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import createStyles from "@material-ui/styles/createStyles/createStyles";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import {Grid, Container, Typography} from "@material-ui/core";

import {Pipeline} from "../../types/health";
import {Theme} from "../../styles/theme/types";

import {PipelineCard} from "./PipelineCard";

const styles = (theme: Theme) => createStyles({
        root: {
            background: theme.palette.greys.bcg.mainBcg
        },
        container: {
            marginTop: '20px'
        },
        selected: {
            backgroundColor: theme.palette.greys.bcg.section,
        },
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            minWidth: 500,
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
        },
        image: {
            width: 64,
                height: 64,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        pager: {
            textAlign: 'center'
        },
        pagerItem: {
            padding: '0 10px 0 10px'
        }
});

interface Props {
    pipelines: Pipeline[];
    pipelineSelected: (pplIdx: number) => void;
    itemsPerPage: number;
    selectedPipelineIdx: number;
    classes: any;
}

interface PipelinesState {
    currentPage: number;
}

class PipelineCardContainer extends React.Component<Props, PipelinesState> {

    state = {
        currentPage: 0
    };

    handleNextButtonClick = async () => {
        const {itemsPerPage} = this.props;
        const {currentPage} = this.state;
        const nextPage = currentPage+1 >= Math.ceil(this.getItemCount() / itemsPerPage)? currentPage: currentPage+1;
        this.setState({
            currentPage: nextPage,
        });
        this.props.pipelineSelected((nextPage !== currentPage)? -1: this.props.selectedPipelineIdx);
    };

    handleFirstPageButtonClick = async () => {
        this.setState({
            currentPage: 0,
        });
        this.props.pipelineSelected(-1);
        console.log('set state, curr page: ' + 0)
    };

    handleLastPageButtonClick = async () => {
        const {itemsPerPage} = this.props;
        const lastPage = Math.ceil(this.getItemCount() / itemsPerPage) - 1;
        this.setState({
            currentPage: lastPage,
        });
        this.props.pipelineSelected(-1);
        console.log('set state, curr page: ' + lastPage)
    };

    handleBackButtonClick = async () => {
        const {currentPage} = this.state;
        const previousPage = currentPage-1 < 0? currentPage: currentPage-1;
        this.setState({
            currentPage: previousPage,
        });
        this.props.pipelineSelected((previousPage !== currentPage)? -1: this.props.selectedPipelineIdx);
        console.log('set state, curr page: ' + previousPage)
    };

    getItemCount = () => this.props.pipelines.length;

    public render() {
        const { itemsPerPage, pipelines, pipelineSelected, selectedPipelineIdx, classes } = this.props;
        const { currentPage } = this.state;
        const offset = currentPage * itemsPerPage;

        return (
            <Container maxWidth={false} className={`${classes.container}`}>
                <Grid container item xs={12} spacing={3}>
                    {/* Only show slice for page but keep global ppl index updated in case of selection */}
                    {pipelines.slice(offset, offset + itemsPerPage).map((pipeline: Pipeline, idx: number) => (
                        <Grid item xs key={idx} onClick={ () => {
                            pipelineSelected(offset + idx)
                        } }>
                            <PipelineCard pipeline={pipeline} selected={(offset + idx) === selectedPipelineIdx}
                                      iconPath='M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z'
                                      specificationAttrName={'ai_tag_name'} purposeAttrName={'purpose'}/>
                        </Grid>
                    ))}
                    {(pipelines.length > 0)?
                    <Grid item xs={12}>
                        <div className={classes.pager}>
                            <IconButton
                                className={classes.pagerItem}
                                onClick={this.handleFirstPageButtonClick}
                                disabled={currentPage === 0}
                                aria-label="First Page">
                                <FirstPageIcon />
                            </IconButton>
                            <IconButton
                                className={classes.pagerItem}
                                onClick={this.handleBackButtonClick}
                                disabled={currentPage === 0}
                                aria-label="Previous Page">
                                <KeyboardArrowLeft />
                            </IconButton>
                            <Typography variant="button">
                                Page {currentPage+1} from {Math.ceil(this.getItemCount() / itemsPerPage)}
                            </Typography>

                            <IconButton
                                className={classes.pagerItem}
                                onClick={this.handleNextButtonClick}
                                disabled={itemsPerPage <= 0 || currentPage >= Math.ceil(this.getItemCount() / itemsPerPage)-1}
                                aria-label="Next Page">
                                <KeyboardArrowRight />
                            </IconButton>
                            <IconButton
                                className={classes.pagerItem}
                                onClick={this.handleLastPageButtonClick}
                                disabled={itemsPerPage <= 0 || currentPage >= Math.ceil(this.getItemCount() / itemsPerPage)-1}
                                aria-label="Last Page">
                                <LastPageIcon />
                            </IconButton>
                        </div>
                    </Grid>: null}
                </Grid>
                </Container>

        )
    };
}

export default withStyles(styles)(PipelineCardContainer);
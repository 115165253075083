import React from 'react';

import { ResponsiveLine } from '@nivo/line'
import {Paper, Grid, Typography} from '@material-ui/core';
import { makeStyles, Theme } from "@material-ui/core/styles";
import createStyles from "@material-ui/styles/createStyles/createStyles";

import {dateFromMilliseconds} from "../../utils/utils";
import {InfoText} from "../InfoTexts/InfoText";

import {getAxisBottomRotation} from "./SharedPlotUtils/PlotUtils";
import {getYFormat} from "./LinePlotUtils/LineUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
        },
        title: {
            ...theme.typography.h4,
            textAlign: 'center'
        }
    }),
);

interface IProps {
    xTitle?: string,
    yTitle: string,
    dataTitle: string,
    xData: number[],
    yData: number[],
    showAsPercentage: boolean,
    infoText?: string,
}


const TimeLinePlot: React.FC<IProps> = React.memo(props => {
    const classes = useStyles();

    function getData() {
        return [{
            id: `${props.dataTitle}`,
            data: [...props.xData.keys()].map((index: number) => ({
                x: dateFromMilliseconds(props.xData[index]),
                y: props.yData[index]
            }))
        }]
    }

    return (
        <Paper className={`${classes.paper}`}>
            <Grid container spacing={1}>
                <Grid item xs={12} >
                    <Typography className={classes.title}>
                        {props.yTitle}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ height: '300px' }} >
                    <ResponsiveLine
                        curve="linear"
                        data={getData()}
                        margin={{ top: 30, right: 2, bottom: 50, left: 50 }}
                        colors={{ scheme: 'accent' }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: getAxisBottomRotation(props.xData.length),
                            format: '%b %d',
                            legend: props.xTitle,
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 0,
                            tickRotation: 0,
                            format: getYFormat(props.showAsPercentage)
                        }}
                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%d-%H-%M',
                            precision: 'minute',
                            useUTC: true
                        }}
                        xFormat="time:%Y-%m-%d"
                        yFormat={getYFormat(props.showAsPercentage)}
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                        }}
                        enableGridX={false}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        enableSlices='x'
                    />
                </Grid>
            </Grid>
           {props.infoText &&
                <Grid item xs={12} >
                    <InfoText info={props.infoText}/>
                </Grid>
              }
        </Paper >

    )
});

export default TimeLinePlot;
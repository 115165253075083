import {Advisor} from "../types/types";
import {FilterSettings} from "../components/Selectors/AssistantSelector";

export class AdvisorHelper {

    public static readonly ST_EU = "ST_EU";
    public static readonly ST_US = "ST_US";
    public static readonly SL = "SL";
    public static readonly tiger = "tiger";
    public static readonly barracuda = "barracuda";

    public async getAdvisorPages(env: string, advisorId: number, revision: string, localizationId: number): Promise<any>{
        if (localizationId > 1000) {
            const advisorCode = await this.javascriptRef(env, advisorId, revision, 1);
            const locale = await this.getLocale(localizationId);
            const model = await this.fetchModel(env, advisorCode, locale);
            return model['pages'];
        } else {
            const advisorCode = await this.javascriptRef(env, advisorId, revision, localizationId);
            const model = await this.fetchModel(env, advisorCode);
            return model['pages'];
        }

    };

    async fetchLastGoLive(env: string, advisorId: number): Promise<number> {
        const hostname = `${process.env.REACT_APP_BACKEND_URL}/advisor-fe-web/${env}/${advisorId}/last-go-live`;
        const response = await fetch(hostname);
        return await response.json();
    }

    async fetchFromAep(hostname: string): Promise<Advisor[]> {
        const response = await fetch(hostname, {
            method: 'GET',
            headers: { 'Content-type': 'application/json' }
        });
        if (response && response.ok) {
            const result = await response.json();
            const advisors = result['advisors'];
            advisors.sort((a: Advisor, b: Advisor) => {
                if ( a.advisorLabel < b.advisorLabel ) {
                    return -1;
                }
                if ( a.advisorLabel > b.advisorLabel ) {
                    return 1;
                }
                return 0;
            });
            return advisors;
        }
        return Promise.reject("Could not fetchFromAep");
    }

    async fetchModel(env: string, baseUrl: string, locale = ''): Promise<any> {
        const response = await fetch(baseUrl + '/model?' + locale);
        return await response.json();
    }

    async javascriptRef(env: string, advisorId: number, revision: string, localizationId: number): Promise<string> {
        const hostname = `${process.env.REACT_APP_BACKEND_URL}/advisor-fe-web/${env}/${advisorId}/${revision}/${localizationId}/javascript-ref`;
        const response = await fetch(hostname);
        return await response.text();
    }

    async getLocale(localizationId: number) {
        const mapping = await this.getLocaleMapping();
        return mapping[localizationId];
    }

    async getLocaleMapping(): Promise<string> {
        const response = await fetch("ai-locale-map.zoovu.com/stub", {
            method: "GET",
            headers: {
                "accept": "text/html"
            }
        });

        if (response && response.ok) {
            const result = await response.json();
            return result;
        }
        return Promise.reject("Could not fetch locale mapping");
    }



    public static mapEnvToApiHost(stage: string): string {
        switch (stage) {
            case AdvisorHelper.ST_EU:
                return "st.smartassistant.com";
            case AdvisorHelper.ST_US:
                return "us-st.smartassistant.com";
            case AdvisorHelper.SL:
                return "softlayer.smartassistant.com";
            case AdvisorHelper.tiger:
                return "tiger-st3.zoovu.com";
            case AdvisorHelper.barracuda:
                return "barracuda-st3.zoovu.com";
            default:
                return stage.toLowerCase().replace("_", "") + ".smartassistant.com"; // e.g. QA_1
        }
    };

}
import React, {ReactElement, useRef} from 'react';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Grid, Fade } from '@material-ui/core';

import DateRangeSelector from '../Datetime/DateRangeSelector'
import CptButton from '../Buttons/CptButton'
import LoadingAnimation from "../LoadingIndicators/LoadingAnimation";
import {fetchAssistants, fetchStagesByTimerange} from "../../utils/fetch";

import {DropDown} from "./DropDown";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
        }
    }),
);

interface Props {
    initialStartDate: number;
    initialEndDate: number;
    projectName: string;
    goButtonPressed: (filterSettings: FilterSettings) => void;
    childComponents?: ReactElement[];
    resetChildrenSelections?: () => void;
}


// Selection made which will be further used to select data for this particular assistant and time range.
export interface FilterSettings {
    startDate: number;
    endDate: number;
    projectName: string;
    stage: string;
    env: string;
    assistantIdentifier?: string;
    identifierType?: string;
    locale?: string;
}

// Elements of the selector
export interface EndpointAssistant {
    env: string
    assistantIdentifier: string
    identifierType: string
    assistantLabel: string
    locales: string[]
}

export const AssistantSelector: React.FC<Props> = ({ initialStartDate, initialEndDate, projectName, goButtonPressed,
                                                       childComponents, resetChildrenSelections}) => {
    const classes = useStyles();

    const assistantsAllEnvs = useRef<EndpointAssistant[]>([]);

    const [stages, _setStages] = React.useState([] as string[]);
    const setStages = (stages: string[]) => {
        _setStages(addNeutralOption(stages));
    };
    const [assistants, setAssistants] = React.useState([] as EndpointAssistant[]);
    const [envs, _setEnvs] = React.useState([] as string[]);
    const setEnvs = (envs: string[]) => {
        _setEnvs(addNeutralOption(envs));
    };
    const [locales, _setLocales] = React.useState([] as string[]);
    const setLocales = (locales: string[]) => {
        _setLocales(addNeutralOption(locales));
    };
    const [loading, setLoading] = React.useState(false);
    const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);

    const [chosenFilterSettings, setChosenFilterSettings] = React.useState({
        startDate: initialStartDate,
        endDate: initialEndDate,
        projectName: projectName
    } as FilterSettings);

    function invokeChildrenReset() { if (resetChildrenSelections) {resetChildrenSelections(); }}

    // we need this "neutraliser" in selector drop-down in order reset to it when e.g. assistant selection changes
    // without it, selector is not triggering onchange event if the value stays the same (e.g. locale is the same in the newly selected assistant)
    // to workaround this, we always do reset to neutral element, so that the user is forced to make a change
    const NEUTRAL_SELECTION = "";

    function addNeutralOption(options: string[]) {
        return [NEUTRAL_SELECTION, ...options.flat()];
    }

    const manageProgressiveSelectors = (step: string, stage= NEUTRAL_SELECTION) => {
        switch(step) {
            case 'dateChanged': {
                setStages([]);
                setEnvs([]);
                assistantsAllEnvs.current = [];
                setAssistants([]);
                setLocales([]);
                invokeChildrenReset();
                setSubmitButtonDisabled(false);
                break;
            }
            case 'stageChanged': {
                fetchAssistantsPerStage(stage);
                setAssistants([]);
                setLocales([]);
                invokeChildrenReset();
                setSubmitButtonDisabled(true);
                break;
            }
            case 'envChanged': {
                setLocales([]);
                invokeChildrenReset();
                setSubmitButtonDisabled(true);
                break;
            }
            case 'assistantChanged': {
                setSubmitButtonDisabled(true);
                invokeChildrenReset();
                break;
            }
            case 'localeChanged': {
                setSubmitButtonDisabled(false);
                invokeChildrenReset();
                break;
            }
        }
    };

    const getAvailableEnvs = (assistants: EndpointAssistant[]): string[] => {
        const envs: string[] = [];
        assistants.forEach(assistant => {
            if (envs.indexOf(assistant.env) === -1) {
                envs.push(assistant.env)
            }
        });
        return envs
    };

    const assistantChanged = (assistantIdentifier: string) => {
        const chosenAssistant = assistants.find(assistant => assistant.assistantIdentifier === assistantIdentifier);
        if (chosenAssistant) {
            setChosenFilterSettings({
                ...chosenFilterSettings,
                env: chosenAssistant.env,
                assistantIdentifier: chosenAssistant.assistantIdentifier,
                identifierType: chosenAssistant.identifierType,
                locale: NEUTRAL_SELECTION
            });
            setLocales(chosenAssistant.locales);
            manageProgressiveSelectors('assistantChanged');
        }
    };

    const localeChanged = (newLocale: string) => {
        setChosenFilterSettings({
            ...chosenFilterSettings,
            locale: newLocale
        });
        manageProgressiveSelectors('localeChanged')
    };

    const dateChanged = (start: number, end: number) => {
        setChosenFilterSettings({
            ...chosenFilterSettings,
            startDate: start,
            endDate: end,
            stage: NEUTRAL_SELECTION
        });
        manageProgressiveSelectors('dateChanged')
    };

    const stageChanged = (newStage: string) => {
        setChosenFilterSettings({
            ...chosenFilterSettings,
            stage: newStage,
            env: NEUTRAL_SELECTION
        });

        manageProgressiveSelectors('stageChanged', newStage)
    };

    const envChanged = (env: string) => {
        setChosenFilterSettings({
            ...chosenFilterSettings,
            env: env,
            locale: NEUTRAL_SELECTION
        });
        setAssistants(assistantsAllEnvs.current.filter(assistant => assistant.env === env));
        manageProgressiveSelectors('envChanged')
    };

    const fetchPipelineStages = () => {
        setLoading(true);

        fetchStagesByTimerange(projectName, chosenFilterSettings.startDate, chosenFilterSettings.endDate).then(stages => {
            setStages(stages);
            setSubmitButtonDisabled(true);
        }).catch((reason) => {
            console.log(reason);
            setSubmitButtonDisabled(false); // allow retry
        }).finally(() => {
            setLoading(false);
        })
    };

    const fetchAssistantsPerStage = (stage: string) => {
        setLoading(true);
        fetchAssistants(chosenFilterSettings.projectName, chosenFilterSettings.startDate, chosenFilterSettings.endDate,
            stage).then(assistants => {
                assistantsAllEnvs.current = assistants;
                setEnvs(getAvailableEnvs(assistants));

                setSubmitButtonDisabled(true);
        }).catch((reason) => {
            console.log(reason);
            setSubmitButtonDisabled(false); // allow retry
        }).finally(() => {
            setLoading(false);
        })
    }

    function renderChildComponents() {
        if(!childComponents) { return [] };

        const items = [];
        for(const idx in childComponents) {
            items.push(
                <Grid key={idx} item>
                    <Fade in={childComponents[idx].props.enabled} unmountOnExit
                          children={childComponents[idx]} />
                </Grid>
            )
        }
        return items
    }

    return (
        <Paper className={`${classes.paper}`}>
            <Grid container spacing={1} alignItems="center" >
                <Grid item>
                    <DateRangeSelector
                        startTime={chosenFilterSettings.startDate}
                        endTime={chosenFilterSettings.endDate}
                        timerangeSelected={dateChanged}>
                    </DateRangeSelector>
                </Grid>
                <Grid item>
                    <Fade in={stages.filter(s => s !== NEUTRAL_SELECTION).length > 0}
                          unmountOnExit>
                        <DropDown title={'Stage'}
                            options={stages.map(stage => [stage, stage])}
                            enabled={!loading && stages.length > 0}
                            valueChanged={stageChanged}
                            selectedInput={chosenFilterSettings.stage}
                        >
                        </DropDown>
                    </Fade>
                </Grid>
                <Grid item>
                    <Fade in={envs.filter(s => s !== NEUTRAL_SELECTION).length > 0}
                          unmountOnExit>
                        <DropDown title={'Env'}
                            options={envs.map(env => [env, env])}
                            enabled={!loading && envs.length > 0}
                            valueChanged={envChanged}
                            selectedInput={chosenFilterSettings.env}
                        >
                        </DropDown>
                    </Fade>
                </Grid>
                <Grid item>
                    <Fade in={assistants.length > 0}
                        unmountOnExit>
                        <DropDown title={'Assistant'}
                            options={assistants.map(assistant => [assistant.assistantIdentifier, `${assistant.assistantLabel} (${assistant.env})`])}
                            enabled={!loading && assistants.length > 0}
                            valueChanged={assistantChanged}
                            selectedInput={chosenFilterSettings.assistantIdentifier || NEUTRAL_SELECTION}
                        >
                        </DropDown>
                    </Fade>
                </Grid>
                <Grid item>
                    <Fade in={locales.filter(l => l !== NEUTRAL_SELECTION).length > 0}
                        unmountOnExit>
                        <DropDown title={'Locale'}
                            options={locales.map(locale => [locale, locale])}
                            enabled={!loading && locales.length > 0}
                            selectedInput={chosenFilterSettings.locale || NEUTRAL_SELECTION}
                            valueChanged={localeChanged}>
                        </DropDown>
                    </Fade>
                </Grid>

                {/* Render child components before the go button */}
                {renderChildComponents()}

                <Grid item>
                    <CptButton children={'go'}
                        disabled={submitButtonDisabled}
                        onClick={() => {
                            if (assistants.length === 0) {
                                fetchPipelineStages()
                            } else {
                                goButtonPressed(chosenFilterSettings)
                            }
                        }} />
                </Grid>
                <Grid item>
                    <LoadingAnimation loading={loading} />
                </Grid>
            </Grid>
        </Paper>
    );
};
import React from 'react';

import {Redirect} from "react-router";
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {ThemeProvider} from '@material-ui/styles';
import {Typography} from "@material-ui/core";
import {
    MsalAuthenticationResult,
    MsalAuthenticationTemplate,
    MsalProvider,
    UnauthenticatedTemplate
} from "@azure/msal-react";
import {Configuration, InteractionType, PublicClientApplication} from "@azure/msal-browser";
import "./App.css";

import NavBar from "./components/NavBar";
import theme from "./styles/theme/themeFactory";
import AcodaView from "./views/AcodaView";
import AiTags from "./views/AiTags";
import DFView from "./views/DFView"
import FlowAnalyzer from './views/FlowAnalyzer';


// MSAL configuration
const configuration: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    }
};

const pca = new PublicClientApplication(configuration);

const App: React.FC = () => {

    console.log('loading App');

    const [refresh, setRefresh] = React.useState(false);

    if (!localStorage.cockpit_lastpath) {
        localStorage.cockpit_lastpath = '/ait';
    }
    const saveToLocalStore = (path: string) => {
        localStorage.cockpit_lastpath = path;
        setRefresh(!refresh) // fixme: temp. to force component reload.
    };

    function ErrorComponent(error: MsalAuthenticationResult) {
        return <p>An Error Occurred: {error}</p>;
    }

    function LoadingComponent() {
        return <p>Authentication in progress...</p>;
    }

    const authRequest = {
        scopes: ["openid", "profile"]
    };

    return (
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <MsalProvider instance={pca}>
                        <MsalAuthenticationTemplate
                            interactionType={InteractionType.Redirect}
                            authenticationRequest={authRequest}
                            errorComponent={ErrorComponent}
                            loadingComponent={LoadingComponent}>
                            <div>
                                <NavBar onclick={saveToLocalStore}/>
                                <Switch>
                                    <Redirect from="/" to={localStorage.cockpit_lastpath} exact/>
                                    <Route path="/fa" component={FlowAnalyzer} exact/>
                                    <Route path="/df" component={DFView} exact/>
                                    <Route path="/ait" component={AiTags} exact/>
                                    <Route path="/acoda" component={AcodaView} exact/>
                                </Switch>
                            </div>

                        </MsalAuthenticationTemplate>
                        <UnauthenticatedTemplate>
                            <Typography variant="h6">
                            No users are signed in!
                            </Typography>
                        </UnauthenticatedTemplate>
                    </MsalProvider>
                </BrowserRouter>
            </ThemeProvider>
    );

};


export default App;

import {Answer, MRAnswer, MRQuestion, Page, Question} from "../types/types";

export class ChartHelper {

    texts:string[] = [];
    answerOrigins:number[] = [];
    positiveWeights:number[] = [];
    negativeWeights:number[] = [];

    constructor(questions: Question[], pages: Page[]) {
        questions.forEach(( item ) => {
            const answers:Answer[] = item.answers;
            answers.forEach( ( answer )=> {
                const text = this.findText( answer.origin, pages );
                if (text) {
                    this.texts.push(text);
                } else {
                    this.texts.push('');
                }
                this.answerOrigins.push(answer.origin);
                this.positiveWeights.push(answer.positiveWeight);
                this.negativeWeights.push(answer.negativeWeight);
            })
        });
    }

    findText(answerOrigin: number, pages: Page[]): (string|null) {
        if (!pages) { return null; }
        for (const page of pages) {
            for (const question of page.questions) {
                const answers = question.answers;
                if (!answers) { continue; }
                const answer = answers.find((a) => {
                    if (a.aid === answerOrigin) {
                        return true;
                    }
                    return false;
                });
                if (answer) {
                    return answer.answerText;
                }
            }
        }

        return null;
    }

    sortAnswers(allanswers:Answer[]) {
        allanswers.sort((a1: Answer, a2: Answer) => {
            if (a1.positiveWeight < a2.positiveWeight) {
                return 1;
            }
            if (a1.positiveWeight > a2.positiveWeight) {
                return -1;
            }
            return 0;
        }).map((answer) => {
            this.texts.push(answer.answerText);
            this.answerOrigins.push(answer.origin);
            this.positiveWeights.push(answer.positiveWeight);
            this.negativeWeights.push(answer.negativeWeight);
            return answer;
        });
    }

}

export class ChartHelper2 {

    texts:string[] = [];
    answerOrigins:number[] = [];
    positiveWeights:number[] = [];

    constructor(questions: MRQuestion[], pages: Page[]) {
        questions.forEach(( item ) => {
            const answers:MRAnswer[] = item.answers;
            answers.forEach( ( answer )=> {
                const text = this.findText( answer.answer_origin, pages );
                if (text) {
                    this.texts.push(text);
                } else {
                    this.texts.push('');
                }
                this.answerOrigins.push(answer.answer_origin);
                this.positiveWeights.push(answer.answer_weight);
            })
        });
    }

    findText(answerOrigin: number, pages: Page[]): (string|null) {
        if (!pages) { return null; }
        for (const page of pages) {
            for (const question of page.questions) {
                const answers = question.answers;
                if (!answers) { continue; }
                const answer = answers.find((a) => {
                    if (a.aid === answerOrigin) {
                        return true;
                    }
                    return false;
                });
                if (answer) {
                    return answer.answerText;
                }
            }
        }

        return null;
    }

    sortAnswers(allanswers:Answer[]) {
        allanswers.sort((a1: Answer, a2: Answer) => {
            if (a1.positiveWeight < a2.positiveWeight) {
                return 1;
            }
            if (a1.positiveWeight > a2.positiveWeight) {
                return -1;
            }
            return 0;
        }).map((answer) => {
            this.texts.push(answer.answerText);
            this.answerOrigins.push(answer.origin);
            this.positiveWeights.push(answer.positiveWeight);
            return answer;
        });
    }

}
import * as React from "react";

import { NavLink } from "react-router-dom"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { withStyles, Theme } from '@material-ui/core/styles'

import CptNavButton from "./Buttons/CptNavButton";

const styles = (theme: Theme) => ({
    root: {
        boxShadow: 'none'
    },
    appName: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(1)
    },
    a: {
        textDecoration: 'none',
        color: 'white'
    }
});

export interface Props {
    classes: any;
    onclick: (path: string) => void;
}

class NavBar extends React.Component<Props> {
    public render() {
        const { classes, onclick } = this.props;
        return (
            <AppBar position="static" className={classes.root} classes={{ root: "myclass" }}>
                <Toolbar>
                    <img style={{ width: '44px', height: '44px', margin: '2px 10px 2px 2px' }} src='images/wheel.png'
                        alt={'logo'} />
                    <Typography variant="h6" color="inherit" className={classes.appName}>
                        Zoovu AI COCKPIT
                    </Typography>
                    <NavLink to="/fa" className={classes.a}>
                        <CptNavButton onClick={() => {
                            onclick('/fa')
                        }}>
                            Flow Analyzer
                        </CptNavButton>
                    </NavLink>
                    <NavLink to="/df" className={classes.a}>
                        <CptNavButton onClick={() => {
                            onclick('/df')
                        }}>
                            Conversation Optimizer
                        </CptNavButton>
                    </NavLink>
                    <NavLink to="/ait" className={classes.a}>
                        <CptNavButton onClick={() => {
                            onclick('/ait')
                        }}>
                            AI Tags
                        </CptNavButton>
                    </NavLink>
                    <NavLink to="/acoda" className={classes.a}>
                        <CptNavButton onClick={() => {
                            onclick('/acoda')
                        }}>
                            User Types Study
                        </CptNavButton>
                    </NavLink>
                </Toolbar>
            </AppBar>
        )
    }
}

export default withStyles(styles)(NavBar);

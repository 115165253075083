import React from 'react';

import { BarExtendedDatum } from "@nivo/bar";
import { SliceTooltipProps, Point } from "@nivo/line";
import { makeStyles } from "@material-ui/core/styles";
import createStyles from "@material-ui/styles/createStyles/createStyles";

import { Theme } from "../../../styles/theme/types";
import { binomialDeviation } from '../../Dashboards/data/StatisticsUtils';

const tooltipStyle = makeStyles((theme: Theme) =>
    createStyles({
        tooltip: {
            ...theme.typography.caption,
            WeightForFont: theme.typography.fontWeightSuperBold,
            margin: theme.spacing(1),
        },
        sliceBackground: {
            background: theme.palette.white,
            padding: theme.spacing(2),
        }
    }),
);

export const confidenceSliceTooltip = (sliceProps: SliceTooltipProps) => {
    const classes = tooltipStyle();
    return (
        <div className={classes.sliceBackground}>
            {sliceProps.slice.points.map((point: Point) => (
                <div key={point.id} style={{ color: point.serieColor }} className={classes.tooltip}>
                    <strong>{point.serieId}</strong> {point.data.yFormatted} ± {
                        // @ts-ignore
                        binomialDeviation(parseFloat(point.data.yFormatted) as number, point.data.n, false).toPrecision(2)
                    }
                </div>
            ))}
        </div>
    )
}

export const barTooltip = ({ id, value, color, data }: BarExtendedDatum) => {
    const classes = tooltipStyle();
    const tooltipForId = data['tooltip'] ? ((data['tooltip'] as any)[id as any] ? (data['tooltip'] as any)[id as any] : null) : null;
    // lost in JSX syntax, sorry :-)
    return (<span style={{ color }}>
        {tooltipForId ? <div className={classes.tooltip} key={id}>{id}</div> : null}
        {
            tooltipForId ? (Object.values(tooltipForId).map((value, idx) =>
                <div className={classes.tooltip} key={idx}>{value as string}</div>
            ))
                : <div className={classes.tooltip} key={id}>{id}: {value}</div>
        }
    </span>)
};
import * as React from "react";

import * as PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {createStyles, Theme} from "@material-ui/core";

import {DataStats} from "../types/types";

import Header from "./Tables/ReportHeader";

const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        fontSize: '0.8125rem',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
    },
});


export interface Props {
    classes: any;
    dataStats?: DataStats;
    snackbar?: React.ReactElement;
}

class TrainingStatsGrid extends React.Component<Props>  {
    public static propTypes = { classes: PropTypes.object.isRequired };
    public render() {
        const { classes, dataStats, snackbar } = this.props;
        return (
            <Paper className={classes.root}>
                <Header header={'Training Data Statistic'}/>
                {dataStats &&
                <Grid container spacing={0} direction='column'>
                    <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                        <Paper className={classes.paper}>number of events: {dataStats.numberOfEvents}</Paper>
                    </Grid>
                    <Grid item xs={'auto'} sm={'auto'} lg={'auto'}>
                        <Paper className={classes.paper}>number of sessions: {dataStats.numberOfSessions}</Paper>
                    </Grid>
                </Grid>}
                {snackbar}
            </Paper>
        );
    }
}

export default withStyles(styles)(TrainingStatsGrid);

import * as React from "react";

import classNames from "classnames";

import {withStyles, Theme} from "@material-ui/core/styles";
import createStyles from "@material-ui/styles/createStyles/createStyles";
import {Fade, LinearProgress, Paper, Container, Grid} from "@material-ui/core";

import {Pipeline, Training} from "../../types/health";
import CptTable from "../Tables/CptTable";
import {calculateAndFormatDuration, convertToDatestring, roundUp} from "../../utils/utils";
import PipelineCardContainer from "../Cards/PipelineCardContainer";
import {AssistantSelector, FilterSettings} from "../Selectors/AssistantSelector";
import HealthBarPlot from "../Plots/BarPlot";
import {fetchAssistantStatistics} from "../../utils/fetch";

const styles = (theme: Theme) => createStyles({
    root: {
        background: theme.palette.greys.bcg.mainBcg
    },
    container: {
        marginTop: '20px'
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    plot: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
    },

});


interface Props {
    classes: any
}

interface PipelinesState {
    pipelines: Pipeline[] | null;
    selectedPipeline: number;
    selectedTraining: number;
    currentPage: number;
    loading: boolean;
    filterSettings: FilterSettings;
}

class AITDashboard extends React.Component<Props, PipelinesState> {
    state = {
        pipelines: [] as Pipeline[],
        selectedPipeline: 0,
        selectedTraining: -1,
        currentPage: 0,
        loading: false,
        filterSettings: {
            env: process.env.REACT_APP_STAGE0 || '',
            startDate: new Date(new Date().getTime() - 1000*60*60*24*((process.env.REACT_APP_ASSISTANT_SELECTOR_DAYS_AGO || 7) as number)).getTime(),
            endDate: new Date().getTime(),
            projectName: 'AIT',
            stage: 'not_set',
        },
    };

    fetchAssistantStatistics = async (filterSettings: FilterSettings) => {
        this.setState({
            loading: true
        });
        const response = await fetchAssistantStatistics(filterSettings, 1000, 0).catch(error => {
            console.log(error);
        }).finally(() => {
            this.setState({
                loading: false
            })
        });
        if (response && response.ok) {
            const result = await response.json();
            this.setState(() => ({
                pipelines: result['pipelines']
            }));
        }
        return []
    };

    getMseData = (pipelines: Pipeline[]) => {
        const indexBy = 'indexBy';
        const trainingData: object[] = [];
        const keys: string[] = [];

        pipelines.forEach((pipeline, pplIdx) => {
            const training: { [k: string]: any } = {};
            pipeline.trainings.forEach((tr: Training) => {

                if (tr.healthParams) {
                    const tooltip: { [k: string]: any } = {};
                    const mse_error: number = (tr.healthParams as any)['mse_error'];
                    if (typeof(mse_error) !== 'undefined' && mse_error !== null) {
                        training[indexBy] = pplIdx; // only index this ppl if mse was found at least once
                        training[tr.algName] = roundUp(mse_error);
                        if (keys.indexOf(tr.algName) === -1) {
                            keys.push(tr.algName);
                        }
                        tooltip[tr.algName] = {
                            date: convertToDatestring(tr.startTime, true),
                        };
                        training['tooltip'] = {...training['tooltip'], ...tooltip}
                    }
                }
            });
            if (typeof(training[indexBy] !== 'undefined')) {
                trainingData.push(training);
            }
        });

        return {data: trainingData, keys: keys, 'indexBy': indexBy};
    };

    getPrePostprocessorData = (pipelines: Pipeline[]) => {
        const indexBy = 'indexBy';
        const trainingData: object[] = []; // {'a1': 0.3, a2': 0.13, 'date': 12345}
        const keys: string[] = [];
        pipelines.forEach((pipeline, pplIdx) => {
            const purpose = (pipeline.purpose as any)['purpose'] as string;
            const training: { [k: string]: any } = {};
            pipeline.trainings.forEach((tr: Training) => {
                if (tr.healthParams) {

                    const tooltip: { [k: string]: any } = {};

                    // add preprocessing data if available
                    let numberOfAttrs = this.getNumberOfAttributes(tr, 'preprocessed_data_size');
                    if (typeof (numberOfAttrs) !== 'undefined') {
                        training[indexBy] = pplIdx; // only index this ppl if a kpi was found at least once
                        const key = tr.algName + ' / preprocessing';
                        training[key] = numberOfAttrs;
                        if (keys.indexOf(key) === -1) {
                            keys.push(key);
                        }
                        tooltip[key] = {
                            purpose: purpose,
                            numberOfAttrs: 'number of attributes: ' + numberOfAttrs,
                            date: convertToDatestring(pipeline.startTime, true),
                        };
                    }

                    // add postprocessing data if available
                    numberOfAttrs = this.getNumberOfAttributes(tr, 'postprocessed_data_size');
                    if (typeof (numberOfAttrs) !== 'undefined') {
                        training[indexBy] = pplIdx; // only index this ppl if a kpi was found at least once
                        const key = tr.algName + ' / postprocessing';
                        training[key] = numberOfAttrs;
                        if (keys.indexOf(key) === -1) {
                            keys.push(key);
                        }
                        tooltip[key] = {
                            purpose: purpose,
                            numberOfAttrs: 'number of attributes: ' + numberOfAttrs,
                            date: convertToDatestring(pipeline.startTime, true),
                        };
                    }
                    training['tooltip'] = {...training['tooltip'], ...tooltip}
                }
            });
            if (typeof(training[indexBy] !== 'undefined')) {
                trainingData.push(training);
            }
        });

        return {data: trainingData, keys: keys, indexBy: indexBy};
    };

    public render() {
        const {classes} = this.props;
        const {pipelines, selectedPipeline, selectedTraining} = this.state;
        const mseData = this.getMseData(pipelines);
        const prePostData = this.getPrePostprocessorData(pipelines);
        const training = selectedTraining >=0? pipelines[selectedPipeline].trainings[selectedTraining]: null;

        return <Container maxWidth={false} className={`${classes.container}`}>
                <Grid container spacing={5} className={`${classes.root}`}>
                    <Grid item xs={12}>
                        <AssistantSelector initialStartDate={this.state.filterSettings.startDate}
                                           initialEndDate={this.state.filterSettings.endDate}
                                           projectName={this.state.filterSettings.projectName}
                                           goButtonPressed={async (filterSettings) => {
                                               this.setState({
                                                   filterSettings: filterSettings,
                                                   selectedPipeline: 0,
                                                   selectedTraining: -1,
                                                   currentPage: 0
                                               });
                                               await this.fetchAssistantStatistics(filterSettings)
                                           }}>
                        </AssistantSelector>
                        <Fade in={this.state.loading} unmountOnExit>
                            <LinearProgress/>
                        </Fade>
                    </Grid>
                    <PipelineCardContainer pipelines={pipelines} itemsPerPage={5}
                                           pipelineSelected={(pplIdx: number) => this.setState(
                                           {selectedPipeline: pplIdx, selectedTraining: -1}
                                           )} selectedPipelineIdx={selectedPipeline}/>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <Paper className={classes.plot}>
                                <HealthBarPlot data={mseData.data} keys={mseData.keys} indexBy={mseData.indexBy} dataTitle='MSE history'
                                               xTitle='Pipelines' yTitle='MSE' />
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper className={classes.plot}>
                                <HealthBarPlot data={prePostData.data} keys={prePostData.keys} indexBy={prePostData.indexBy} dataTitle='Number of attributes in pre- and postprocessing'
                                               xTitle='Pipelines' yTitle='Number of attributes' />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <Paper className={classes.plot}>
                            {pipelines[selectedPipeline] !== undefined ?
                                <CptTable tableTitle={'Trainings in pipeline'}
                                          tableHead={['algorithm', 'duration']}
                                          tableRows={pipelines[selectedPipeline].trainings.map((training, idx) =>
                                           ({
                                                  idx: idx,
                                                  rowData: [
                                                      {
                                                          cellObject: training,
                                                          handler: (training) =>  (training as Training)['algName']
                                                      },
                                                      {
                                                          cellObject: training,
                                                          handler: (training) => {
                                                              const tr = training as Training;
                                                              return calculateAndFormatDuration(tr.startTime, tr.endTime)
                                                          }
                                                      }
                                                  ], // cells
                                              })
                                          )}
                                          rowSelected={rowNumber => {
                                              this.setState({
                                                  selectedTraining: rowNumber
                                              })}
                                          }
                                          selectedRowIdx={selectedTraining}

                                /> : null}
                            </Paper>
                        </Grid>
                        <Grid item xs={6} className={classNames({ 'display': selectedTraining >= 0 })}>
                            <Paper className={classes.plot}>
                                {training !== null ?
                                    <CptTable tableTitle={'Training details'}
                                              tableHead={['health parameter name', 'value']}
                                              tableRows={[
                                                  {
                                                      idx: 0,
                                                      rowData: [
                                                          this.createMetricNameCellObject('features'),
                                                          {
                                                              cellObject: training,
                                                              handler: (training) => {
                                                                  const tr = (training as Training);
                                                                  return tr.features ?
                                                                      tr.features.map(feature => feature).join(', '): '';
                                                              }
                                                          }
                                                      ]
                                                  },
                                                  {
                                                      idx: 1,
                                                      rowData: [
                                                          this.createMetricNameCellObject('significant attributes'),
                                                          {
                                                              cellObject: training,
                                                              handler: (training) => {
                                                                  const tr = (training as Training);
                                                                  const signifAttrs = (tr.healthParams as any)['significant_attributes'] as string[];
                                                                  return signifAttrs ?
                                                                      signifAttrs.map(attr => attr).join(', '): '';
                                                              }
                                                          }
                                                      ]
                                                  },
                                                  {
                                                      idx: 2,
                                                      rowData: [
                                                          this.createMetricNameCellObject('blacklisted attributes'),
                                                          {
                                                              cellObject: training,
                                                              handler: (training) => {
                                                                  const tr = (training as Training);
                                                                  const blackAttrs = (tr.healthParams as any)['blacklisted_attributes'] as string[];
                                                                  return blackAttrs ?
                                                                      blackAttrs.map(attr => attr).join(','): '';
                                                              }
                                                          }
                                                      ]
                                                  },
                                                  {
                                                      idx: 3,
                                                      rowData: [
                                                          this.createMetricNameCellObject('preprocessed number attributes'),
                                                          {
                                                              cellObject: training,
                                                              handler: (training) => {
                                                                  const numberOfAttrs = this.getNumberOfAttributes(training as Training, 'preprocessed_data_size');
                                                                  return numberOfAttrs? numberOfAttrs: '';
                                                              }
                                                          }
                                                      ]
                                                  },
                                                  {
                                                      idx: 4,
                                                      rowData: [
                                                          this.createMetricNameCellObject('postprocessed number attributes'),
                                                          {
                                                              cellObject: training,
                                                              handler: (training) => {
                                                                  const numberOfAttrs = this.getNumberOfAttributes(training as Training, 'postprocessed_data_size');
                                                                  return numberOfAttrs? numberOfAttrs: '';
                                                              }
                                                          }
                                                      ]
                                                  },
                                                  {
                                                      idx: 5,
                                                      rowData: [
                                                          this.createMetricNameCellObject('hyperparameters'),
                                                          {
                                                              cellObject: training,
                                                              handler: (training) => {
                                                                  const tr = (training as Training);
                                                                  return JSON.stringify(tr.hyperparameters, null, 2)
                                                              }
                                                          }
                                                      ]
                                                  }
                                              ]}
                                    /> : null
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
    }

    getNumberOfAttributes = (training: Training, attrName: string): (string|undefined) => {
        const preprocessed_data_size = (training.healthParams as any)[attrName];
        if (preprocessed_data_size) {
            return preprocessed_data_size['number_of_attributes'];
        }
    }

    createMetricNameCellObject = (title: string) => ({
            cellObject: title as unknown as object,
            handler: (title: object) => title as unknown as string
        }
    )}

export default withStyles(styles)(AITDashboard)

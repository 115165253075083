import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import {Theme} from "../../styles/theme/types";
import AITooltip from "../Tables/AITooltip";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        marginRight: {
            marginRight: '0px'
        },
        marginBottom: {
            marginBottom: '-8px'
        },
        cartIcon: {
            color: theme.palette.cartIcon
        },
        right: {
            textAlign: 'right',
        }
    }),
);

interface Props {
    info: string,
}

export const InfoText: React.FC<Props> = ({ info }) => {
    const classes = useStyles();

    return (
          <Grid item xs={12} className={`${classes.right}`}>
              <AITooltip title={info} placement="right-end">
                  <InfoOutlinedIcon className={`${classes.cartIcon} ${classes.marginBottom} ${classes.marginRight}`}>
                  </InfoOutlinedIcon>
              </AITooltip>
        </Grid>
    );
};
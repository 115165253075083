import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(
    createStyles({
            root: {
                flexGrow: 1,
                marginTop: 0
            },
            primary: {
                backgroundColor: 'rgb(241, 225, 91)',
                '& div': {

                    backgroundColor: 'rgb(232, 168, 56)',
                }
            },
            secondary: {
                backgroundColor: 'rgb(97, 205, 187)',
                '& div': {
                    backgroundColor: 'rgb(151, 227, 213)',
                }
            }
        }
    )
);

export default function LinearIndeterminate() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LinearProgress className={classes.primary} />
        </div>
    );
}
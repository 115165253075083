import {PathQuestion} from "../../Plots/PathsPlot";
import {PATH_TO_QUESTION_ORIGINS_REGEX} from "../../../utils/utils";

import { QuestionText } from "./HealthDbStatistics";

export enum QuestionPathStat{
    AVG_CO = 'avg_co',
    AVG_DO = 'avg_do'
}

export const getQuestionPathStatsOrigins = (trainings: any[]) => trainings.map(training => {
        const dynamic_question_path: [] = training.dynamic_question_path || []
        return dynamic_question_path.map((path: string) => path.split(PATH_TO_QUESTION_ORIGINS_REGEX)
        ).reduce((acc, curr) => {
            curr.forEach(o => acc.add(o))
            return acc
        }, new Set<string>());
    }).reduce((acc, curr) => {
        curr.forEach(o => acc.add(o))
        return acc
    }, new Set<string>())


export const createPaths = (trainings: any[], questionTexts: QuestionText[], stat: QuestionPathStat) => {

    // we are passed multiple trainings, we must collect and aggregate our paths
    // across them
    let allTrainings: PathQuestion[] = []

    trainings.forEach(training => {
        if(!training.dynamic_question_path) { return; }
        const dynamic_question_path: [] = training.dynamic_question_path
        const number_visits: [] = training.number_visits
        let stat_list: [] = []

        if (stat === QuestionPathStat.AVG_CO)
            {stat_list = training.avg_co}
        else
            {stat_list = training.avg_do}

        if (!dynamic_question_path || !dynamic_question_path.length || !number_visits || !stat_list ||
            (dynamic_question_path.length !== number_visits.length) || (dynamic_question_path.length !== stat_list.length)) {
            console.log("There is something wrong with the dynamic_question_path passed for training data. " + JSON.stringify(training) +
                "Check it to avoid erroneous stats")
            return;
        }

        // First we convert paths encoded as comma-sep. strings into a list of pathQuestions.
        // Each PathQuestion then contains it's follower as its single "child"
        // later we will merge paths, so "parents" will be aggregated and children accumulated "under" their
        // aggregated parents
        const pathQuestions = dynamic_question_path.map((path: string, idx) => {
            const questionOrigins = path.split(PATH_TO_QUESTION_ORIGINS_REGEX);
            return questionOrigins.map((origin, i) => {
                const pathQuestion: PathQuestion = {
                    text: ( questionTexts.find( el => el[origin]) || {[origin]: {'question_text': origin}} )[origin]['question_text'],
                    origin: origin,
                    children: [],
                    // last question in path must bear path KPIs
                    stat_value: i === questionOrigins.length-1 ? stat_list[idx] : undefined,
                    number_visits: i === questionOrigins.length-1 ? number_visits[idx] : undefined
                }
                return pathQuestion
            }).reduce((acc, curr) => {
                // chain questions
                acc.children.length? _appendChild(acc.children.slice(-1)[0], curr): _appendChild(acc, curr)
                return acc
            });
        });

        // merge step.
        function mergeAndRemove(pathQuestion1: PathQuestion, pathQuestion2: PathQuestion, deleteIdx: number) {
            if (pathQuestion1 && pathQuestion2 && pathQuestion1.origin === pathQuestion2.origin) {
                mergeChildren(pathQuestion1, pathQuestion2)
                pathQuestions.splice(deleteIdx, 1)
            }
        }

        // merges and removes the paths created from the current training
        function mergeCurrentPaths() {
            for (let i = pathQuestions.length-1; i>=0; i--) {
                for (let j = pathQuestions.length-1; j>=0; j--) {
                    if (i === j) { continue; }
                    mergeAndRemove(pathQuestions[i], pathQuestions[j], j)
                }
            }
        }

        // in order to remove while iterating we iterate backwards (so that index is not affected
        if (allTrainings.length === 0) {
            // this is the first training in the set, we must merge its paths (comparing them between each other)
            mergeCurrentPaths()

            // assign the merged paths of this first training to all trainings
            allTrainings = pathQuestions
        } else {
            // merge paths we have already collected with the current training's, one by one
            for (let i = allTrainings.length-1; i>=0; i--) {
                for (let j = pathQuestions.length-1; j>=0; j--) {
                    mergeAndRemove(allTrainings[i], pathQuestions[j], j)
                }
            }
            // merge the paths created from the current training as well (necessary since some paths from the current
            // training might not be in the allTrainings and we want them included)
            mergeCurrentPaths()

            // extend the paths with the merged paths from previous trainings and the ones created from the current one
            allTrainings = [...allTrainings, ...pathQuestions]
        }
    })

    function _appendChild (potentialParent: PathQuestion, child: PathQuestion) {
        potentialParent.children.push(child)
    }

    return allTrainings
};

const updateStatisticRate = (question1: PathQuestion, question2: PathQuestion) => {
    // e.g. we aggregate the stat rate (clickout or dropout) of a path appearing in sep. trainings
    // we cannot simply aggregate rates by summing and dividing by two
    // we get their joint number (not rate) of clickouts and divide it by joint number of visits
    if (question1.stat_value === undefined && question2.stat_value === undefined) { return undefined }
    // to avoid converting undefined to 0 when not needed
    if (question1.number_visits === undefined && question2.number_visits === undefined) { return undefined }
    const numberOfStat1 = rawNumberOfStat(question1)!;
    const numberOfStat2 = rawNumberOfStat(question2)!;
    return +(((numberOfStat1 || 0) + (numberOfStat2 || 0)) /
        ((question1.number_visits! || 0) + (question2.number_visits! || 0))).toFixed(3);
}

const rawNumberOfStat = (question: PathQuestion) => {
    // we reverse-engineer raw number of the stats from its rate
    // (e.g. rate is 0.1, number of visits = 100, then raw number for that stat is 10)
    if (question.number_visits === undefined || question.stat_value === undefined) {
        return undefined
    }
    return question.number_visits! * question.stat_value!
}

// called if we know that parents have the same origin
const mergeChildren = (questions1: PathQuestion, questions2: PathQuestion) => {
    // first update rate, then visits as rate calculation is based on original visits
    const numberOfVisits1 = questions1.number_visits
    const numberOfVisits2 = questions2.number_visits
    // if both compared KPIs are undefined we must preserve undefined, since it's expected to be not the leaf question
    questions1.stat_value = updateStatisticRate(questions1, questions2)
    if (numberOfVisits1 === undefined && numberOfVisits2 === undefined) {
        questions1.number_visits = undefined
    } else {
        questions1.number_visits = (numberOfVisits1 || 0) + (numberOfVisits2 || 0)
    }

    // compare two arrays of children recursively:
    // joint elements should be aggregated, new elements from the second array should be appended to the first
    if (questions1.children.length === 0) {
        // if first array is empty we just "hang over" the children of the second
        questions1.children.push(...questions2.children)
        return
    }

    questions1.children.forEach(child1 => {
        const toRemove: number[] = []
        questions2.children.forEach((child2, idx) => {
            if (child1.origin === child2.origin) { // found same subpath: aggregate and try merging children
                mergeChildren(child1, child2)
                toRemove.push(idx) // child2 was aggregated into child2, so we can remove it
            }
        })
        // those we aggregated we can remove
        toRemove.reverse().forEach(idx => questions2.children.splice(idx, 1))
    });

    // append those which were not merged
    questions1.children.push(...questions2.children)
}



import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import {Grid, Container, LinearProgress, Fade, CircularProgress} from "@material-ui/core";

import { KPICard } from "../Cards/KPICard"
import TimeLinePlot from "../Plots/TimeLinePlot"
import { AssistantSelector, FilterSettings } from "../Selectors/AssistantSelector"
import { Theme } from "../../styles/theme/types";
import {fetchAssistantInsights as fetchInsights, fetchAssistantStatistics as fetchStats, fetchQuestionTexts} from "../../utils/fetch";
import { QuestionPathCard } from "../Cards/QuestionPathCard";
import { PathsPlot } from "../Plots/PathsPlot";
import MultiTimeLinePlot from "../Plots/MultiTimeLinePlot";
import {
    ALL_AND_MOST_RECENT_PATHS_INFO_TEXT,
    AVERAGE_TRAINING_DURATION_INFO_TEXT,
    BEST_NEXT_QUESTION_INFO_TEXT,
    CO_RATE_AI_VS_RANDOM_INFO_TEXT,
    AGGREGATED_CO_RATE_INFO_TEXT,
    AGGREGATED_RANDOM_SHARE_INFO_TEXT,
    NUMBER_OF_VISITS_INFO_TEXT,
    OBSERVED_STATES_INFO_TEXT,
    PATHS_POPULARITY_AND_COR_INFO_TEXT,
    PATHS_WITH_HIGHEST_CO_INFO_TEXT,
    PATHS_WITH_HIGHEST_DO_INFO_TEXT,
    RATE_OF_RANDOM_RECOMMENDATIONS_INFO_TEXT,
    RATIO_OF_OBSERVED_STATES_INFO_TEXT,
    TOTAL_CO_RATE_INFO_TEXT,
    TOTAL_NUMBER_OF_VISITS_INFO_TEXT,
    TRAINING_TIMES_INFO_TEXT,
    NEW_OBSERVED_STATES_INFO_TEXT,
    RETRAINS_INFO_TEXT,
    NUMBER_OF_CONFIDENT_STATES_INFO_TEXT,
    NUMBER_OF_DYNAMIC_QUESTIONS_SEEN_INFO_TEXT,
    NUMBER_OF_TIMES_QUESTION_SKIPPED,
    INPUT_AND_OUTPUT_NEURONS_INFO_TEXT,
    BURN_IN_BUFFER_INFO_TEXT,
    NUMBER_OF_EXPERIENCES_INFO_TEXT,
    DQN_LOSS_INFO_TEXT,
    UNPARSED_VISITS_INFO_TEXT,
    ROLLING_CLICKOUT_RATE_INFO_TEXT,
    EXPANDING_CLICKOUT_RATE_INFO_TEXT
} from "../InfoTexts/DynamicFlowInfoTexts"
import BarPlot from "../Plots/BarPlot";
import ConfidenceIntervalPlot from '../Plots/ConfidenceIntervalPlot';
import {someDaysAgo} from "../../utils/utils";
import {CheckBox} from "../Selectors/Checkbox";
import {HIGHEST_CO_PATH_NAME, HIGHEST_DO_PATH_NAME, NUMBER_OF_CLICKOUTS_WHEN_SKIPPED, NUMBER_OF_VISITS_WHEN_SKIPPED, QUESTION_TEXT} from "../constants";
import {DropDown} from "../Selectors/DropDown";

import { average, sum, } from './data/ListUtils';
import { getQuestionPathStatsOrigins, createPaths, QuestionPathStat } from "./data/QuestionPathStatistics";
import {
    getPopularPaths, getPopularPathsQuestionOrigins, getBestNextQuestionOrigins, TOP_PATHS_TO_SHOW
} from "./data/PathStatistics";
import {
    dummyAssistantInsights, getRollingClickoutRate, getExpandingClickoutRate, getInsightTimePeriod, Insights,
    InsightsNames
} from "./data/Insights";
import {
    visits, clickouts, aggregatedClickoutRate, randomRates, durations, newStatesPerDay,
    getBestNextQuestionData, startTimes, numberOfInputAndOutputNeurons, numberOfExperiences,
    burnInBuffer, unParsedVisits, averageDqnLossPerTraining, getAiVsRandomCORate, minimumVisitsThreshold,
    observedStates, aggregatedRandomRate, skippingEnabled, isAssistantDQN, dynamicQuestionsSeen, retrainsPerDay,
    numberOfConfidentStates, ratioOfObservedStates, numberOfTimesQuestionSkipped, QuestionText, dummyAssistantStatistics
} from "./data/HealthDbStatistics";


const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        background: theme.palette.greys.bcg.mainBcg
    },
    container: {
        marginTop: '20px'
    },
    progressCircle: {
        position: 'absolute',
        left: '50%'
    },
    relativePosition: {
        position: 'relative'
    },
    negativeMarginTop: {
        marginTop: "-16px"
    }
}));

export const pathsWithSharedLogic = [HIGHEST_CO_PATH_NAME, HIGHEST_DO_PATH_NAME];


export const DFDashboard: React.FC = () => {

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);
    const [showRetrainingOnly, setShowRetrainingOnly] = React.useState(false);
    const [loadingPaths, setLoadingPaths] = React.useState(false);
    const [allStatistics, setAllStatistics] = React.useState(dummyAssistantStatistics);
    const [allInsights, setAllInsights] = React.useState(dummyAssistantInsights);
    const [questionTexts, setQuestionTexts] = React.useState([] as QuestionText[]);
    const [boxOrigins, setBoxOrigins] = React.useState([] as string[]);
    const [selectedOrigin, setSelectedOrigin] = React.useState('');

    function processPipelines(pipelines: any[]) {
        if (!pipelines) {
            return dummyAssistantStatistics
        }
        let trainings = pipelines.flatMap((pipeline) => pipeline.trainings).map((training) => ({
                end_time: training.endTime,
                start_time: training.startTime,
                ...training.healthParams,
            })
        );
        trainings = trainings.sort((t1, t2) => t1.start_time - t2.start_time );

        setAllStatistics(trainings);

        // keep unique box origins fetched from trainings
        const fetchedBoxOrigins = trainings.map(t => t.box_origin)
                                 .filter(a => a !== undefined)
                                 .map(a => a.toString())
                                 .filter((v, i, a) => a.indexOf(v) === i);

        // automatically select first box origin in the list
        if (fetchedBoxOrigins.length !== 0) {
            setSelectedOrigin(fetchedBoxOrigins[0]);
        }

        setBoxOrigins(fetchedBoxOrigins);

        return trainings
    }

    const fetchData = async (filterSettings: FilterSettings) => {
        await Promise.all([
            fetchAssistantStatistics(filterSettings),
            fetchAssistantInsights(filterSettings)
        ]).catch((error) => {
            console.error(error.message);
        }).finally(() => {
            setLoading(false);
        });
    }

    const fetchAssistantStatistics = async (filterSettings: FilterSettings) => {
        setLoading(true);
        setLoadingPaths(true);
        const response = await fetchStats(filterSettings).catch(error => {
            console.log(error);
        });
        if (response && response.ok) {
            const responseJson = await response.json();

            const trainings = processPipelines(responseJson.pipelines).filter(p => p !== undefined);
            // gather all question origins from all paths and fetch their question texts in one call to backend
            const popularPathsQuestionOrigins = getPopularPathsQuestionOrigins(trainings);
            const questionPathStatsOrigins = getQuestionPathStatsOrigins(trainings)
            const bestNextQuestionOrigins = getBestNextQuestionOrigins(trainings)

            // concat to one list and fetch texts from backend
            // todo: by SH - harmonize
            const uniqueQuestionOriginsFromPaths = [...new Set(popularPathsQuestionOrigins
                .concat([...questionPathStatsOrigins])
                .concat(bestNextQuestionOrigins))
            ]

            // get question texts in format like:
            // [{origin: {
            //      'question_origin': origin,
            //      'question_text': text,
            //      'answers': [{'answer_origin': answer_origin, 'answer_text': answer_text}],
            //   }
            // }]
            let fetchedQuestionTexts: QuestionText[] = []
            try {
                fetchedQuestionTexts = await fetchQuestionTexts(filterSettings.env,
                    filterSettings.assistantIdentifier!,
                    uniqueQuestionOriginsFromPaths
                );
            } catch (error) {
                console.log(error)
            } finally {
                setLoadingPaths(false);
            }
            setQuestionTexts(fetchedQuestionTexts)

        } else {
            setLoadingPaths(false);
        }
        return []
    };

    const fetchAssistantInsights = async (filterSettings: FilterSettings) => {
        setLoading(true);
        const response = await fetchInsights(filterSettings).catch(error => {
            console.log(error);
        });
        if (response && response.ok) {
            const responseJson = await response.json();
            setAllInsights(responseJson.insights || dummyAssistantInsights);
        } else {
            setLoadingPaths(false);
        }
        return []
    };

    function getPaths() {
        return getPopularPaths(filterStatistics(), questionTexts);
    }

    function getPathPopularityAndCor() {
        const questionPathStats = filterStatistics().map(training => training.question_paths_stats || {})
        return createPaths(questionPathStats, questionTexts, QuestionPathStat.AVG_CO);
    }

    function getPathPopularityAndDor() {
        const questionPathStats = filterStatistics().map(training => training.question_paths_dropout_stats || {})
        return createPaths(questionPathStats, questionTexts, QuestionPathStat.AVG_DO);
    }

    function filterStatistics() {
        let statistics;
        if(showRetrainingOnly) {
            statistics = allStatistics.filter((t) => t.run_type === 'retrain');
        } else {
            statistics = allStatistics.filter((t) => t.run_type !== 'retrain');
        }
        if(selectedOrigin) {
            statistics = statistics.filter((t) => t.box_origin === parseInt(selectedOrigin));
        }
        if(statistics.length === 0) {
            return dummyAssistantStatistics;
        } else {
            return statistics;
        }
    }

    function getInsights() {
        return allInsights || dummyAssistantInsights
    }

    const retrainingSelectionChanged = (event: any) => {
        setShowRetrainingOnly(event.target.checked)
    };

    return (
        <Container maxWidth={false} className={`${classes.container}`}>
            <Grid container spacing={5} className={`${classes.root}`}>
                <Grid item xs={12}>
                    <AssistantSelector
                        initialStartDate={someDaysAgo()}
                        initialEndDate={Date.now()}
                        projectName={'DynamicFlow'}
                        goButtonPressed={fetchData}
                        childComponents={[
                            <DropDown title={'Box Origin'}
                                options={boxOrigins.map(origin => [origin, origin])}
                                enabled={boxOrigins.length !== 0}
                                selectedInput={selectedOrigin}
                                valueChanged={(newSelection: string) => {
                                    setSelectedOrigin(newSelection)
                            }}/>
                        ]}
                        resetChildrenSelections={() => {
                            // when changes in parent happen, we reset the selections in the children components
                            setSelectedOrigin('');
                            setBoxOrigins([]);
                        }}>
                    </AssistantSelector>

                    <CheckBox
                        title={"Show retraining statistics only"}
                        enabled={showRetrainingOnly}
                        valueChanged={retrainingSelectionChanged}
                    />

                  <Fade in={loading} unmountOnExit>
                      <Grid item xs={12}>
                          <LinearProgress className={classes.negativeMarginTop} />
                      </Grid>
                  </Fade>
                </Grid>

                <Grid container item xs={12} spacing={1} className={classes.negativeMarginTop}>
                    <Grid item xs={3}>
                        <KPICard title='total number of visits'
                            value={String(sum(visits(filterStatistics())))}
                            iconPath='M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z'
                            infoText={TOTAL_NUMBER_OF_VISITS_INFO_TEXT}
                            percentageChange={0}
                            percentageDescription=''>
                        </KPICard>
                    </Grid>
                    <Grid item xs={3}>
                        <KPICard title='aggregated clickout rate'
                            value={`${aggregatedClickoutRate(filterStatistics()).toFixed(2)}%`}
                            iconPath='M9 11.24V7.5C9 6.12 10.12 5 11.5 5S14 6.12 14 7.5v3.74c1.21-.81 2-2.18 2-3.74C16 5.01 13.99 3 11.5 3S7 5.01 7 7.5c0 1.56.79 2.93 2 3.74zm9.84 4.63l-4.54-2.26c-.17-.07-.35-.11-.54-.11H13v-6c0-.83-.67-1.5-1.5-1.5S10 6.67 10 7.5v10.74l-3.43-.72c-.08-.01-.15-.03-.24-.03-.31 0-.59.13-.79.33l-.79.8 4.94 4.94c.27.27.65.44 1.06.44h6.79c.75 0 1.33-.55 1.44-1.28l.75-5.27c.01-.07.02-.14.02-.2 0-.62-.38-1.16-.91-1.38z'
                            infoText={AGGREGATED_CO_RATE_INFO_TEXT}
                            percentageChange={0}
                            percentageDescription=''>
                        </KPICard>
                    </Grid>
                    <Grid item xs={3}>
                        <KPICard title='aggregated random share'
                            value={`${aggregatedRandomRate(filterStatistics())}%`}
                            iconPath='M5 9.2h3V19H5zM10.6 5h2.8v14h-2.8zm5.6 8H19v6h-2.8z'
                            infoText={AGGREGATED_RANDOM_SHARE_INFO_TEXT}
                            percentageChange={0}
                            percentageDescription=''>
                        </KPICard>
                    </Grid>
                    <Grid item xs={3}>
                        <KPICard title='Average Training Duration'
                            value={`${average(durations(filterStatistics())).toFixed(2)}s`}
                            iconPath='M11 17c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1zm0-14v4h2V5.08c3.39.49 6 3.39 6 6.92 0 3.87-3.13 7-7 7s-7-3.13-7-7c0-1.68.59-3.22 1.58-4.42L12 13l1.41-1.41-6.8-6.8v.02C4.42 6.45 3 9.05 3 12c0 4.97 4.02 9 9 9 4.97 0 9-4.03 9-9s-4.03-9-9-9h-1zm7 9c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM6 12c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z'
                            infoText={AVERAGE_TRAINING_DURATION_INFO_TEXT}
                            percentageChange={0}
                            percentageDescription=''>
                        </KPICard>
                    </Grid>
                    <Grid item xs={3} hidden={!isAssistantDQN(filterStatistics())}>
                        <KPICard title='Burn In Buffer'
                            value={`${burnInBuffer(filterStatistics())}`}
                            iconPath='M19.48,12.35c-1.57-4.08-7.16-4.3-5.81-10.23c0.1-0.44-0.37-0.78-0.75-0.55C9.29,3.71,6.68,8,8.87,13.62 c0.18,0.46-0.36,0.89-0.75,0.59c-1.81-1.37-2-3.34-1.84-4.75c0.06-0.52-0.62-0.77-0.91-0.34C4.69,10.16,4,11.84,4,14.37 c0.38,5.6,5.11,7.32,6.81,7.54c2.43,0.31,5.06-0.14,6.95-1.87C19.84,18.11,20.6,15.03,19.48,12.35z M10.2,17.38 c1.44-0.35,2.18-1.39,2.38-2.31c0.33-1.43-0.96-2.83-0.09-5.09c0.33,1.87,3.27,3.04,3.27,5.08C15.84,17.59,13.1,19.76,10.2,17.38z'
                            infoText={BURN_IN_BUFFER_INFO_TEXT}
                            percentageChange={0}
                            percentageDescription=''>
                        </KPICard>
                    </Grid>
                    <Grid item xs={3}>
                        <KPICard title='Total number of visits not parsed'
                            value={String(sum(unParsedVisits(filterStatistics())))}
                            iconPath="m1.39 4.22 7.9 7.9c.18.2.18.5-.01.7-.1.1-.23.15-.35.15s-.26-.05-.35-.15L6.87 11.1c-.11.4-.26.78-.45 1.12l1.4 1.4c.2.2.2.51 0 .71-.1.1-.23.15-.35.15s-.26-.05-.35-.15l-1.27-1.27c-.24.29-.5.56-.77.8l1.28 1.28c.2.2.2.51 0 .71-.1.1-.23.15-.36.15s-.26-.05-.35-.15l-1.38-1.38c-.69.46-1.39.79-1.97 1.02-.78.31-1.3 1.04-1.3 1.88V20h9.5l3.33-3.33 5.94 5.94 1.41-1.41L2.81 2.81 1.39 4.22zm17.12 11.46-1.41-1.41 4.48-4.48L23 11.2l-4.49 4.48zm2.37-6.6-4.48 4.48-7.1-7.09L13.8 2l7.08 7.08z"
                            infoText={UNPARSED_VISITS_INFO_TEXT}
                            percentageChange={0}
                            percentageDescription=''>
                        </KPICard>
                    </Grid>
                </Grid>

                <Grid container item spacing={3} className={classes.relativePosition}>
                    <Grid item xs={4}>
                        <TimeLinePlot yTitle='Number of Visits' dataTitle='Visits'
                            xData={startTimes(filterStatistics())}
                            yData={visits(filterStatistics())}
                            showAsPercentage={false}
                            infoText={NUMBER_OF_VISITS_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    <Grid item xs={4}>
                        <TimeLinePlot yTitle='CO Rate' dataTitle='Rate'
                            xData={startTimes(filterStatistics())}
                            yData={clickouts(filterStatistics())}
                            showAsPercentage={true}
                            infoText={TOTAL_CO_RATE_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    <Grid item xs={4}>
                        <TimeLinePlot yTitle='Rate of Random Recommendations (%)' dataTitle='Percentage'
                            xData={startTimes(filterStatistics())}
                            yData={randomRates(filterStatistics())}
                            showAsPercentage={true}
                            infoText={RATE_OF_RANDOM_RECOMMENDATIONS_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    <Grid item xs={4}>
                        <TimeLinePlot yTitle='Number of unparsed visits' dataTitle='Number of visits not parsed'
                            xData={startTimes(filterStatistics())}
                            yData={unParsedVisits(filterStatistics())}
                            showAsPercentage={false}
                            infoText={UNPARSED_VISITS_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    <Grid item xs={4} hidden={isAssistantDQN(filterStatistics())}>
                        <MultiTimeLinePlot yTitle='Observed States'
                            lines={observedStates(filterStatistics())} showLegend={false}
                            showAsPercentage={false}
                            infoText={
                                OBSERVED_STATES_INFO_TEXT.replace('MINIMUM_NUMBER_OF_VISITS',
                                    minimumVisitsThreshold(filterStatistics()).toString())
                            }>
                        </MultiTimeLinePlot>
                    </Grid>
                    <Grid item xs={4} hidden={isAssistantDQN(filterStatistics())}>
                        <BarPlot
                            data={newStatesPerDay(filterStatistics())}
                            keys={['New States']}
                            indexBy={'date'}
                            dataTitle='New Observed States per Day'
                            yTitle='Number of States'
                            infoText={NEW_OBSERVED_STATES_INFO_TEXT}
                        />
                    </Grid>
                     <Grid item xs={4} hidden={isAssistantDQN(filterStatistics())}>
                        <BarPlot
                            data={numberOfConfidentStates(filterStatistics())}
                            keys={['Confident States']}
                            indexBy={'date'}
                            dataTitle='Number of confident states'
                            yTitle='Number of Confident States'
                            infoText={NUMBER_OF_CONFIDENT_STATES_INFO_TEXT}
                        />
                    </Grid>
                    <Grid item xs={4} hidden={isAssistantDQN(filterStatistics())}>
                        <TimeLinePlot yTitle='Ratio of Observed States' dataTitle='Ratio'
                            xData={startTimes(filterStatistics())}
                            yData={ratioOfObservedStates(filterStatistics())}
                            showAsPercentage={true}
                            infoText={RATIO_OF_OBSERVED_STATES_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    <Grid item xs={4} hidden={!isAssistantDQN(filterStatistics())}>
                       <MultiTimeLinePlot
                           yTitle='Number of input and output neurons'
                           lines={numberOfInputAndOutputNeurons(filterStatistics())}
                           showLegend={false}
                           showAsPercentage={false}
                           infoText={
                                INPUT_AND_OUTPUT_NEURONS_INFO_TEXT
                            }>
                        </MultiTimeLinePlot>
                    </Grid>
                    <Grid item xs={4} hidden={!isAssistantDQN(filterStatistics())}>
                        <TimeLinePlot yTitle='Number of Experiences' dataTitle='Experiences'
                            xData={startTimes(filterStatistics())}
                            yData={numberOfExperiences(filterStatistics())}
                            showAsPercentage={false}
                            infoText={NUMBER_OF_EXPERIENCES_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    <Grid item xs={4} hidden={!isAssistantDQN(filterStatistics())}>
                        <TimeLinePlot yTitle='Average Loss Value' dataTitle='Loss'
                            xData={startTimes(filterStatistics())}
                            yData={averageDqnLossPerTraining(filterStatistics())}
                            showAsPercentage={false}
                            infoText={DQN_LOSS_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    <Grid item xs={4}>
                         <TimeLinePlot yTitle='Rolling Clickout rate' dataTitle='Rolling clickout rate'
                            xData={getInsightTimePeriod(InsightsNames.ROLLING_CLICKOUT_RATE, getInsights())}
                            yData={getRollingClickoutRate(getInsights())}
                            showAsPercentage={true}
                            infoText={ROLLING_CLICKOUT_RATE_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    <Grid item xs={4}>
                         <TimeLinePlot yTitle='Expanding Clickout rate' dataTitle='Expanding clickout rate'
                            xData={getInsightTimePeriod(InsightsNames.EXPANDING_CLICKOUT_RATE, getInsights())}
                            yData={getExpandingClickoutRate(getInsights())}
                            showAsPercentage={true}
                            infoText={EXPANDING_CLICKOUT_RATE_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    {/* We somehow need to be able to place this whole chart in the grid. The chart is not responsive. So we are trying to manipulate the view with the help of breakpoints
                    dependent on how many questions in the DBox are to display. For > 4 dynamic questions we are trying to give more space to the plot.
                    */}
                    <Grid item xs={12} lg={(getPathPopularityAndCor().length > 4) ? 9 : 9} xl={(getPathPopularityAndCor().length > 4) ? 6 : 4}>
                        <PathsPlot title={"Paths popularity and COR"} questions={getPathPopularityAndCor()}
                            infoText={PATHS_POPULARITY_AND_COR_INFO_TEXT} />
                     </Grid>

                    <Grid item xs={12} lg={(getPathPopularityAndDor().length > 4) ? 9 : 9} xl={(getPathPopularityAndDor().length > 4) ? 6 : 4}>
                        <PathsPlot title={"Paths popularity and DOR"} questions={getPathPopularityAndDor()}
                            infoText={PATHS_POPULARITY_AND_COR_INFO_TEXT} />
                    </Grid>


                    <Grid item xs={12} lg={(getPaths().length > 4) ? 3 : 3} xl={(getPaths().length > 4) ? 6 : 4}>
                        {loadingPaths ? <CircularProgress className={classes.progressCircle} /> : null}
                        <QuestionPathCard paths={getPaths()}
                            infoText={ALL_AND_MOST_RECENT_PATHS_INFO_TEXT}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ConfidenceIntervalPlot yTitle='Pure random vs AI CO Rate'
                            lines={getAiVsRandomCORate(filterStatistics())}
                            showAsPercentage={true}
                            infoText={CO_RATE_AI_VS_RANDOM_INFO_TEXT} />
                    </Grid>
                    <Grid item xs={4}>
                        <TimeLinePlot yTitle='Training Time (s)' dataTitle='Seconds'
                            xData={startTimes(filterStatistics())}
                            yData={durations(filterStatistics())}
                            showAsPercentage={false}
                            infoText={TRAINING_TIMES_INFO_TEXT}>
                        </TimeLinePlot>
                    </Grid>
                    <Grid item xs={4}>
                        <BarPlot
                            data={retrainsPerDay(filterStatistics())}
                            keys={['retrain']}
                            indexBy={'date'}
                            dataTitle='Retrains per day'
                            yTitle='Retrains that happened'
                            infoText={RETRAINS_INFO_TEXT}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MultiTimeLinePlot yTitle='Best next question'
                            lines={getBestNextQuestionData(filterStatistics(), questionTexts)} showLegend={true}
                            infoText={BEST_NEXT_QUESTION_INFO_TEXT.replace("TOP_PATHS_TO_SHOW", TOP_PATHS_TO_SHOW.toString())}
                            showAsPercentage={true}>
                        </MultiTimeLinePlot>
                    </Grid>
                     <Grid item xs={12} hidden={!skippingEnabled(filterStatistics())}>
                        <MultiTimeLinePlot yTitle='How many dynamic questions are being shown?'
                            lines={dynamicQuestionsSeen(filterStatistics())} showLegend={true}
                            infoText={NUMBER_OF_DYNAMIC_QUESTIONS_SEEN_INFO_TEXT}
                            showAsPercentage={false}>
                        </MultiTimeLinePlot>
                    </Grid>
                     <Grid item xs={4} hidden={!skippingEnabled(filterStatistics())}>
                        <BarPlot
                            data={numberOfTimesQuestionSkipped(filterStatistics(), questionTexts)}
                            keys={[NUMBER_OF_VISITS_WHEN_SKIPPED, NUMBER_OF_CLICKOUTS_WHEN_SKIPPED]}
                            indexBy={QUESTION_TEXT}
                            dataTitle='Number of times a question is skipped'
                            yTitle='Times skipped and times clicked out'
                            infoText={NUMBER_OF_TIMES_QUESTION_SKIPPED}
                            showLegend={true}
                            // format question to show only question origin in the x-axis (while the full text will be on the legend)
                            formatFunc={v =>`${v.toString().split('(')[1].split(')')[0]}`}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
